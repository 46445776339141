define("tpf-fund-manager/adapters/application-v2-patch-api", ["exports", "tpf-fund-manager/adapters/application-v2-api"], function (_exports, _applicationV2Api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _applicationV2Api.default.extend({
    /**
     Called by the store when an existing record is saved
     via the `save` method on a model record instance.
     The `updateRecord` method serializes the record and makes an Ajax (HTTP PUT) request
     to a URL computed by `buildURL`.
     See `serialize` for information on how to customize the serialized form
     of a record.
      This is a direct copy of the original implementation. The difference here is that
     this is now a PATCH instead of a PUT. Removing the "bonus" work of fancy requests.
      @method updateRecord
     @param {DS.Store} store
     @param {DS.Model} type
     @param {DS.Snapshot} snapshot
     @return {Promise} promise
     */
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var submittedData = {};
      var modelName = Ember.String.camelize(type.modelName);
      var serializer = store.serializerFor(modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var id = snapshot.id;
      var url = this.buildURL(modelName, id, snapshot, 'updateRecord');

      // Use data directly because serializeIntoHash gives the object directly differently for feathers.
      var dataKeys = Object.keys(data);
      snapshot.adapterOptions = snapshot.adapterOptions || {};

      // Ensure code only sends the data we are actually updating on a record.
      dataKeys.forEach(function (key) {
        if (key in snapshot.changedAttributes()) {
          submittedData[key] = data[key];
        }

        // This is for allowing overrides, i.e send additional parameters in request body,
        // to comply with mandatory params check in request body of some PATCH APIs
        if (key in snapshot.adapterOptions) {
          submittedData[key] = data[key];
        }
      });
      return this.ajax(url, 'PATCH', {
        data: submittedData
      });
    }
  });
});
