import Ember from 'ember';
import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    makerId: Model.attr('id'),
    takerId: Model.attr('id'),
    marketId: Model.attr('id'),
    makerMarketClearId: Model.attr('id'),
    takerMarketClearId: Model.attr('id'),
    tpfImportUploadStatusId: Model.attr('id'),
    companyId: Model.attr('id'),
    companyName: Model.attr('string', 'Name'),
    amount: Model.attr('currency'),
    currency: Model.attr('string'),
    invoiceId: Model.attr('id'),
    dueDate: Model.attr('date'),
    transactionDate: Model.attr('date'),
    meta: Model.attr(),
    voucherId: Model.attr('id'),
    clearedDate: Model.attr('date'),
    isCleared: Model.attr('boolean'),
    isPaid: Model.attr('boolean'),
    takerExcluded: Model.attr('boolean'),
    makerExcluded: Model.attr('boolean'),
    isApproved: Model.attr('boolean'),
    isIneligible: Model.attr('boolean'),
    isReviewed: Model.attr('boolean'),
    makerInvoiceId: Model.attr('id'),
    tpfInvoicesLoans: DS.hasMany('tpf-invoices-loan'),

    amountUsed: Ember.computed('tpfInvoicesLoans.@each.amountUsed', function() {
        const invoicesLoans = this.get('tpfInvoicesLoans');
        const amountUsed = invoicesLoans.reduce((sum, item) => {
            return sum + +item.get('amountUsed');
        }, 0);
        return amountUsed;
    }),

    amountUnused: Ember.computed('amount', 'amountUsed', function() {
        const amount = this.get('amount');
        const amountUsed = this.get('amountUsed');
        return amount - amountUsed;
    }),

    isFullyUsed: Ember.computed('amountUnused', function() {
        const amountUnused = this.get('amountUnused');
        return amountUnused <= 0;
    })
});
