import DS from 'ember-data';
import Ember from 'ember';

export default DS.Model.extend({
    clientUuid: DS.attr(),
    bankAccountUpdated: DS.attr(),
    lastFourBankAccountNumber: DS.attr(),
    latestSettlementAmount: DS.attr(),
    latestSettlementIssuedDate: DS.attr(),
    routingNumberAch: DS.attr(),
    routingNumberWire: DS.attr(),
    /**
     * If bank account details are new since last deposit, provide extra "heads up" / warning
     */
    firstSettlementSinceBankUpdate: Ember.computed('bankAccountUpdated', 'latestSettlementIssuedDate', function() {
        const settlementDate = this.get('latestSettlementIssuedDate');
        if (Ember.isEmpty(this.get('latestSettlementIssuedDate'))) {
            return true;
        }
        return settlementDate < this.get('bankAccountUpdated');
    })
});
