define("tpf-fund-manager/routes/capfin/review-receipt/show/debtor-remittance/show", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model(params) {
      var receiptUploadUuid = this.paramsFor('capfin.review-receipt.show').stagedReceiptUploadUuid;
      return this.get('store').findRecord('staged-debtor-remittance', params.stagedDebtorRemittanceUuid, {
        preload: {
          stagedReceiptUploadUuid: receiptUploadUuid
        }
      }).then(function (remittanceSummary) {
        return Ember.RSVP.hash({
          remittanceSummary: remittanceSummary
        });
      });
    }
  });
});
