define("tpf-fund-manager/routes/capfin/clients/index", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin", "tpf-fund-manager/config/environment"], function (_exports, _c2foAuthenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    toast: Ember.inject.service(),
    session: Ember.inject.service('session'),
    isSubmitting: false,
    model: function model() {
      var searchedClientName = '';
      if (this.controller) {
        searchedClientName = this.controller.get('clientName');
      }
      return Ember.RSVP.hash(this.getPreviousFilters({
        clients: {
          _modelName: 'client',
          sort: {
            outstandingBalance: 'DESC'
          },
          $search: {
            clientName: searchedClientName
          }
        }
      }));
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      },
      addClientStagingInit: function addClientStagingInit(name, userEmail) {
        var that = this;
        var controller = this.controllerFor(this.routeName);
        controller.set('showLoading', true);
        var successCallback = function successCallback(response) {
          var toastMsg;
          var isCreated = response['isCreated'];
          if (isCreated === false) {
            controller.send('openConfirmationModal');
          } else if (isCreated === undefined) {
            // In case of any failure we get isCreated as undefined
            controller.send('closeConfirmationModal');
            controller.send('closeModal');
            that.get('toast').error(response);
          } else {
            toastMsg = "".concat(name, " was successfully created");
            that.get('toast').success(toastMsg);
            controller.send('closeModal');
          }
        };
        var failureCallback = function failureCallback(e) {
          // eslint-disable-next-line no-console
          console.log('Error is : ', e);
          that.get('toast').error(e);
        };
        var finalCallback = function finalCallback() {
          that.refresh();
          controller.set('showLoading', false);
          controller.set('isSubmitting', false);
          controller.set('isCreatingClient', false);
        };
        return fetch("".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/setups"), {
          method: 'POST',
          body: JSON.stringify({
            clientName: name,
            emailAddress: userEmail.toLowerCase(),
            isManuallyCreated: true,
            shouldCommit: false
          }),
          headers: {
            Authorization: "Bearer ".concat(this.get('session.data.authenticated.token')),
            'Content-Type': 'application/json'
          }
        }).then(function (response) {
          if (response.ok) return response.json();
          controller.set('isSubmitting', false);
          return response.json().then(function (response) {
            throw new Error(response.message);
          });
        }).then(function (response) {
          successCallback(response);
        }).catch(function (e) {
          failureCallback(e);
        }).finally(function () {
          finalCallback();
        });
      },
      addSupplier: function addSupplier(name, userEmail) {
        var that = this;
        var controller = this.controllerFor(this.routeName);
        controller.set('isSubmitting', true);
        controller.set('showLoading', true);
        var successCallback = function successCallback() {
          var toastMsg;
          toastMsg = "".concat(name, " was successfully created");
          that.get('toast').success(toastMsg);
          controller.send('closeConfirmationModal');
        };
        var failureCallback = function failureCallback(e) {
          // eslint-disable-next-line no-console
          console.log('Error is : ', e);
          that.get('toast').error(e);
        };
        var finalCallback = function finalCallback() {
          that.refresh();
          controller.set('showLoading', false);
          controller.set('isSubmitting', false);
          controller.set('isCreatingClient', false);
        };
        return fetch("".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/setups"), {
          method: 'POST',
          body: JSON.stringify({
            clientName: name,
            emailAddress: userEmail.toLowerCase(),
            isManuallyCreated: true,
            shouldCommit: true
          }),
          headers: {
            Authorization: "Bearer ".concat(this.get('session.data.authenticated.token')),
            'Content-Type': 'application/json'
          }
        }).then(function (response) {
          if (response.ok) return response.json();
          controller.set('isSubmitting', false);
          return response.json().then(function (response) {
            throw new Error(response.message);
          });
        }).then(function (response) {
          successCallback(response);
        }).catch(function (e) {
          failureCallback(e);
        }).finally(function () {
          finalCallback();
        });
      }
    }
  });
});
