import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    uuid: Model.attr(),
    clientUuid: Model.attr(),
    advanceAmount: Model.attr('currency'),
    advanceDate: Model.attr('date'),
    transferMethod: Model.attr(),
    transactionFee: Model.attr(), // ephemeral
    status: Model.attr(),
    created: Model.attr('date'),
    updated: Model.attr('date'),
    createdByEmailAddress: Model.attr(),
    type: Model.attr(),
    notes: Model.attr(),
    soldDate: Model.attr()
});
