define("tpf-fund-manager/helpers/format-percent", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "accounting", "tpf-fund-manager/config/environment"], function (_exports, _slicedToArray2, _accounting, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatPercent = formatPercent;
  /**
   * 1. Display the <no-value> character if value is not present, or 'zero-like' (defaults to '-')
   *
   * @param value string - the raw value to be parsed and formatted.
   * @returns {*}
   */

  function formatPercent(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      value = _ref2[0];
    var val = parseFloat(value);
    var precision = 2;

    // [1]
    if (isNaN(val) || inThreshold(val, precision)) {
      return _environment.default.formatHelpers['noValue'];
    }
    return "".concat(_accounting.default.toFixed(val, precision), "%");
  }
  var _default = _exports.default = Ember.Helper.helper(formatPercent);
  function inThreshold(val, precision) {
    var threshold = 1 / Math.pow(10, precision);
    return val <= 0 && val > (threshold + 0.001) * -1;
  }
});
