define("tpf-fund-manager/routes/capfin/factoring/fees/index/invoice-fees", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin", "tpf-fund-manager/mixins/active-client-search-mixin"], function (_exports, _c2foAuthenticatedRouteMixin, _activeClientSearchMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, _activeClientSearchMixin.default, {
    model: function model() {
      return Ember.RSVP.hash(this.getPreviousFilters({
        invoiceFees: {
          _modelName: 'invoice-fee',
          sort: {
            postedDate: 'DESC'
          },
          pageSize: 25
        }
      }));
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('invoiceFee', this.store.createRecord('invoice-fee'));
    },
    resetController: function resetController(controller) {
      controller.set('isShowingInvoiceFeeForm', false);
    },
    actions: {
      saveFee: function saveFee(invoiceFee) {
        var _this = this;
        if (invoiceFee.get('isNew')) {
          invoiceFee.set('invoiceUuid', this.controller.get('selectedInvoice.uuid'));
        }
        return invoiceFee.save().then(function () {
          _this.controller.set('isShowingInvoiceFeeForm', false);
          return _this.refresh();
        });
      },
      editFee: function editFee(invoiceFee) {
        this.controller.set('invoiceFee', invoiceFee);
        this.controller.set('isShowingInvoiceFeeForm', true);
      }
    }
  });
});
