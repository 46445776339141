define("tpf-fund-manager/services/page-state", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var storeName = 'pageStore';
  var _default = _exports.default = Ember.Service.extend({
    pageStore: (0, _emberLocalStorage.storageFor)('page-store'),
    currentRouteName: '',
    // set in all routes' beforeModel()
    defaultPageSize: 25,
    /**
     * Returns the pageSize values for the given query parameter.
     * @param {*} filterModel - The model to namespace the pageSize under,
     * specified as the underscore-delimited route + field, e.g. index_general|invoiceViews.
     * @returns {*} The pageSize value for the model / route.
     */
    getParam: function getParam(filterModel) {
      var storeKey = this._buildStoreKey(filterModel);
      return this.get("".concat(storeName, ".").concat(storeKey));
    },
    /**
     * Sets a pageSize for a route's model with the provided values.
     * @param {*} filterModel - The model to namespace the pageSize fields under,
     * specified as the underscore-delimited route + field, e.g. index_general|invoiceViews.
     * @param {*} pageSize - The number of records to return in a query.
     * @returns {*} The value for the model / route.
     */
    setParam: function setParam(filterModel, pageSize) {
      var storeKey = this._buildStoreKey(filterModel);
      var defaultPageSize = this.get('defaultPageSize');
      return this.set("".concat(storeName, ".").concat(storeKey), Number(pageSize || defaultPageSize));
    },
    /**
     * Remove filters for the provided model and key.
     * @param {*} filterModel The model to namespace the pageSize fields under,
     * specified as the underscore-delimited route + field, e.g. index_general|invoiceViews.
     */
    deleteParam: function deleteParam(filterModel) {
      var storeKey = this._buildStoreKey(filterModel);
      return this.set("".concat(storeName, ".").concat(storeKey), void 0);
    },
    _buildStoreKey: function _buildStoreKey(filterModel) {
      // currentRouteName is set in the "beforeModel" hook for all routes via
      // the route-filter-history initializer.
      var currentRouteName = this.get('currentRouteName').replace(/\./g, '_');
      filterModel = Ember.String.dasherize(filterModel);
      return "".concat(currentRouteName, "|").concat(filterModel);
    }
  });
});
