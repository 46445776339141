define("tpf-fund-manager/helpers/title-case", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.titleCase = titleCase;
  function titleCase(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      str = _ref2[0];
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var emptyText = options.emptyText;
    var deCamStr = Ember.String.decamelize(str || emptyText || '');
    var removeUnderStr = deCamStr.replace(/_/g, ' ');
    return removeUnderStr.split(' ').map(Ember.String.capitalize).join(' ');
  }
  var _default = _exports.default = Ember.Helper.helper(titleCase);
});
