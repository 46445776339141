define("tpf-fund-manager/components/uuid-truncated", ["exports", "tpf-fund-manager/templates/components/uuid-truncated"], function (_exports, _uuidTruncated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UuidTruncatedComponent = Ember.Component.extend({
    layout: _uuidTruncated.default,
    classNames: 'clickable',
    isTruncated: true,
    tagName: 'span',
    init: function init() {
      this._super.apply(this, arguments);
      var uuid = this.get('uuid');
      this.set('fullUuid', uuid);
      var truncatedUuid = Ember.String.htmlSafe(uuid.substring(0, 8) + "&hellip;");
      this.set('truncatedUuid', truncatedUuid);
      this.set('uuid', truncatedUuid);
    },
    click: function click() {
      if (!this.get('linkTo')) {
        var isTruncated = !this.get('isTruncated');
        var uuidValue = isTruncated ? this.get('truncatedUuid') : this.get('fullUuid');
        this.set('uuid', uuidValue);
        this.set('isTruncated', isTruncated);
      }
    }
  });
  UuidTruncatedComponent.reopenClass({
    positionalParams: ['uuid']
  });
  var _default = _exports.default = UuidTruncatedComponent;
});
