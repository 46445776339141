define("tpf-fund-manager/router", ["exports", "tpf-fund-manager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('home', {
      path: '/'
    });
    this.route('capfin', function () {
      this.route('testing', function () {
        this.route('invoice-csv-gen');
        this.route('convert-product-type');
        this.route('receipt-csv-gen');
        this.route('demo-gen');
        this.route('refresh-jobs');
      });
      this.route('show', {
        path: '/:taker_uuid'
      });
      this.route('receivables-finance', function () {
        this.route('clients');
        this.route('invoices', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('priority');
            this.route('pending');
            this.route('approved');
            this.route('sent');
            this.route('past-due');
            this.route('closed');
          });
        });
        this.route('advances', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('pending');
            this.route('initiated');
            this.route('sent');
          });
          this.route('new');
        });
        this.route('fees');
        this.route('balance-adjustments');
      });
      this.route('factoring', function () {
        this.route('clients');
        this.route('invoices', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('priority');
            this.route('pending');
            this.route('approved');
            this.route('purchased');
            this.route('partially-paid');
            this.route('past-due');
            this.route('closed');
          });
        });
        this.route('settlements', function () {
          this.route('show', {
            path: '/:settlement_uuid'
          });
          this.route('index', {
            path: '/'
          }, function () {
            this.route('unsettled', function () {
              this.route('show', {
                path: '/:client_uuid'
              });
            });
            this.route('pending');
            this.route('initiated');
            this.route('sent');
            this.route('canceled');
          });
        });
        this.route('fees', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('client-fees');
            this.route('invoice-fees');
          });
        });
      });
      this.route('debtors', function () {
        this.route('index', {
          path: '/'
        });
        this.route('show', {
          path: '/:debtor_uuid'
        });
      });
      this.route('clients', function () {
        this.route('index', {
          path: '/'
        });
        this.route('show', {
          path: '/:client_uuid'
        }, function () {
          this.route('invoices');
          this.route('debtors', function () {
            this.route('show', {
              path: '/:debtor_uuid'
            });
            this.route('new');
          });
          this.route('advances');
          this.route('settlements');
          this.route('receipts');
          this.route('detail');
          this.route('bank');
          this.route('files');
          this.route('status');
          this.route('invoice-testing');
          this.route('client-invoice-uploads');
        });
      });
      this.route('all-receipts', function () {
        this.route('unallocated-receipts');
        this.route('receipts', function () {
          this.route('index', {
            path: '/'
          });
          this.route('show', {
            path: '/:receipt_uuid'
          });
          this.route('new');
        });
        this.route('uploads', function () {
          this.route('index', {
            path: '/'
          });
        });
      });
      this.route('review-receipt', function () {
        this.route('show', {
          path: '/:stagedReceiptUploadUuid'
        }, function () {
          this.route('success');
          this.route('summary');
          this.route('debtor-remittance', function () {
            this.route('show', {
              path: '/:stagedDebtorRemittanceUuid'
            }, function () {
              this.route('allocations');
            });
          });
        });
      });
      this.route('client-debtor-mapping', function () {
        this.route('index', {
          path: '/'
        }, function () {
          this.route('unmapped');
          this.route('mapped');
        });
      });
      this.route('admin', function () {
        this.route('reference-rates', function () {
          this.route('new');
          this.route('show', {
            path: '/:reference_rate_uuid'
          });
        });
        this.route('funding-sources', function () {
          this.route('index', {
            path: '/'
          });
          this.route('show', {
            path: '/:funding_source_uuid'
          });
        });
        this.route('term-code', function () {
          this.route('index', {
            path: '/'
          });
          this.route('show', {
            path: '/:term_code_uuid'
          });
          this.route('new');
        });
        this.route('user-search', function () {
          this.route('user', {
            path: '/:user_auth_uuid'
          }, function () {});
        });
        this.route('bank-statements', function () {
          this.route('index', {
            path: '/'
          });
        });
        this.route('unsettled-clients', function () {
          this.route('index', {
            path: '/'
          });
        });
        this.route('month-end-factoring-reports', function () {
          this.route('index', {
            path: '/'
          });
        });
      });
      this.route('onboarding', function () {
        this.route('rf', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('diligence');
            this.route('prequalification');
          });
        });
      });
    });

    // Handle missing routes
    this.route('page-not-found', {
      path: '/*404'
    });
  });
  var _default = _exports.default = Router;
});
