define("tpf-fund-manager/fixtures/states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // list of US state abbreviations for form usage
  var _default = _exports.default = [{
    AL: 'Alabama'
  }, {
    AK: 'Alaska'
  }, {
    AZ: 'Arizona'
  }, {
    AR: 'Arkansas'
  }, {
    CA: 'California'
  }, {
    CO: 'Colorado'
  }, {
    CT: 'Connecticut'
  }, {
    DE: 'Delaware'
  }, {
    FL: 'Florida'
  }, {
    GA: 'Georgia'
  }, {
    HI: 'Hawaii'
  }, {
    ID: 'Idaho'
  }, {
    IL: 'Illinois'
  }, {
    IN: 'Indiana'
  }, {
    IA: 'Iowa'
  }, {
    KS: 'Kansas'
  }, {
    KY: 'Kentucky'
  }, {
    LA: 'Louisiana'
  }, {
    ME: 'Maine'
  }, {
    MD: 'Maryland'
  }, {
    MA: 'Massachusetts'
  }, {
    MI: 'Michigan'
  }, {
    MN: 'Minnesota'
  }, {
    MS: 'Mississippi'
  }, {
    MO: 'Missouri'
  }, {
    MT: 'Montana'
  }, {
    NE: 'Nebraska'
  }, {
    NV: 'Nevada'
  }, {
    NH: 'New Hampshire'
  }, {
    NJ: 'New Jersey'
  }, {
    NM: 'New Mexico'
  }, {
    NY: 'New York'
  }, {
    NC: 'North Carolina'
  }, {
    ND: 'North Dakota'
  }, {
    OH: 'Ohio'
  }, {
    OK: 'Oklahoma'
  }, {
    OR: 'Oregon'
  }, {
    PA: 'Pennsylvania'
  }, {
    RI: 'Rhode Island'
  }, {
    SC: 'South Carolina'
  }, {
    SD: 'South Dakota'
  }, {
    TN: 'Tennessee'
  }, {
    TX: 'Texas'
  }, {
    UT: 'Utah'
  }, {
    VT: 'Vermont'
  }, {
    VA: 'Virginia'
  }, {
    WA: 'Washington'
  }, {
    WV: 'West Virginia'
  }, {
    WI: 'Wisconsin'
  }, {
    WY: 'Wyoming'
  },
  // us districts, terrories, possessions
  {
    AS: 'American Samoa'
  }, {
    DC: 'District of Colombia'
  }, {
    FSM: 'Federated States of Micronesia'
  }, {
    GU: 'Guam'
  }, {
    MH: 'Marshall Islands'
  }, {
    MP: 'Northern Mariana Islands'
  }, {
    PW: 'Palau'
  }, {
    PR: 'Puerto Rico'
  }, {
    VI: 'Virgin Islands'
  }];
});
