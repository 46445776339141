define("tpf-fund-manager/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // logic to preserve serializeIntoHash from JSONSerializer as referred below
  // https://github.com/emberjs/data/blob/v2.11.0/addon/serializers/json.js#L17
  var assign = Ember.assign || Ember.merge;

  // This will eventually become the Feathersjs serializer for ember-data.
  // Currently it works for collections, not yet tested for single resources.
  var _default = _exports.default = _emberData.default.RESTSerializer.extend({
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      // we want the default behavior of JSONSerializer instead of RESTSerializer:
      // https://github.com/emberjs/data/blob/v2.11.0/addon/serializers/json.js#L1085
      // (don't key data off model)
      assign(hash, this.serialize(snapshot, options));
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var convertedPayload = {
        meta: {
          // Work on setting these, will likely need to map the request as well
          // to make them go to the $sort, $limit that feathersjs uses
          //
          // Need to set them to the page numbers. Then on the query out
          // need to change page number to $sort, and $limit style
          next: null,
          previous: null,
          pageSize: payload.meta.size,
          itemsCount: payload.meta.totalElements,
          pagesCount: payload.meta.pageCounts,
          // The V2 API is zero based for the page index.
          page: payload.meta && payload.meta.pageNumber ? payload.meta.pageNumber + 1 : 1
        }
      };

      // Used for paging
      var limit = payload.limit;
      var skip = payload.skip;
      var total = payload.total;
      if (!Ember.isNone(payload.data)) {
        convertedPayload[primaryModelClass.modelName] = payload.data;
      }

      // Setup paging
      if (!Ember.isNone(skip) && !Ember.isNone(limit) && !Ember.isNone(total)) {
        var next;
        var previous;
        if (skip === 0) {
          next = limit;
          previous = null;
        } else {
          previous = skip - limit;
          if (skip + limit >= total) {
            next = null;
          } else {
            next = skip + limit;
          }
        }
        convertedPayload.meta.next = next;
        convertedPayload.meta.previous = previous;
        convertedPayload.meta.pageSize = limit;
      }
      if (!Ember.isNone(total)) {
        convertedPayload.meta.total = total;
        convertedPayload.meta.itemsCount = total;
      }
      if (!Ember.isNone(total) && !Ember.isNone(limit)) {
        convertedPayload.meta.pagesCount = Math.ceil(total / limit);
      }
      if (!Ember.isNone(total) && !Ember.isNone(limit)) {
        convertedPayload.meta.page = Math.floor(skip / limit) + 1;
      }
      return this._super(store, primaryModelClass, convertedPayload, id, requestType);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var convertedPayload = {};
      convertedPayload[primaryModelClass.modelName] = payload;

      // Include the id so the internal calls to push onto the data store work. Otherwise you'll
      // get a cryptic error saying that an id is needed to call push.
      convertedPayload[primaryModelClass.modelName].id = id || payload.id;
      return this._super(store, primaryModelClass, convertedPayload, id, requestType, true);
    }
  });
});
