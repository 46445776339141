define("tpf-fund-manager/routes/markets/show/buyer-toggle/show", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model(params) {
      var _this = this;
      var buyerToggleClientUuid = params.buyer_toggle_client_uuid;
      return Ember.RSVP.hash({
        buyerToggleClient: this.get('store').findRecord('buyer-toggle-client', buyerToggleClientUuid)
      }).then(function (data) {
        return Ember.RSVP.hash({
          buyerToggleClient: data.buyerToggleClient,
          buyerToggleOnboardingStatus: _this.get('store').findRecord('buyer-toggle-onboarding-status-view', data.buyerToggleClient.get('divisionUuid'))
        });
      }).then(function (data) {
        data.tabs = Ember.A([Ember.Object.create({
          route: 'markets.show.buyer-toggle.show.onboarding-answers',
          label: 'Onboarding Answers'
        }), Ember.Object.create({
          route: 'markets.show.buyer-toggle.show.status',
          label: 'Status'
        }), Ember.Object.create({
          route: 'markets.show.buyer-toggle.show.bank-account',
          label: 'Bank Account'
        })]);
        return data;
      });
    }
  });
});
