import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    uuid: Model.attr(),
    clientUuid: Model.attr(),
    advanceRate: Model.attr(),
    clientDebtorUuid: Model.attr(),
    tpfImportUploadStatusId: Model.attr(),
    amount: Model.attr(),
    netAmount: Model.attr(),
    invoiceId: Model.attr(),
    orderId: Model.attr(),
    companyId: Model.attr(),
    currency: Model.attr(),
    dueDate: Model.attr(),
    projectedDueDate: Model.attr(),
    transactionDate: Model.attr(),
    approvedDate: Model.attr('naive-date'),
    status: Model.attr(),
    supplierDisplayStatus: Model.attr(),
    isVerified: Model.attr(),
    termCodeUuid: Model.attr(),
    removedDate: Model.attr(),
    verificationUuid: Model.attr(),
    verificationPriority: Model.attr(),
    verificationReasonCode: Model.attr(),
    anomalyReasons: Model.attr(),
    debtorUuid: Model.attr(),
    purchasedDate: Model.attr(),
    verificationMethod: Model.attr(),
    verificationNote: Model.attr(),
    verificationDate: Model.attr(),
    divisionUuid: Model.attr(),
    clientName: Model.attr(),
    debtorName: Model.attr(),
    companyName: Model.attr(),
    purchasePrice: Model.attr(),
    discountFee: Model.attr(),
    minimumTenor: Model.attr(),
    rate: Model.attr(),
    rateType: Model.attr(),
    uploadedDate: Model.attr(),
    isPurchased: Model.attr(),
    chargeBackDate: Model.attr()
});
