import DS from 'ember-data';
import Ember from 'ember';
import Model from '../utils/model-util';
import { memberAction } from 'ember-api-actions';

export default DS.Model.extend({
    // PATCH /clients/:client_uuid/add-debtor
    addDebtor: memberAction({
        path: 'add-debtor',
        type: 'patch'
    }),

    // PATCH /clients/:client_uuid/remove-debtor
    removeDebtor: memberAction({
        path: 'remove-debtor',
        type: 'patch'
    }),

    uuid: Model.attr(),
    divisionUuid: Model.attr(),
    divisionId: Model.attr(),
    organizationUuid: Model.attr(),
    clientName: Model.attr(),
    whiteOakClientNumber: Model.attr(),
    productClassification: Model.attr(),
    purchasedInvoiceCount: Model.attr(),
    purchasedInvoiceAmount: Model.attr(),
    purchasedInvoiceAdvanceAmount: Model.attr(),
    creditLimit: Model.attr(),
    advanceRate: Model.attr(),
    rate: Model.attr(),
    nextInvoiceDueDate: Model.attr(),
    debtors: DS.hasMany('ClientDebtor'),
    collectionDays: Model.attr(),
    transferMethodPreference: Model.attr(),
    wireFee: Model.attr(),
    invoiceFee: Model.attr(),
    whiteOakSalesforceAccountId: Model.attr(),
    whiteOakSalesforceOpportunityId: Model.attr(),
    uccFilingState: Model.attr(),
    uccFilingDate: Model.attr(),
    uccFirstOption: Model.attr(),
    uccFirstNote: Model.attr(),
    hasDepositAccountControlAgreement: Model.attr(),
    collateral: Model.attr(),
    collateralOther: Model.attr(),
    guarantor: Model.attr(),
    rateType: Model.attr(),
    riskNotes: Model.attr(),
    riskRating: Model.attr(),
    interestRateChangeFrequency: Model.attr(),
    effectiveDate: Model.attr(),
    maturityInterval: Model.attr(),
    maturityLength: Model.attr(),
    fundingSourceUuid: Model.attr(),
    isApprovedAndConfigured: DS.attr(),
    isUnderwritingDisapproved: DS.attr(),
    isBankAccountRequired: DS.attr(),
    outstandingBalance: Model.attr(), //View model only - read only
    arBalance: Model.attr(), //View model only - read only
    advanceAmount: Model.attr(), //View model only - read only
    marginalCollateralBalance: Model.attr(), //View model only - read only
    availabilityBalance: Model.attr(), //View model only - read only
    created: Model.attr(),
    updated: Model.attr(),
    isTermSheetAccepted: Model.attr(),
    salesforceAccountId: Model.attr(),
    productType: Model.attr(),
    closedDate: Model.attr(),
    isClosed: Model.attr(),

    chargebackDays: Model.attr(),

    /**
     * Not just a helper computed; in the future this should be the primary
     * way we determine if a client has factoring, in order to insulate us for a
     * multi product type future.
     */
    isFactoring: Ember.computed.equal('productType', 'FACTORING')
});
