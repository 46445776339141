import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    divisionId: Model.attr('id'),
    marketId: Model.attr('id'),
    filePath: Model.attr('string'),
    fileName: Model.attr('string'),
    filePathType: Model.attr('string'),
    deleted: Model.attr('boolean'),
    created: Model.attr('date'),
    updated: Model.attr('date'),
    type: Model.attr('string'),
    period: Model.attr('string'),
    isExpired: Model.attr('boolean'),
    isReviewed: Model.attr('boolean')
});
