define("tpf-fund-manager/initializers/accounting", ["exports", "accounting/settings"], function (_exports, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // app/initializers/accounting.js

  function initialize( /* application */
  ) {
    _settings.currency.symbol = '$';
    _settings.number.decimal = '.';
    _settings.number.thousand = ',';
  }
  var _default = _exports.default = {
    name: 'accounting',
    initialize: initialize
  };
});
