define("tpf-fund-manager/helpers/receipt-upload-summary-display-status", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.receiptUploadSummaryDisplayStatus = receiptUploadSummaryDisplayStatus;
  var distplayStatuses = {
    INVALID: 'Rejected',
    COMMITTED: 'Submitted',
    REJECTED: 'Rejected',
    IMPORTED: 'Processing',
    CHANGED: 'Unreviewed',
    APPROVED: 'Approved',
    LINKED: 'Unreviewed'
  };
  function receiptUploadSummaryDisplayStatus(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      status = _ref2[0];
    return distplayStatuses[status] || status;
  }
  var _default = _exports.default = Ember.Helper.helper(receiptUploadSummaryDisplayStatus);
});
