import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    clientUuid: Model.attr(),
    name: Model.attr(),
    companyId: Model.attr(),
    companyName: Model.attr(),
    firstUploaded: Model.attr()
});
