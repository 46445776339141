define("tpf-fund-manager/mixins/auto-generate-primary-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    normalizeQueryResponse: function normalizeQueryResponse(store, type, payload, id, requestType, isSingle) {
      var idx = 0;
      var meta = payload.meta,
        data = payload.data;
      data.forEach(function (it) {
        return it.id = it.transactionType + '_' + it.createdTime + '_' + idx++;
      });
      return this._super(store, type, {
        meta: meta,
        data: data
      }, id, requestType, isSingle);
    }
  });
});
