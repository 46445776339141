define("tpf-fund-manager/mixins/selectable-grid-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Mixin used to make the rows of a grid selectable.
   * The component must have a property for 'selectableItems' and 'id' (i.e. id, uuid).
   */
  var _default = _exports.default = Ember.Mixin.create({
    selectedAll: false,
    /**
     * Returns the query for the currently selected items in the grid.
     *
     * @returns {*}
     */
    getSelectedQuery: function getSelectedQuery() {
      var items = this.get('selectableItems');
      var idField = this.get('id');
      Ember.assert("Component with selectable-grid-mixin must have a property 'selectableItems'", items);
      Ember.assert("Component with selectable-grid-mixin must have a property 'id'", idField);
      var query = {};
      var selectedItems = this.getSelectedItems();
      var itemIds = selectedItems.map(function (item) {
        return item.get(idField);
      });
      if (itemIds.length) {
        query[idField] = itemIds;
      } else {
        throw new Error('No items selected');
      }
      return query;
    },
    getSelectedItems: function getSelectedItems() {
      var items = this.get('selectableItems');
      var selectedItems = items.filter(function (item) {
        return item.get('selected');
      });
      return selectedItems;
    },
    isGridEmpty: Ember.computed.equal('selectableItems.length', 0),
    gridTotalItems: Ember.computed.alias('selectableItems.meta.itemsCount'),
    selectedItems: Ember.computed('selectableItems.@each.selected', function () {
      return this.getSelectedItems();
    }),
    hasItemSelected: Ember.computed('selectableItems.@each.selected', 'selectedAll', function () {
      var selectable = this.get('selectableItems');
      if (!selectable) {
        return false;
      }
      return selectable.findBy && !!selectable.findBy('selected', true);
    }),
    /**
     * Toggles the selection of all the rows in the grid.
     *
     * @param value - boolean parameter that determine if the rows are selected or not.
     */
    toggleSelection: function toggleSelection(value) {
      var items = this.get('selectableItems');
      Ember.assert("Component with selectable-grid-mixin must have a property 'selectableItems'", !Ember.isEmpty(items));
      items.forEach(function (item) {
        item.set('selected', value);
      });
    },
    /**
     *  Reset all of the rows to not be selected.
     */
    resetSelection: function resetSelection() {
      var items = this.get('selectableItems');
      Ember.assert("Component with selectable-grid-mixin must have a property 'selectableItems'", !Ember.isEmpty(items));
      items.update();
      this.toggleSelection(false);
      this.set('selectedAll', false);
    },
    actions: {
      /**
       * Action that will be wired to the selecting/deselecting of a row.
       *
       * @param item - the item for the particular row that is being selected/deselected.
       */
      select: function select(item) {
        var selected = !item.get('selected');
        // If a value is unselected remove the select all option
        if (!selected) {
          this.set('selectedAll', false);
        }
        item.set('selected', selected);
      },
      /**
       * Toggles the rows from selected to not selected or vice versa.
       */
      toggleAllSelection: function toggleAllSelection() {
        var selectedAll = this.get('selectedAll');
        this.toggleSelection(!selectedAll);
        //selectedAll set last to enable observers to implement select logic (see checkboxes)
        this.set('selectedAll', !selectedAll);
      }
    }
  });
});
