define("tpf-fund-manager/routes/capfin/clients/show/invoices", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      var client = this.modelFor('capfin.clients.show').client;
      return Ember.RSVP.hash(this.getPreviousFilters({
        invoices: {
          _modelName: 'invoice',
          clientUuid: client.get('uuid'),
          sort: {
            dueDate: 'ASC'
          },
          pageSize: 25
        }
      })).then(function (model) {
        Object.assign(model, {
          client: client
        });
        return model;
      });
    },
    actions: {
      refreshRoute: function refreshRoute() {
        return this.refresh();
      }
    }
  });
});
