define("tpf-fund-manager/routes/capfin/clients/show/receipts", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      var clientUuid = this.modelFor('capfin.clients.show').client.get('uuid');
      return Ember.RSVP.hash(this.getPreviousFilters({
        receiptView: {
          _modelName: 'receipt',
          clientUuid: clientUuid,
          sort: {
            receivedDate: 'DESC'
          },
          pageSize: 25
        }
      }));
    }
  });
});
