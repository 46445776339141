// import Ember from 'ember';
// import C2FOAuthenticatedRouteMixin from 'tpf-fund-manager/mixins/c2fo-authenticated-route-mixin';
//
// export default Ember.Route.extend(C2FOAuthenticatedRouteMixin, {
//     session: Ember.inject.service('session'),
//     sortService: Ember.inject.service('sort-state'),
//
//     /*model(params) {
//         return Ember.RSVP.hash({
//             market: this.get('store').findRecord('market', params.market_id),
//             aggregateMarketStat: this.get('store').findRecord('aggregate-market-stat', params.market_id),
//             tpfLoanStatAggregate: this.get('store').findRecord('tpf-loan-stat-aggregate', params.market_id)
//         });
//     },*/
//
//     clearSort() {
//         const data = this.get('data');
//         this.get('sortService').clear(data.modelName);
//         if (data.query.sort) {
//             delete data.query.sort;
//         }
//     },
//
//     actions: {
//         /*updateMarket(market) {
//             return market.save().then(() => this.send('refreshRoute'));
//         },*/
//
//         refreshRoute() {
//             return this.refresh();
//         }
//     }
// });
define("tpf-fund-manager/routes/markets/show", [], function () {
  "use strict";
});
