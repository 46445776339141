import DS from 'ember-data';

export default DS.Model.extend({
    uuid: DS.attr(),
    clientUuid: DS.attr(),
    type: DS.attr(),
    amount: DS.attr(),
    remainingAmount: DS.attr(),
    discountFee: DS.attr(),
    advanceRate: DS.attr(),
    advanceAmount: DS.attr(),
    purchasePrice: DS.attr(),
    amountPaid: DS.attr(),
    invoiceId: DS.attr()
});
