define("tpf-fund-manager/controllers/capfin/client-debtor-mapping/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    activeRouteName: Ember.computed('router.currentRouteName', {
      get: function get() {
        return this.get('router.currentRouteName');
      }
    })
  });
});
