define("tpf-fund-manager/initializers/model-fragments", ["exports", "ember-data-model-fragments", "ember-data-model-fragments/transforms/fragment", "ember-data-model-fragments/transforms/fragment-array", "ember-data-model-fragments/transforms/array"], function (_exports, _emberDataModelFragments, _fragment, _fragmentArray, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Import the full module to ensure monkey-patchs are applied before any store
  // instances are created. Sad face for side-effects :(
  var _default = _exports.default = {
    name: 'fragmentTransform',
    before: 'ember-data',
    initialize: function initialize(application) {
      application.register('transform:fragment', _fragment.default);
      application.register('transform:fragment-array', _fragmentArray.default);
      application.register('transform:array', _array.default);
    }
  };
});
