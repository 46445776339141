define("tpf-fund-manager/components/supplier-financial-files", ["exports", "tpf-fund-manager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    selectedAll: false,
    selectedAllPostOnboard: false,
    _generateSelectedFilesQuery: function _generateSelectedFilesQuery() {
      var selectedAll = this.get('selectedAll');
      var financialFiles = this.get('financialFiles');
      var query = null;
      if (selectedAll) {
        query = Ember.merge({}, financialFiles.query);
        delete query.pageSize;
        delete query.page;
      } else {
        var fileUuids = [];
        financialFiles.forEach(function (item) {
          var selected = item.get('selected');
          if (selected) {
            fileUuids.push(item.uuid);
          }
        });
        query = {
          uuid: fileUuids
        };
      }
      return query;
    },
    noFileSelected: Ember.computed('financialFiles.@each.selected', function () {
      var financialFiles = this.get('financialFiles');
      var noneSelected = true;
      financialFiles.forEach(function (item) {
        var selected = item.get('selected');
        if (selected) {
          noneSelected = false;
        }
      });
      return noneSelected;
    }),
    noPostOnboardFileSelected: Ember.computed('financialFilesPostOnboard.@each.selected', function () {
      var financialFilesPostOnboard = this.get('financialFilesPostOnboard');
      var noneSelected = true;
      if (financialFilesPostOnboard && financialFilesPostOnboard.content && financialFilesPostOnboard.content.length > 0) {
        financialFilesPostOnboard.forEach(function (item) {
          var selected = item.get('selected');
          if (selected) {
            noneSelected = false;
          }
        });
      }
      return noneSelected;
    }),
    toggleSelection: function toggleSelection(value) {
      var financialFiles = this.get('financialFiles');
      financialFiles.forEach(function (item) {
        item.set('selected', value);
      });
    },
    toggleSelectionPostOnboard: function toggleSelectionPostOnboard(value) {
      var financialFiles = this.get('financialFilesPostOnboard');
      financialFiles.forEach(function (item) {
        item.set('selected', value);
      });
    },
    resetSelection: function resetSelection() {
      var financialFiles = this.get('financialFiles');
      financialFiles.update();
      this.toggleSelection(false);
      this.set('selectedAll', false);
    },
    resetSelectionPostOnboard: function resetSelectionPostOnboard() {
      var financialFiles = this.get('financialFilesPostOnboard');
      financialFiles.update();
      this.toggleSelectionPostOnboard(false);
      this.set('selectedAllPostOnboard', false);
    },
    actions: {
      select: function select(file) {
        var selected = !file.get('selected');
        // If a value is unselected remove the select all option
        if (!selected) {
          this.set('selectedAll', false);
        }
        file.set('selected', selected);
      },
      selectPostOnboard: function selectPostOnboard(file) {
        var selected = !file.get('selected');
        // If a value is unselected remove the select all option
        if (!selected) {
          this.set('selectedAllPostOnboard', false);
        }
        file.set('selected', selected);
      },
      toggleAllSelection: function toggleAllSelection() {
        var selectedAll = this.get('selectedAll');
        this.set('selectedAll', !selectedAll);
        this.toggleSelection(!selectedAll);
      },
      toggleAllSelectionPostOnboard: function toggleAllSelectionPostOnboard() {
        var selectedAllPostOnboard = this.get('selectedAllPostOnboard');
        this.set('selectedAllPostOnboard', !selectedAllPostOnboard);
        this.toggleSelectionPostOnboard(!selectedAllPostOnboard);
      },
      downloadZippedFiles: function downloadZippedFiles() {
        var financialFiles = this.get('financialFiles');
        var divisionUuid = this.get('client').get('divisionUuid');
        var fileUuids = financialFiles.filterBy('selected').mapBy('uuid');
        var token = this.get('session.data.authenticated.token');
        var data = {
          token: token,
          financialFileUuids: fileUuids,
          divisionUuid: divisionUuid
        };
        var url = "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/financial-files/zipped?") + Ember.$.param(data);
        window.open(url, '_blank');
      },
      downloadZippedFilesPostOnboard: function downloadZippedFilesPostOnboard() {
        var financialFiles = this.get('financialFilesPostOnboard');
        var divisionUuid = this.get('client').get('divisionUuid');
        var fileUuids = financialFiles.filterBy('selected').mapBy('uuid');
        var token = this.get('session.data.authenticated.token');
        if (!token) {
          return;
        }
        var data = {
          token: token,
          financialFileUuids: fileUuids,
          divisionUuid: divisionUuid
        };
        var url = "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/financial-files/zipped?") + Ember.$.param(data);
        window.open(url, '_blank');
      },
      downloadFile: function downloadFile(fileUuid) {
        var session = this.get('session');
        var token = session.get('data.authenticated.token');
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var request = Ember.$.ajax({
            url: "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/financial-files/").concat(fileUuid),
            type: 'GET',
            contentType: 'application/json',
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', "Bearer ".concat(token));
            }
          });
          request.done(function (response) {
            var objectResponse = JSON.parse(response);
            resolve(window.open(objectResponse.url, '_blank'));
          });
          request.fail(function (jqXHR, textStatus, errorThrown) {
            reject(errorThrown);
          });
        });
      },
      expireFiles: function expireFiles() {
        var _this = this;
        var financialFiles = this.get('financialFiles');
        var fileUuids = financialFiles.filterBy('selected').mapBy('uuid');
        var divisionUuid = this.get('client').get('divisionUuid');
        var data = {
          financialFileUuids: fileUuids,
          divisionUuid: divisionUuid
        };
        return this.get('bulkUpdateFinancialFiles')(data, '/expire').then(function () {
          _this.resetSelection();
        });
      },
      reviewFiles: function reviewFiles() {
        var _this2 = this;
        var financialFiles = this.get('financialFiles');
        var fileUuids = financialFiles.filterBy('selected').mapBy('uuid');
        var divisionUuid = this.get('client').get('divisionUuid');
        var data = {
          financialFileUuids: fileUuids,
          divisionUuid: divisionUuid
        };
        return this.get('bulkUpdateFinancialFiles')(data, '/review').then(function () {
          _this2.resetSelection();
        });
      },
      approveFiles: function approveFiles() {
        var _this3 = this;
        var financialFiles = this.get('financialFilesPostOnboard');
        var fileUuids = financialFiles.filterBy('selected').mapBy('uuid');
        var divisionUuid = this.get('client').get('divisionUuid');
        var data = {
          financialFileUuids: fileUuids,
          divisionUuid: divisionUuid,
          approvalAction: 'APPROVED'
        };
        return this.get('bulkUpdateFinancialFiles')(data, '/update-approval-status').then(function () {
          _this3.resetSelectionPostOnboard();
        });
      },
      rejectFiles: function rejectFiles(reviewComment) {
        var _this4 = this;
        var financialFiles = this.get('financialFilesPostOnboard');
        var fileUuids = financialFiles.filterBy('selected').mapBy('uuid');
        var divisionUuid = this.get('client').get('divisionUuid');
        var data = {
          financialFileUuids: fileUuids,
          divisionUuid: divisionUuid,
          approvalAction: 'REJECTED',
          reviewComment: reviewComment
        };
        return this.get('bulkUpdateFinancialFiles')(data, '/update-approval-status').then(function () {
          _this4.resetSelectionPostOnboard();
        });
      }
    }
  });
});
