define("tpf-fund-manager/components/feathers-pill-filters", ["exports", "moment", "tpf-fund-manager/utils/model-util", "tpf-fund-manager/templates/components/feathers-pill-filters"], function (_exports, _moment, _modelUtil, _feathersPillFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _feathersPillFilters.default,
    classNameBindings: ['isResponsive:hide-gt-sm'],
    // show dismiss/close button
    allowClose: true,
    // adds responsive css classes to only show on small resolutions
    isResponsive: true,
    modelUtil: Ember.Object.create(_modelUtil.default),
    filterService: Ember.inject.service('filter-state'),
    addedFilters: Ember.computed('data.isUpdating', {
      get: function get() {
        var data = this.get('data');
        return data ? this.fillPills(data) : [];
      }
    }),
    fillPills: function fillPills(data) {
      var _this = this;
      var defaultIgnores = this.get('defaultIgnoreFilters') || [];
      var ignores = this.get('ignoreFilters') || [];
      var filters = [];
      Object.keys(data.query).forEach(function (key) {
        var prettyName = _this.modelUtil.getModelDisplayName(data, key);
        //Check if this is a range filter.
        if (Ember.typeOf(data.query[key]) === 'object' && (data.query[key]['gte'] || data.query[key]['lte'])) {
          var startDate = data.query[key]['gte'] ? (0, _moment.default)(data.query[key]['gte']).format('MM/DD/YYYY') : 'EMPTY',
            endDate = data.query[key]['lte'] ? (0, _moment.default)(data.query[key]['lte']).format('MM/DD/YYYY') : 'EMPTY';
          filters.push({
            name: prettyName,
            key: key,
            value: startDate + ' - ' + endDate
          });
        } else {
          // Do not attempt to show any data that is a "keyword" for
          // feathers parameters.
          //
          // Also do not show filters that have been listed as an ignore filter.
          if (key.indexOf('$') !== 0 && ignores.indexOf(key) < 0 && defaultIgnores.indexOf(key) == -1) {
            // don't show empty objects as pills
            if (Object.keys(data.query[key]).length > 0) {
              filters.push({
                name: prettyName,
                key: key,
                value: data.query[key]
              });
            }
          }
        }
      });
      return filters;
    },
    actions: {
      removeFilter: function removeFilter(filterName) {
        var _this2 = this;
        // Array of all the possible filters for a fieldName.
        var filters = [filterName, "".concat(filterName, "[$in]"), "".concat(filterName, "[$notIn]")];
        var data = this.get('data');

        // Remove all filters for a fieldName.
        filters.forEach(function (eachFilterName) {
          _this2.get('filterService').deleteParam(data.modelName, eachFilterName);
          delete data.query[eachFilterName];
        });
        this.set('isLoading', true);
        data.update().then(function () {
          _this2.set('isLoading', false);
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.defaultIgnoreFilters = ['sort', 'pageSize'];
    }
  });
});
