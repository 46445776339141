define("tpf-fund-manager/routes/capfin/client-debtor-mapping/index", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      return {
        tabs: Ember.A([Ember.Object.create({
          route: 'capfin.client-debtor-mapping.index.unmapped',
          label: 'Unmapped'
        }), Ember.Object.create({
          route: 'capfin.client-debtor-mapping.index.mapped',
          label: 'Mapped'
        })])
      };
    }
  });
});
