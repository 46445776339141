import Ember from 'ember';
import DS from 'ember-data';
import Model from '../utils/model-util';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default DS.Model.extend({
    uuid: Model.attr(),
    receivedDate: Model.attr(),
    debtorUuid: Model.attr(),
    clientUuid: Model.attr(),
    receivedAmount: Model.attr(),
    paymentType: Model.attr(),
    isCollectionSuccessful: Model.attr(),
    paymentReferenceNumber: Model.attr(),
    created: Model.attr(),
    debtorName: Model.attr(),
    clientName: Model.attr(),
    grossAmount: Model.attr('number'),
    deductionAmount: Model.attr('number'),
    discountAmount: Model.attr('number'),
    isPending: Model.attr(),
    isClientRemitted: Model.attr(),
    bankAccountId: Model.attr(),

    invoiceAllocations: fragmentArray('receipt-invoice-allocation'),
    eligibleInvoiceAllocations: Ember.computed('invoiceAllocations.@each.invoiceUuid', function() {
        return this.get('invoiceAllocations').filter(x => x.get('allocationType') === 'ELIGIBLE');
    }),
    ineligibleInvoiceAllocations: Ember.computed('invoiceAllocations.@each.invoiceUuid', function() {
        return this.get('invoiceAllocations').filter(x => x.get('allocationType') === 'INELIGIBLE');
    })
});
