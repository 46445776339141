define("tpf-fund-manager/components/receipt-upload-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LINK_POLL_DELAY_MS = 5000;
  // retry / 5sec = 2 minutes, very conservative,
  // but we have big files and an endpoint not entirely made for them.
  // can tweak this if we see otherwise.
  var POLL_THRESHOLD_WARNING = 24;
  var _default = _exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.get('stagedReceiptUpload').summary().then(function (response) {
        _this.set('numberOfReceipts', response.numberOfReceipts);
        _this.set('numberOfErrors', response.numberOfErrors);
        _this.set('totalAmount', response.totalAmount);
      });
      this.set('refreshCalls', 0);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get('stillProcessingReceipts') > 0) {
        var refreshCalls = this.get('refreshCalls');
        this.set('refreshCalls', refreshCalls + 1);
        var refreshFunction = this.get('refresh');
        Ember.run.later(this, refreshFunction, LINK_POLL_DELAY_MS);
      }
    },
    longRunningLink: Ember.computed('refreshCalls', function () {
      return this.get('refreshCalls') > POLL_THRESHOLD_WARNING;
    }),
    noRiskCounts: Ember.computed(function () {
      return this.get('noRiskRemittance').content.length;
    }),
    needsReviewCounts: Ember.computed(function () {
      return this.get('needsReviewRemittance').content.length;
    }),
    reviewedCounts: Ember.computed(function () {
      return this.get('reviewedRemittance').content.length;
    })
  });
});
