define("tpf-fund-manager/routes/capfin/all-receipts/receipts/new", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      return {
        Receipt: this.get('store').createRecord('receipt', {
          isPending: false
        })
      };
    },
    actions: {
      saveReceipt: function saveReceipt(Receipt) {
        var _this = this;
        return Receipt.save().then(function () {
          // Unload invoice allocation records.  Otherwise they will hang around and there
          // will be duplicates when the receipt is fetched.
          var store = _this.get('store');
          store.unloadAll('receipt-invoice-allocation');
          _this.transitionTo('capfin.all-receipts.receipts');
        });
      },
      cancelReceipt: function cancelReceipt(Receipt) {
        Receipt.rollbackAttributes();
        this.transitionTo('capfin.all-receipts.receipts');
      }
    }
  });
});
