define("tpf-fund-manager/routes/capfin/admin/bank-statements/index", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin", "tpf-fund-manager/config/environment"], function (_exports, _c2foAuthenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      return this.get('store').query('bank-statement-upload', {
        sort: {
          uploadTimestamp: 'DESC'
        }
      }).then(function (statements) {
        return Ember.RSVP.hash({
          statements: statements
        });
      });
    },
    actions: {
      onFileUpload: function onFileUpload(file) {
        var _this = this;
        var token = this.get('session').get('data.authenticated.token');
        var bankStatementUploadUrl = "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/bank-statement-uploads/").concat(file.blob.name);
        file.uploadBinary(bankStatementUploadUrl, {
          contentType: file.blob.type,
          headers: {
            authorization: "Bearer ".concat(token)
          }
        }).then(function (result) {
          var numAccounts = result.body.numSuccessfulAccounts;
          var numTransactions = result.body.numSuccessfulTransactions;
          _this.refresh();
          if (result.body.parseErrors.length !== 0) {
            _this.get('toast').error("There are errors while parsing the BAI2 file.");
          } else {
            _this.get('toast').success("Successfully processed ".concat(numAccounts, " accounts and ").concat(numTransactions, " transactions"));
          }
          _this.get('toast').success("Successfully processed ".concat(numAccounts, " accounts and ").concat(numTransactions, " transactions"));
        }).catch(function (result) {
          _this.get('toast').error("".concat(result.body.message));
        });
      }
    }
  });
});
