define("tpf-fund-manager/controllers/application", ["exports", "tpf-fund-manager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    router: Ember.inject.service(),
    activeRouteName: Ember.computed('router.currentRouteName', {
      get: function get() {
        return this.get('router.currentRouteName');
      }
    }),
    isDevEnvironment: Ember.computed(function () {
      return _environment.default.environment.includes('development');
    }).readOnly(),
    isNonProdEnvironment: Ember.computed(function () {
      return _environment.default.environment === 'frenzy';
    }).readOnly(),
    shouldShowEnvBanner: Ember.computed(function () {
      return this.get('isDevEnvironment') || this.get('isNonProdEnvironment');
    }).readOnly(),
    actions: {
      // Used to allow logging out of the application.
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });
});
