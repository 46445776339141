define("tpf-fund-manager/components/repayment-allocations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isReadOnly: false,
    store: Ember.inject.service(),
    currentAllocationDate: Ember.observer('allocationDate', function () {
      var allocationDate = this.get('allocationDate');
      this.get('Receipt.invoiceAllocations').forEach(function (allocation) {
        if (allocation.get('allocationDate') !== allocationDate) {
          allocation.set('allocationDate', allocationDate);
        }
      });
    }),
    searchInvoices: function searchInvoices(invoiceId) {
      var isEligible = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      invoiceId = invoiceId.replace(/ /g, '');
      var queryOptions = {
        searchParams: {
          'invoiceId[LIKE]': invoiceId,
          'orderId[LIKE]': invoiceId
        },
        hasBeenApproved: true,
        isEligible: isEligible,
        clientUuid: this.get('clientDebtor.clientUuid'),
        debtorUuid: this.get('clientDebtor.debtorUuid'),
        size: 25
      };
      var allocations = this.get('Receipt.invoiceAllocations.[]');
      return this.get('store').createRecord('invoice').findByInvoiceOrderId(queryOptions).then(function (_invoices) {
        if (allocations === undefined) {
          return _invoices['data'];
        }
        var usedInvoiceUUIDs = allocations.reduce(function (eset, item) {
          eset[item.get('invoiceUuid')] = 1;
          return eset;
        }, {});
        return _invoices.data.reject(function (inv) {
          return usedInvoiceUUIDs[inv.uuid] === 1;
        });
      });
    },
    actions: {
      removeInvoice: function removeInvoice(invoice) {
        this.get('Receipt.invoiceAllocations').removeObject(invoice);
      },
      addAllocation: function addAllocation(model) {
        var data = {
          invoiceUuid: model.invoiceUuid || undefined,
          grossAmount: model.grossAmount,
          deductionAmount: -1 * model.deductionAmount || undefined,
          discountAmount: -1 * model.discountAmount || undefined,
          referenceNumber: model.referenceNumber,
          status: model.invoiceStatus,
          description: model.description,
          allocationDate: this.get('allocationDate'),
          allocationType: this.get('eligible') ? 'ELIGIBLE' : 'INELIGIBLE'
        };
        var InvoiceAllocation = this.get('store').createFragment('ReceiptInvoiceAllocation', data);
        this.get('Receipt.invoiceAllocations').pushObject(InvoiceAllocation);
      },
      searchEligibleInvoices: function searchEligibleInvoices(invoiceId) {
        return this.get('searchInvoices').call(this, invoiceId, true);
      },
      searchIneligibleInvoices: function searchIneligibleInvoices(invoiceId) {
        return this.get('searchInvoices').call(this, invoiceId, false);
      }
    }
  });
});
