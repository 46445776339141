define("tpf-fund-manager/adapters/staged-receipt-upload", ["exports", "tpf-fund-manager/adapters/application-v2-patch-api"], function (_exports, _applicationV2PatchApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _applicationV2PatchApi.default.extend({
    pathForType: function pathForType() {
      return 'receipts/uploads';
    },
    /**
     Called by the store when an staged receipt record is saved
     This is a direct copy of the super class (application-v2-patch-api) method. The only difference
     is modification in URL path to take a query param for riskSeverities.
      @method updateRecord
     @param {DS.Store} store
     @param {DS.Model} type
     @param {DS.Snapshot} snapshot
     @return {Promise} promise
     */
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var submittedData = {};
      var modelName = Ember.String.camelize(type.modelName);
      var serializer = store.serializerFor(modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var id = snapshot.id;
      var url = this.buildURL(modelName, id, snapshot, 'updateRecord');

      // Adjust adapter to accept queryParams for riskSeverities
      if (snapshot.adapterOptions && snapshot.adapterOptions.riskSeverities) {
        url += '?riskSeverities=' + snapshot.adapterOptions.riskSeverities;
      }

      // Use data directly because serializeIntoHash gives the object directly differently for feathers.
      var dataKeys = Object.keys(data);

      // Ensure code only sends the data we are actually updating on a record.
      dataKeys.forEach(function (key) {
        if (key in snapshot.changedAttributes()) {
          submittedData[key] = data[key];
        }
      });
      return this.ajax(url, 'PATCH', {
        data: submittedData
      });
    }
  });
});
