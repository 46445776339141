define("tpf-fund-manager/mixins/c2fo-authenticated-route-mixin", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "tpf-fund-manager/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    tokenQueryParamKey: 'token',
    beforeModel: function beforeModel(transition) {
      var _this = this;
      if (transition.queryParams.token) {
        return this.get('session').authenticate('authenticator:jwt', transition.queryParams.token).then(function () {
          var tokenStrippedURL = _this._removeURLQueryParameter(window.location.href, _this.tokenQueryParamKey);
          window.history.replaceState(window.history.state, '', tokenStrippedURL);
        });
      } else if (this._isInsecureEnvironment()) {
        // disable local auth without relying on api.
        // future: use insecure api flag to pass a fake token instead.
        //
        // NOTE: if also running with C2FO Early Pay you _must_ pass a real working
        // token into this application to get back to the C2FO Early Pay app.
        return this.get('session').authenticate('authenticator:jwt', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7InVzZXIiOnsidXVpZCI6IjU5YzI0MjE1LTMwMWUtNGJkNi1hOTMzLWI0MmI4ZTBmYjkzNCJ9LCJ0eXBlIjoicGFzc3dvcmQifX0.yhjqc_ZGVuYc6K2cdsECs3e8x9YbRtabJWrBWtpaMjg').then(function () {
          return _this._super(transition);
        });
      } else if (!this.get('session.isAuthenticated')) {
        // If the user isn't authenticated redirect to login
        this.triggerAuthentication();
      }
      return this._super(transition);
    },
    triggerAuthentication: function triggerAuthentication() {
      var encodedRedirect = encodeURIComponent(window.location.href);
      var externalAuthUrl = "".concat(_environment.default.APP.c2foAdmin, "/login?redirect-to=").concat(encodedRedirect, "&tokenQueryParam=").concat(this.tokenQueryParamKey);
      window.location.replace(externalAuthUrl);
    },
    _isInsecureEnvironment: function _isInsecureEnvironment() {
      return ['insecure-development', 'insecure-mirage'].includes(_environment.default.environment);
    },
    // shameless: https://stackoverflow.com/questions/1634748/how-can-i-delete-a-query-string-parameter-in-javascript
    _removeURLQueryParameter: function _removeURLQueryParameter(url, parameter) {
      var urlparts = url.split('?');
      if (urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
          //idiom for string.startsWith
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            pars.splice(i, 1);
          }
        }
        url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
      }
      return url;
    }
  });
});
