define("tpf-fund-manager/components/feathers-search", ["exports", "tpf-fund-manager/templates/components/feathers-search"], function (_exports, _feathersSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _feathersSearch.default,
    searchLabel: 'Search',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('id', "".concat(Ember.guidFor(this), "-search"));
    },
    /**
     * Gets the value of the current data query on the datastore for this particular search filter.
     *
     * @param data - The data store
     * @returns {*}
     */
    getValue: function getValue(data) {
      var fieldNames = this.get('fieldNames');
      return data.query.$search[fieldNames[0]] || '';
    },
    /**
     * Issues a search and adds the value to the data store query.
     */
    issueSearch: function issueSearch() {
      var data = this.get('data');
      var fieldName = this.get('fieldName');
      var fieldNames = this.get('fieldNames');
      var searchValue = this.get('searchValue');
      var hasValue = searchValue.length !== 0;
      if (!hasValue) {
        this.clearSearch();
      } else {
        if (!data.query.$search) {
          data.query.$search = {};
        }
        if (fieldName) {
          fieldNames = [fieldName];
        }
        fieldNames.forEach(function (fieldName) {
          data.query.$search[fieldName] = searchValue;
        });
        // if user changes the search data on a page, reset the index that is being searched
        delete data.query.page;
      }
    },
    /**
     * Clears the search filter and removes it from the data query as well.
     */
    clearSearch: function clearSearch() {
      var data = this.get('data');
      var fieldName = this.get('fieldName');
      var fieldNames = this.get('fieldNames');
      if (fieldName) {
        fieldNames = [fieldName];
      }

      // Ensure we have a search to actually remove from.
      if (data.query.$search) {
        this.set('searchValue', undefined);
        fieldNames.forEach(function (fieldName) {
          if (data.query.$search[fieldName]) {
            delete data.query.$search[fieldName];
          }
        });
      }

      // If at any point across all search app-components there are
      // values for the search we should delete the search object.
      if (data.query.$search && Object.keys(data.query.$search).length <= 0) {
        delete data.query.$search;
      }
    },
    actions: {
      issueSearch: function issueSearch() {
        var data = this.get('data');
        this.issueSearch();
        Ember.run.debounce(data, data.update, 200);
      },
      clearSearch: function clearSearch() {
        var data = this.get('data');
        this.clearSearch();
        Ember.run.debounce(data, data.update, 200);
      }
    }
  });
});
