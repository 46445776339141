define("tpf-fund-manager/routes/capfin/admin/funding-sources/show", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model(params) {
      var fundingUuid = params.funding_source_uuid;
      return this.get('store').findRecord('funding-source', fundingUuid);
    },
    actions: {
      toggleEditCashPool: function toggleEditCashPool() {
        var controller = this.controllerFor(this.routeName);
        controller.set('cashPoolChange', null);
        controller.toggleProperty('isEditCashPool');
      },
      adjustCashPool: function adjustCashPool(fundingSource, cashPoolChange) {
        var _this = this;
        var controller = this.controllerFor(this.routeName);
        controller.set('isAdjustingCashPool', true);
        return fundingSource.adjustCashPool({
          adjustmentAmount: cashPoolChange
        }).then(function () {
          controller.toggleProperty('isEditCashPool');
          return _this.refresh();
        }).finally(function () {
          controller.set('isAdjustingCashPool', false);
          controller.set('cashPoolChange', null);
        });
      },
      deleteFundingSource: function deleteFundingSource(fundingSource) {
        var _this2 = this;
        return fundingSource.destroyRecord().then(function () {
          _this2.transitionTo('capfin.admin.funding-sources');
        });
      }
    }
  });
});
