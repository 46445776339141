define("tpf-fund-manager/components/client-invoice-upload-grid", ["exports", "tpf-fund-manager/mixins/selectable-grid-mixin", "tpf-fund-manager/config/environment"], function (_exports, _selectableGridMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Added the SelectableGridMixin so the grid's rows can be selected.
  var _default = _exports.default = Ember.Component.extend(_selectableGridMixin.default, {
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      downloadFile: function downloadFile(fileUuid) {
        var session = this.get('session');
        var token = session.get('data.authenticated.token');
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var request = Ember.$.ajax({
            url: "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/client-invoice-uploads/").concat(fileUuid),
            type: 'GET',
            contentType: 'application/json',
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', "Bearer ".concat(token));
            }
          });
          request.done(function (response) {
            var objectResponse = JSON.parse(response);
            resolve(window.open(objectResponse.url, '_blank'));
          });
          request.fail(function (jqXHR, textStatus, errorThrown) {
            reject(errorThrown);
          });
        });
      }
    }
  });
});
