import DS from 'ember-data';
import Model from '../utils/model-util';
import { memberAction } from 'ember-api-actions';

export default DS.Model.extend({
    // This will adjust the cash pool by a negative or positive decimal amount
    // PATCH /funding-sources/:sourceUuid/adjust-cashpool
    adjustCashPool: memberAction({
        path: 'adjust-cashpool',
        type: 'patch'
    }),

    uuid: Model.attr(),
    name: Model.attr(),
    cashPool: Model.attr('currency'),
    created: Model.attr('date'),
    updated: Model.attr('date')
});
