define("tpf-fund-manager/components/feathers-sort-reset", ["exports", "tpf-fund-manager/templates/components/feathers-sort-reset"], function (_exports, _feathersSortReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _feathersSortReset.default,
    sortService: Ember.inject.service('sort-state'),
    routing: Ember.inject.service('-routing'),
    resetSort: function resetSort() {
      var data = this.get('data');
      this.get('sortService').reset(data.modelName);
    },
    actions: {
      resetSort: function resetSort() {
        var _this = this;
        var callReset = function callReset() {
          _this.resetSort();
          var router = _this.get('routing.router').router;
          router.refresh();
        };
        Ember.run.debounce(this, callReset, 200);
      }
    }
  });
});
