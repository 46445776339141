define("tpf-fund-manager/adapters/staged-debtor-remittance", ["exports", "tpf-fund-manager/adapters/application-v2-patch-api"], function (_exports, _applicationV2PatchApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _applicationV2PatchApi.default.extend({
    snapshotContextResolver: function snapshotContextResolver(snapshot) {
      return {
        uploadUuid: Ember.String.dasherize(snapshot.attr('stagedReceiptUploadUuid').toLowerCase())
      };
    },
    queryContextResolver: function queryContextResolver(query) {
      return {
        uploadUuid: Ember.String.dasherize(query['stagedReceiptUploadUuid'].toLowerCase())
      };
    },
    url: function url(context, modelName, id) {
      var base = "".concat(this.host, "/").concat(this.namespace, "/receipts/uploads/").concat(context.uploadUuid, "/").concat(this.pathForType(modelName));
      if (id) {
        return "".concat(base, "/").concat(id);
      }
      return base;
    },
    // override for collection action.
    // Do not want to do this on generic adapter due to risk of trying to update all invoices
    // By default we do want update to fail if there is no id
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return this.url(this.snapshotContextResolver(snapshot), modelName, id.toLowerCase());
    },
    pathForType: function pathForType() {
      return 'debtor-remittance';
    },
    urlForQuery: function urlForQuery(query, modelName) {
      return this.url(this.queryContextResolver(query), modelName);
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return this.url(this.snapshotContextResolver(snapshot), modelName, id);
    }
  });
});
