define("tpf-fund-manager/routes/capfin/receivables-finance/advances/new", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        Advance: this.get('store').createRecord('advance', {
          type: 'CLIENT_ADVANCE'
        })
      });
    },
    actions: {
      saveAdvance: function saveAdvance(Advance) {
        var _this = this;
        return Advance.save().then(function () {
          _this.transitionTo('capfin.receivables-finance.advances.index.pending');
        });
      },
      cancelAdvance: function cancelAdvance(Advance) {
        Advance.rollbackAttributes();
        this.transitionTo('capfin.receivables-finance.advances.index.pending');
      }
    }
  });
});
