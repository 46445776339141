define("tpf-fund-manager/components/client-form", ["exports", "tpf-fund-manager/fixtures/states", "tpf-fund-manager/fixtures/ucc-first-options"], function (_exports, _states, _uccFirstOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.riskRatings = ['', 1, 2, 3, 4, 5];
      this.productClassifications = ['GOODS', 'SERVICES'];
      this.transferMethods = ['ACH', 'WIRE'];
      this.stateOptions = _states.default.map(function (state) {
        return Object.keys(state)[0];
      });
      this.uccFirstOptions = _uccFirstOptions.default;
    },
    isContractCollateralTypeOther: Ember.computed('clientContract.collateral', {
      get: function get() {
        return this.get('clientContract.collateral') === 'Other';
      }
    }),
    isWireMethodPreferred: Ember.computed('client.transferMethodPreference', {
      get: function get() {
        return this.get('client.transferMethodPreference') === 'WIRE';
      }
    }),
    isUccNoteRequired: Ember.computed('client.uccFirstOption', {
      get: function get() {
        return this.get('client.uccFirstOption') === 'YES_OTHER_SEE_NOTE' || this.get('client.uccFirstOption') === 'NO_OTHER_SEE_NOTE';
      }
    }),
    actions: {
      saveClient: function saveClient(client) {
        if (this.get('isUccNoteRequired') && !this.get('client.uccFirstNote')) {
          return;
        }
        if (client.get('hasDirtyAttributes')) {
          this.get('saveClient')(client);
        }
        this.set('isEditing', false);
      },
      /**
       * Action for canceling an edit of the whole client form.
       * @param {*} client
       */
      cancelClient: function cancelClient(client) {
        this.get('cancelClient')(client);
        this.set('isEditing', false);
      },
      /**
       * action for clicking "cancel" in the "Close Account" dialog for a client.
       * rolls back any dirty state on the client.closedDate field and hides the
       * dialog
       * @param {*} client
       */
      cancelCloseAccountDialog: function cancelCloseAccountDialog(client) {
        client.rollbackAttributes();
        this.set('showCloseAccountDialog', false);
      },
      /**
       * Save a client with a closedDate set in the template dialog modal, then close the modal.
       * @param {*} client
       */
      closeAccount: function closeAccount(client) {
        var _this = this;
        client.save().finally(function () {
          return _this.set('showCloseAccountDialog', false);
        });
      },
      reopenAccount: function reopenAccount(client) {
        client.set('closedDate', null);
        this.send('saveClient', client);
        this.set('showReopenAccountConfirmDialog', false);
      }
    }
  });
});
