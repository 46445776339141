define("tpf-fund-manager/utils/model-util", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    /**
     * attr simplifies defining an attribute on a model by defining the type and
     * options needed to work with tpf-ui filters and tables.  The first argument can be
     * an option defined as: {displayType:'', displayName:'', displayDescription: ''}.
     * DisplayTypes must have a corresponding Ember transform:
     * ```bash
     *      ember generate transform 'name')
     *```
     *
     * @param {string|object} [type]
     * @param {string} [name]
     * @param {string} [description]
     * @returns {*}
     * @example attr('currency', 'My Column', 'A description...')
     */
    attr: function attr(type, name, description) {
      var options = {};

      // definition shortcut with string args to save having to define an object.
      if (!Ember.isBlank(type)) {
        options.displayType = type;
      }
      if (!Ember.isBlank(name)) {
        options.displayName = name;
      }
      if (!Ember.isBlank(description)) {
        options.displayDiscription = description;
      }

      //handle "options" object was passed as the first argument.
      if (Ember.typeOf(arguments[0]) === 'object') {
        options = Ember.merge(options, arguments[0]);
      }
      if (options.DisplayType) {
        return _emberData.default.attr(options.displayType, options);
      } else {
        return _emberData.default.attr(options);
      }
    },
    /**
     * Returns the displayName for the data and column.  The display name is set
     * on the model.
     *
     * @param data - the data store.
     * @param column - the name of the column.
     * @returns {*}
     */
    getModelDisplayName: function getModelDisplayName(data, column) {
      var type = Ember.get(data, 'type');
      var attributes = Ember.get(type, 'attributes');
      var modelAttr = attributes ? attributes.get(column) : undefined;
      var displayName = Ember.String.underscore(column).replace(/_/g, ' ');
      displayName = displayName.replace(/\[\$in\]/g, ' ');
      displayName = displayName.replace(/\[\$notIn\]/g, ' NOT IN ');
      if (modelAttr && modelAttr.options && modelAttr.options.displayName) {
        displayName = modelAttr.options.displayName;
      }
      return displayName;
    }
  };
});
