define("tpf-fund-manager/helpers/is-active", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isActive = isActive;
  function isActive(_ref /*, hash*/) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      _ref2$ = _ref2[0],
      routeName = _ref2$ === void 0 ? '' : _ref2$,
      _ref2$2 = _ref2[1],
      activeRoute = _ref2$2 === void 0 ? 'noop-route' : _ref2$2;
    return activeRoute.includes(routeName);
  }
  var _default = _exports.default = Ember.Helper.helper(isActive);
});
