import DS from 'ember-data';
import Model from '../utils/model-util';
import { memberAction } from 'ember-api-actions';

export default DS.Model.extend({
    // PATCH /client-debtors/:client_debtor_uuid/add-code
    addCode: memberAction({
        path: 'add-code',
        type: 'patch'
    }),

    // PATCH /client-debtors/:client_debtor_uuid/remove-code
    removeCode: memberAction({
        path: 'remove-code',
        type: 'patch'
    }),

    selectDefaultCode: memberAction({
        path: 'select-default-code',
        type: 'patch'
    }),

    clientUuid: Model.attr(),
    clientName: Model.attr(),
    debtorName: Model.attr(),
    debtorUuid: Model.attr(),
    uuid: Model.attr(),
    defaultAdvanceRate: Model.attr(),
    defaultConcentration: Model.attr(),
    creditLimit: Model.attr(),
    invoiceVerificationRating: Model.attr(),
    invoiceVerificationNotes: Model.attr(),
    isActive: Model.attr(),
    isEnabled: Model.attr(),
    isControlledRemittance: Model.attr(),
    termCodes: DS.hasMany('term-code'),
    defaultTermCodeDescription: Model.attr(),
    hasDefaultTermCode: Model.attr(),
    bufferDays: Model.attr(),
    chargebackDays: Model.attr(),
    created: Model.attr(),
    updated: Model.attr(),
    isNoaSent: Model.attr(),

    /**
     * Used only when creating a new client-debtor and including a bunch of
     * pre-existing term codes with it.
     */
    termCodeIds: DS.attr(),
    selectedDefaultCodeId: DS.attr()
});
