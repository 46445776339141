define("tpf-fund-manager/mixins/uuid-pk-serializer-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // logic to preserve serializeIntoHash from JSONSerializer as referred below
  // https://github.com/emberjs/data/blob/v2.11.0/addon/serializers/json.js#L17
  var assign = Ember.assign || Ember.merge;
  var _default = _exports.default = Ember.Mixin.create({
    primaryKey: 'uuid',
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      // we want the default behavior of JSONSerializer instead of RESTSerializer:
      // https://github.com/emberjs/data/blob/v2.11.0/addon/serializers/json.js#L1085
      // (don't key data off model)
      assign(hash, this.serialize(snapshot, options));
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'queryRecord') {
        // https://github.com/emberjs/data/blob/master/addon/serializers/json.js#L562
        // ^ at this point resourceHash is still a full query response instead of a single
        // record when using the helper method 'queryRecord'.
        // To fix, hoist the custom pk up to the resourceHash level, which is discarded in
        // normalize anyways.
        payload.uuid = payload.data[0].uuid;
      }
      return this._super(store, primaryModelClass, payload, payload.uuid || id, requestType, true);
    }
  });
});
