define("tpf-fund-manager/controllers/capfin/testing/demo-gen", ["exports", "tpf-fund-manager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    isProductionEnvironment: Ember.computed(function () {
      return _environment.default.environment === 'production';
    }),
    actions: {
      createDemoClient: function createDemoClient() {
        var session = this.get('session');
        var token = session.get('data.authenticated.token');
        var userEmailAddress = this.get('userEmailAddress');
        var productType = this.get('selectedProductType');
        var controller = this;
        var toast = this.get('toast');
        controller.set('isCreatingDemoClient', true);
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var request = Ember.$.ajax({
            url: "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/capfin-testing/create-demo-client"),
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(Object.assign({
              userEmailAddress: userEmailAddress,
              productType: productType
            })),
            headers: {
              Authorization: "Bearer ".concat(token)
            }
          });
          request.done(function () {
            controller.set('isCreatingDemoClient', false);
          });
          request.fail(function (jqXHR, textStatus, errorThrown) {
            controller.set('isCreatingDemoClient', false);
            toast.error(jqXHR.responseText);
            reject(errorThrown);
          });
        });
      }
    }
  });
});
