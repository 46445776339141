define("tpf-fund-manager/helpers/one-way-select/contains", ["exports", "ember-one-way-controls/helpers/one-way-select/contains"], function (_exports, _contains) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "contains", {
    enumerable: true,
    get: function get() {
      return _contains.contains;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _contains.default;
    }
  });
});
