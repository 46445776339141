import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    uuid: Model.attr(),
    clientUuid: Model.attr(),
    filePath: Model.attr(),
    fileName: Model.attr(),
    filePathType: Model.attr(),
    deleted: Model.attr(),
    created: Model.attr(),
    updated: Model.attr(),
    type: Model.attr(),
    period: Model.attr(),
    isExpired: Model.attr(),
    isReviewed: Model.attr(),
    isClientUploaded: Model.attr(),
    approvalStatus: Model.attr(),
    reviewComments: Model.attr()
});
