define("tpf-fund-manager/transforms/naive-date", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return (0, _moment.default)(value).format('YYYY-MM-DD');
    },
    deserialize: function deserialize(value) {
      return new Date(value);
    }
  });
});
