define("tpf-fund-manager/components/feathers-pagination", ["exports", "tpf-fund-manager/templates/components/feathers-pagination"], function (_exports, _feathersPagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _feathersPagination.default,
    pageService: Ember.inject.service('page-state'),
    classNames: ['center-align'],
    pageRange: 7,
    minPage: 1,
    maxPage: Ember.computed.alias('data.meta.pagesCount'),
    includeSize: true,
    defaultPageSize: 25,
    resetPageOnSizeChange: true,
    shouldHidePaginationComponent: Ember.computed('minPage', 'maxPage', {
      get: function get() {
        if (this.get('minPage') === this.get('maxPage')) {
          return 'hidden';
        } else {
          return 'visible';
        }
      }
    }),
    currentPage: Ember.computed('data.@each.isUpdating', {
      get: function get() {
        return this.getWithDefault('data.meta.page', 1);
      },
      set: function set(key, newVal) {
        var _this = this;
        this.set('isLoading', true);
        this.set('data.meta.page', newVal);
        var data = this.get('data');
        data.query['page'] = newVal;
        data.query['pageSize'] = this.getWithDefault('data.meta.pageSize', this.get('defaultPageSize'));
        data.update().finally(function () {
          return _this.set('isLoading', false);
        });
      }
    }),
    currentPageSize: Ember.computed('data.@each.isUpdating', 'data.meta.pageSize', {
      get: function get() {
        var defaultPageSize = this.get('defaultPageSize');
        return this.getWithDefault('data.meta.pageSize', defaultPageSize);
      },
      set: function set(key, newVal) {
        var _this2 = this;
        this.set('isLoading', true);
        this.set('data.meta.pageSize', newVal);
        var data = this.get('data');
        this.get('pageService').setParam(data.modelName, newVal);
        if (this.get('resetPageOnSizeChange', true)) {
          data.query['page'] = 1;
        }
        data.query['pageSize'] = newVal;
        data.update().finally(function () {
          return _this2.set('isLoading', false);
        });
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.pageSizes = this.getWithDefault('pageSizes', [25, 50, 100]);
    }
  });
});
