define("tpf-fund-manager/components/loan-transactions-grid", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-local-storage"], function (_exports, _toConsumableArray2, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    ui: (0, _emberLocalStorage.storageFor)('ui-state'),
    transactionEvents: Ember.computed({
      get: function get() {
        var activity = [];
        var transactions = this.get('transactions');
        if (transactions) {
          transactions.forEach(function (txn) {
            if (!txn.get('transactionEvents')) return;
            var txnActivity = txn.get('transactionEvents').map(function (act) {
              return Object.assign({
                transactionId: txn.id
              }, act);
            });
            activity.push.apply(activity, (0, _toConsumableArray2.default)(txnActivity));
          });
          return Ember.A(activity.sort(this.activitySorter));
        }
        return Ember.A([]);
      }
    }),
    activitySorter: function activitySorter(a, b) {
      var t1 = +new Date(a.eventDateTime);
      var t2 = +new Date(b.eventDateTime);
      var sortDirection = (t1 - t2) * -1;
      if (sortDirection === 0) {
        if (b.eventType === 'Settled' && a.eventType !== 'Settled') {
          sortDirection = 1;
        }
      }
      return sortDirection;
    },
    actions: {
      toggleGrouped: function toggleGrouped() {
        this.toggleProperty('ui.groupedTransactions');
      },
      toggleActivity: function toggleActivity() {
        this.toggleProperty('ui.expandedTransactions');
      }
    }
  });
});
