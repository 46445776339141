import DS from 'ember-data';

export default DS.Model.extend({
    uuid: DS.attr(),
    clientName: DS.attr(),
    feeAmount: DS.attr(),
    invoiceFeeAmount: DS.attr(),

    invoicePurchaseAmount: DS.attr(),
    invoiceRebateAmount: DS.attr(),
    invoiceUnderpaymentAmount: DS.attr(),
    invoiceChargeBackAmount: DS.attr(),
    passthroughAmount: DS.attr()
});
