define("tpf-fund-manager/services/invoice-prioritization-factoring", ["exports", "tpf-fund-manager/services/invoice-prioritization"], function (_exports, _invoicePrioritization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * In theory prioritizing factoring invoices works the same way as
   * RF invoices, but we have to keep their state separate for UI reasons
   */
  var _default = _exports.default = _invoicePrioritization.default.extend({
    productType: 'FACTORING'
  });
});
