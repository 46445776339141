import DS from 'ember-data';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
    uuid: DS.attr(),
    onboardingFormUuid: DS.attr(),
    lenderName: DS.attr(),
    debtNature: DS.attr(),
    debtNatureOther: DS.attr(),
    balanceOwed: DS.attr(),
    presentPostClose: DS.attr()
});
