define("tpf-fund-manager/components/risk-score-graph", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    ui: (0, _emberLocalStorage.storageFor)('ui-state'),
    // uses ui.showRiskScoreChart
    init: function init() {
      this._super.apply(this, arguments);
      this.chartOptions = {
        chart: {
          polar: true,
          type: 'line',
          spacing: [0, 0, 0, 0]
        },
        title: false,
        pane: {
          size: '80%'
        },
        legend: {
          enabled: false,
          maxHeight: 0,
          lineHeight: 0,
          margin: 0,
          padding: 0,
          symbolPadding: 0
        },
        xAxis: {
          categories: ['V1', 'V2', 'V3', 'V4', 'V5'],
          tickmarkPlacement: 'on',
          lineWidth: 0,
          labels: {
            style: {
              fontSize: '1rem',
              fontWeight: '700'
            }
          }
        },
        yAxis: {
          gridLineInterpolation: 'polygon',
          lineWidth: 0,
          min: 0
        },
        tooltip: {
          shared: true,
          pointFormat: '<b>{point.y:,.8f}</b>'
        }
      };
    },
    // tpfRisk data formatted for high-charts
    content: Ember.computed('tpfRisk.isLoading', {
      get: function get() {
        var x = {
          name: 'Risk Score Variable',
          data: [],
          pointPlacement: 'on',
          type: 'area',
          animation: {
            duration: 500
          }
        };
        var tpfRisk = this.get('tpfRisk');
        var riskValCount = 5;
        for (var i = 0; i < riskValCount; i++) {
          x.data.push(Number(tpfRisk.get("v".concat(i + 1)) || 0));
        }
        return [x];
      }
    }),
    toggleIcon: Ember.computed('ui.showRiskScoreChart', {
      get: function get() {
        return this.get('ui.showRiskScoreChart') ? 'list' : 'equalizer';
      }
    }),
    actions: {
      toggleUI: function toggleUI() {
        this.toggleProperty('ui.showRiskScoreChart');
      }
    }
  });
});
