define("tpf-fund-manager/components/term-code-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isEom: Ember.computed('TermCode.eomCode', function () {
      return this.get('TermCode.eomCode') === 'E' ? true : false;
    }),
    actions: {
      onEomCodeChange: function onEomCodeChange() {
        if (this.get('TermCode.eomCode') === 'E') {
          this.set('TermCode.eomCode', null);
        } else {
          this.set('TermCode.eomCode', 'E');
        }
      },
      saveTermCode: function saveTermCode(termCode) {
        return this.get('saveTermCode')(termCode);
      },
      cancelTermCode: function cancelTermCode(termCode) {
        return this.get('cancelTermCode')(termCode);
      },
      onTermCodeChange: function onTermCodeChange() {
        var TermCode = this.get('TermCode');
        var percent = TermCode.get('percent') && TermCode.get('percent') > 0 ? +TermCode.get('percent') + '% ' : '';
        var discountDays = TermCode.get('discountDays') && TermCode.get('discountDays') > 0 ? TermCode.get('discountDays') + ' ' : '';
        var netDays = TermCode.get('netDays') && TermCode.get('netDays') > 0 ? 'NET ' + TermCode.get('netDays') + ' ' : '';
        var extraDays = TermCode.get('extraDays') && TermCode.get('extraDays') > 0 ? '+ ' + TermCode.get('extraDays') + ' ' : '';
        var eomCode = this.get('isEom') ? 'EOM ' : '';
        TermCode.set('description', percent + discountDays + netDays + eomCode + extraDays);
      }
    }
  });
});
