import DS from 'ember-data';

export default DS.Model.extend({
    uuid: DS.attr(),
    invoiceUuid: DS.attr(),
    postedDate: DS.attr(),
    feeAmount: DS.attr(),
    feeType: DS.attr(),
    description: DS.attr(),
    isActive: DS.attr(),
    created: DS.attr(),
    updated: DS.attr(),

    invoiceId: DS.attr(),
    clientName: DS.attr()
});
