export default [
    'TWO_WAY_MATCH_SERVICES',
    'TWO_WAY_MATCH_MATERIALS',
    'THREE_WAY_MATCH_TRADITIONAL',
    'THREE_WAY_MATCH_SERVICES',
    'EDI_PORTAL',
    'VENDOR_PORTAL',
    'DEBTOR_EMAIL_APPROVAL',
    'BILLING_CORRESPONDENCE',
    'THREE_PL_PORTAL',
    'EARLY_PAY_DATA',
    'VENDOR_PORTAL_SCREENSHOT',
    'OTHER'
];
