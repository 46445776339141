define("tpf-fund-manager/components/advance-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.advanceTypes = ['CLIENT_ADVANCE', 'PAYOFF', 'CARD_ADVANCE', 'REIMBURSABLE'];
      this.paymentTypes = ['ACH', 'WIRE'];
      this.clientSearchFilters = {
        productType: 'RECEIVABLES_FINANCE'
      };
    },
    transactionTypeLabel: Ember.computed('clientForAdvance', {
      get: function get() {
        var client = this.get('clientForAdvance');
        if (client) {
          return "Transaction Type (preference: ".concat(client.get('transferMethodPreference'), ")");
        } else {
          return 'Transaction Type';
        }
      }
    }),
    actions: {
      saveAdvance: function saveAdvance(Advance, overrideConfirmation) {
        var _this = this;
        var isClientClosed = this.get('clientForAdvance').get('isClosed');
        var isOverAdvance = +Advance.get('advanceAmount') > +this.get('clientForAdvance.availabilityBalance');
        if (overrideConfirmation || !(isOverAdvance || isClientClosed)) {
          this.set('isOverAdvance', null);
          this.set('showConfirmSaveAdvanceDialog', false);
          this.set('isSavingAdvance', true);
          return this.get('saveAdvance')(Advance).finally(function () {
            return _this.set('isSavingAdvance', false);
          });
        } else if (isOverAdvance) {
          this.set('showConfirmSaveAdvanceDialog', true);
          this.set('isOverAdvance', true);
        } else {
          this.set('showConfirmSaveAdvanceDialog', true);
        }
      },
      cancelAdvance: function cancelAdvance(Advance) {
        this.set('isOverAdvance', null);
        return this.get('cancelAdvance')(Advance);
      },
      onCloseAdvanceDialog: function onCloseAdvanceDialog() {
        this.set('showConfirmSaveAdvanceDialog', false);
        this.set('isOverAdvance', null);
      },
      onClientSelect: function onClientSelect(client) {
        this.set('isOverAdvance', null);
        this.set('clientForAdvance', client);
        this.set('Advance.clientUuid', client.get('uuid'));
        this.set('Advance.transferMethod', client.get('transferMethodPreference'));
      }
    }
  });
});
