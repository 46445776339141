define("tpf-fund-manager/helpers/format-null-bool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatNullBool = formatNullBool;
  function formatNullBool(params, options) {
    var textMap = Object.keys(options || {}).length !== 0 ? options : {
      isTrue: 'Yes',
      isFalse: 'No',
      isNull: '-'
    };
    var value = params[0];
    if (value) {
      return textMap.isTrue;
    } else if (value === false) {
      return textMap.isFalse;
    } else {
      return textMap.isNull;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(formatNullBool);
});
