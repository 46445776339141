define("tpf-fund-manager/services/one-time-connect-token", ["exports", "tpf-fund-manager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    getToken: function getToken(divisionUuuid) {
      var sessionToken = this.get('session').get('data.authenticated.token');
      var token = '';
      Ember.$.ajax({
        url: "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/connect-tokens?divisionUuid=").concat(divisionUuuid),
        type: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', "Bearer ".concat(sessionToken));
        },
        success: function success(body) {
          token = body.key;
        },
        async: false
      });
      return token;
    }
  });
});
