import DS from 'ember-data';

export default DS.Model.extend({
    userEmailAddress: DS.attr('string'),
    userAuthServiceUuid: DS.attr('string'),
    userUuid: DS.attr('string'),
    clientName: DS.attr('string'),
    divisionUuid: DS.attr('string'),
    clientUuid: DS.attr('string')
});
