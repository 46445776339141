define("tpf-fund-manager/components/md-state-tabs", ["exports", "tpf-fund-manager/templates/components/md-state-tabs"], function (_exports, _mdStateTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _mdStateTabs.default,
    colWidth: 2,
    redirectDelay: 150,
    routing: Ember.inject.service('-routing'),
    currentPath: Ember.computed('routing.router.currentPath', {
      get: function get() {
        return this.get('routing.router.currentPath');
      },
      set: function set(key, newVal) {
        var router = this.get('routing.router').router;
        setTimeout(function () {
          return router.transitionTo(newVal);
        }, this.redirectDelay);
        return newVal;
      }
    })
  });
});
