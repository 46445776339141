import Ember from 'ember';
import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    uuid: Model.attr(),
    divisionUuid: Model.attr(),
    clientName: Model.attr(),
    creditLimit: Model.attr(),
    creditAvailable: Model.attr(),
    creditUtilizationPercent: Model.attr(),
    unsettledBalance: Model.attr(),
    factoringTotalUnsettledAmount: Model.attr(),
    factoringPurchasedAmount: Model.attr(),
    factoringUnsettledInvoiceFees: Model.attr(),
    factoringUnsettledInvoices: Model.attr(),
    factoringTotalObligation: Model.attr(),

    hasCreditAvailable: Ember.computed('creditAvailable', function() {
        return this.get('creditAvailable') > 0;
    }),

    roundedCreditUtilizationPercent: Ember.computed('creditUtilizationPercent', function() {
        return this.get('creditUtilizationPercent').toFixed(2);
    }),

    roundedCreditAvailable: Ember.computed('creditAvailable', 'creditLimit', function() {
        let creditAvailableChange = this.get('creditLimit')
            ? (this.get('creditAvailable') / this.get('creditLimit')) * 100
            : 0;
        return creditAvailableChange.toFixed(2);
    })
});
