define("tpf-fund-manager/components/material-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Material Modal is a component that allows making a modal a little easier.
   * If no `affirmAction` is given then the modal will close when the affirmation button
   * is selected.
   *
   * It takes the following params:
   *
   * @required buttonText || iconName - one of these must be provided to get a trigger
   *                                    iconName takes precedence over the buttonText.
   *
   * @param iconName      (optional) - Makes the modal trigger be an icon with that name
   * @param iconSize      (optional) - Set the size of the icon that triggers the modal
   * @param buttonText    (optional) - The text to appear on the modal open button
   * @param affirmText    (optional) - the text to show on the affimration button
   * @param cancelText    (optional) - text to show for cancel action of the modal
   * @param isDisabled    (optional) - Boolean value for whether the button should be disabled.
   * @param buttonClasses (optional) - Additional CSS styles to use on the modal-triggering button
   * @param affirmAction  (optional) - Action to use for handling closing the modal on affirmation
   *                                  click. This function _must_ return a promise that resovles to
   *                                  `true` to close the modal.
   *                                  Any falsey value will leave the modal open.
   */
  var _default = _exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('uuid', Ember.guidFor(this));
    },
    cancelText: 'Cancel',
    affirmText: 'Close',
    buttonClasses: '',
    isDisabled: null,
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.$('.modal').modal) {
        this.$('.modal').modal();
      }
    },
    closeModal: function closeModal() {
      this.$("#".concat(this.get('uuid'))).modal('close');
    },
    actions: {
      onClose: function onClose() {
        var _this = this;
        var affirmAction = this.get('affirmAction');
        if (affirmAction) {
          // Only close the modal if the action that was taken says
          // it was successful.
          affirmAction().then(function (shouldClose) {
            if (shouldClose) {
              _this.closeModal();
            }
          });
        } else {
          this.closeModal();
        }
      }
    }
  });
});
