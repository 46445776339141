define("tpf-fund-manager/services/codified-list", ["exports", "tpf-fund-manager/fixtures/formation-structures"], function (_exports, _formationStructures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * codifiedList service
   * ```js
   * import Ember from 'ember';
   * // in component/route:
   * codifiedListService: Ember.inject.service('codified-list'),
   * ```
   *
   * Abstraction layer to return various defined lists to be used in display to the
   * user, or as select dropdown items.  As each becomes more dynamic we can either
   * query the DB or just pull from a static array as necessary.
   *
   * Examples include:
   *  1. List of Industries / Formation structures.
   *
   * Methods return the translated (if applicable) versions of these lists.
   */
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    getFormationStructures: function getFormationStructures() {
      return _formationStructures.default;
    },
    getIndustryGroups: function getIndustryGroups() {
      return this.get('store').query('onboarding-industry-group', {
        sort: {
          group: 'DESC',
          sortOrder: 'DESC'
        },
        size: 100
      }).then(function (industries) {
        // this logic _requires_ the industries have been ORDER BY'd group first, then sort order.

        // sentinel array to keep track of which categories we've prepended a label to
        // in our returned groups.
        var industryGroupCategories = [];
        // array of industries to return, including group labels.
        var industryGroups = [];
        industries.forEach(function (industry) {
          if (!industryGroupCategories.includes(industry.get('group'))) {
            // if we come across a new group, insert a 'header' item in the list,
            // to assist with select dropdown display.
            industryGroupCategories.push(industry.get('group'));
            industryGroups.push({
              description: industry.get('groupDisplayName')
            });
          }
          // append only model fields, sans ember-data metadata to keep
          // array items similar.
          industryGroups.push({
            uuid: industry.get('uuid'),
            description: industry.get('description'),
            majorGroupId: industry.get('majorGroupId'),
            group: industry.get('group'),
            groupDisplayName: industry.get('groupDisplayName'),
            locale: industry.get('locale')
          });
        });
        return industryGroups;
      });
    }
  });
});
