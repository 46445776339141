import DS from 'ember-data';

export default DS.Model.extend({
    row: DS.attr(),
    uuid: DS.attr(),
    referenceNumber: DS.attr(),
    invoiceId: DS.attr(),
    orderId: DS.attr(),
    expectedAmount: DS.attr('number'),
    amountPaid: DS.attr('number'),
    difference: DS.attr('number'),
    percentPaid: DS.attr('number'),
    advanceRate: DS.attr('number'),
    forcePartiallyPaid: DS.attr(),
    dilutionRate: DS.attr('number'),
    classification: DS.attr(),
    status: DS.attr(),
    isRemovedOrChargedBack: DS.attr(),
    stagedReceiptUploadUuid: DS.attr(),
    stagedDebtorRemittanceUuid: DS.attr(),
    allocationDate: DS.attr('date')
});
