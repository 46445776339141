define("tpf-fund-manager/models/receipt-invoice-allocation", ["exports", "tpf-fund-manager/utils/model-util", "ember-data-model-fragments/fragment"], function (_exports, _modelUtil, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _fragment.default.extend({
    uuid: _modelUtil.default.attr(),
    invoiceUuid: _modelUtil.default.attr(),
    repaymentUuid: _modelUtil.default.attr(),
    invoiceId: _modelUtil.default.attr(),
    grossAmount: _modelUtil.default.attr('number'),
    deductionAmount: _modelUtil.default.attr('number'),
    discountAmount: _modelUtil.default.attr('number'),
    referenceNumber: _modelUtil.default.attr('string'),
    allocationType: _modelUtil.default.attr(),
    netAmount: Ember.computed('grossAmount', 'deductionAmount', 'discountAmount', function () {
      var _this = this;
      var netAmount = ['grossAmount', 'deductionAmount', 'discountAmount'].map(function (it) {
        return +(_this.get(it) || 0);
      }).reduce(function (x, y) {
        return x + y;
      }, 0);
      return netAmount;
    }),
    status: _modelUtil.default.attr(),
    description: _modelUtil.default.attr(),
    allocationDate: _modelUtil.default.attr()
  });
});
