define("tpf-fund-manager/routes/capfin/factoring/settlements/index", ["exports", "tpf-fund-manager/utils/is-index-transition", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _isIndexTransition, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    redirect: function redirect(model, transition) {
      if ((0, _isIndexTransition.default)(transition, 'capfin.factoring.settlements')) {
        this.transitionTo('capfin.factoring.settlements.index.unsettled');
      }
    },
    actions: {
      handleStatusChange: function handleStatusChange(items, changes) {
        var _this = this;
        return Ember.RSVP.all(items.map(function (settlement) {
          settlement.setProperties(changes);
          return settlement.save();
        })).finally(function () {
          return _this.refresh();
        });
      }
    }
  });
});
