define("tpf-fund-manager/components/chip-download", ["exports", "tpf-fund-manager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service(),
    baseUrl: "".concat(_environment.default.APP.tpfApi),
    baseUrlV2: "".concat(_environment.default.APP.tpfSupplierApiV2),
    label: 'CSV Download',
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.assert('A valid absolute path(without hostname) to the api-server is required.', !!this.get('path'));
    },
    // Override this.onDownload from the parent template to handle custom actions,
    // If override, returning a rejected promise prevents the component from
    // initiating the download.
    onDownload: function onDownload() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.get('_onDownload')(options);
    },
    /**
     * the default onDownload behavior.
     * @param options
     * @returns {*}
     * @private
     */
    _onDownload: function _onDownload() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var fullUrl = "".concat(options.baseUrl).concat(options.path);
      var queryStrings = "token=".concat(options.token).concat(options.queryStrings);
      window.open("".concat(fullUrl, "?").concat(queryStrings), '_blank');
      return Ember.RSVP.resolve(options);
    },
    actions: {
      downloadFromUrl: function downloadFromUrl(loans) {
        var query = Ember.copy(loans.query, true);
        var queryStrings = '';
        if (query) {
          if (query.sort) {
            query.$sort = query.sort;
            delete query.sort;
          }
          if (query.pageSize) {
            delete query.pageSize;
          }
          if (query.page) {
            delete query.page;
          }
          queryStrings = "&".concat(Ember.$.param(query));
          queryStrings = queryStrings.replace(/DESC/g, '-1');
          queryStrings = queryStrings.replace(/ASC/g, '1');
        }
        return this.onDownload({
          baseUrl: this.get('isV2Api') ? this.baseUrlV2 : this.baseUrl || '',
          path: this.get('path'),
          token: this.get('session.data.authenticated.token'),
          query: query,
          queryStrings: queryStrings
        });
      }
    }
  });
});
