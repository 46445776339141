define("tpf-fund-manager/components/unsettled-clients", ["exports", "tpf-fund-manager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    baseUploadUrl: "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/unsettled-clients-reports/regenerate-unsettled-report"),
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.isGeneratingClientReport = false;
    },
    actions: {
      regenUnsettledClientReport: function regenUnsettledClientReport() {
        this.set('isGeneratingClientReport', true);
        fetch(this.get('baseUploadUrl'), {
          method: 'POST',
          headers: {
            Authorization: "Bearer ".concat(this.get('session.data.authenticated.token')),
            'Content-Type': 'application/json'
          }
        });
      }
    }
  });
});
