define("tpf-fund-manager/adapters/application-v2-api", ["exports", "tpf-fund-manager/adapters/application-kt-api", "tpf-fund-manager/config/environment"], function (_exports, _applicationKtApi, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Update sort query params to be of the format sort[]=column,direction
   *
   * @param sort
   * @returns {*}
   */
  function convertSortObjectToArray(sort) {
    if (sort === undefined || Array.isArray(sort)) {
      return sort;
    }
    return Object.keys(sort).reduce(function (memo, key) {
      memo.push(key + ',' + sort[key]);
      return memo;
    }, []);
  }
  var _default = _exports.default = _applicationKtApi.default.extend({
    namespace: 'v2/tpf-api/admin',
    host: _environment.default.APP.tpfSupplierApiV2,
    /**
     * Override query param behavior to format as expected by API
     *
     * https://github.com/emberjs/data/blob/v3.3.1/addon/adapters/rest.js#L534
     *
     */
    sortQueryParams: function sortQueryParams() {
      var newParams = this._super.apply(this, arguments);
      if (newParams.sort) {
        newParams.sort = convertSortObjectToArray(newParams.sort);
      }
      if (newParams.hasOwnProperty('$search')) {
        Object.keys(newParams['$search']).map(function (key) {
          newParams[key] = {
            LIKE: newParams['$search'][key]
          };
        });
        delete newParams['$search'];
      }
      return newParams;
    },
    // Sanitize/convert unsupported params to supported query actions.
    query: function query(store, type, _query) {
      var newQuery = Ember.copy(_query, true);

      // The V2 API is zero based for the page index.
      if (!Ember.isEmpty(_query.page)) {
        newQuery.page = _query.page - 1;
      }
      return this._super(store, type, newQuery);
    }
  });
});
