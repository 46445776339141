define("tpf-fund-manager/routes/markets/show/buyer-toggle/show/bank-account", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    model: function model() {
      var buyerToggleClient = this.modelFor('markets.show.buyer-toggle.show').buyerToggleClient;
      var divisionUuid = buyerToggleClient.get('divisionUuid');
      return Ember.RSVP.hash({
        bankAccounts: this.get('store').query('bank-account', {
          divisionUuid: divisionUuid,
          isActive: true
        })
      }).then(function (model) {
        var tpfBankAccount = model.bankAccounts.get('firstObject');
        var bankAccountExists = !Ember.isNone(tpfBankAccount);
        return {
          bankAccountExists: bankAccountExists,
          tpfBankAccount: tpfBankAccount,
          divisionUuid: divisionUuid
        };
      });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    }
  });
});
