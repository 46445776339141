define("tpf-fund-manager/routes/capfin/testing/refresh-jobs", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    actions: {
      refreshUnsettledAmounts: function refreshUnsettledAmounts() {
        var _this = this;
        var controller = this.controllerFor(this.routeName);
        controller.set('refreshing', true);
        var tempRecord = this.get('store').createRecord('unsettled-invoice-aggregate');
        tempRecord.refreshAmounts().then(function () {
          controller.set('refreshing', false);
          _this.refresh();
        });
      }
    }
  });
});
