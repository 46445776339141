define("tpf-fund-manager/mixins/loan-repayment-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LoanRepaymentRouteMixin = Ember.Mixin.create({
    actions: {
      createRepayment: function createRepayment() {
        // temporary no-op
        return true;
      },
      cancelRepayment: function cancelRepayment() {
        // temporary no-op
        return true;
      },
      allocateRepayment: function allocateRepayment() {
        // temporary no-op
        return true;
      },
      scheduleAch: function scheduleAch() {
        // temporary no-op
        return true;
      }
    }
  });
  var _default = _exports.default = LoanRepaymentRouteMixin;
});
