define("tpf-fund-manager/helpers/checkmark", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkmark = checkmark;
  _exports.default = void 0;
  /**
   * Returns the HTML for a checkmark or an ex-out based on the passed `value`.
   *
   * @param value {Boolean} - the boolean value to make into an icon
   * @param size {String} - the size of the icon, defaults to 'small'
   * @param namedArgs {Object} - impacts how the checkmark is created
   *          invert - If true, will invert the value given for the `value` parameter, defaults to false.
   *          falseOnly - If true, will only show false values (highlight_off).  This can be useful for
   *              warning / invalid states that have no explicit "positive".
   *
   * @returns {String} - the HTML for the icon
   */
  function checkmark(_ref, namedArgs) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      value = _ref2[0],
      size = _ref2[1];
    size = size || 'small';
    namedArgs = namedArgs || {};
    var invert = namedArgs.invert || false;
    var falseOnly = namedArgs.falseOnly || false;
    var icon;
    var color;

    // Invert the boolean result of value if the caller
    // has decided to change the default.
    if (invert) {
      value = !value;
    }
    if (value === true) {
      icon = 'check_circle';
      color = 'green-text';
    } else {
      icon = 'highlight_off';
      color = 'red-text darken-4';
    }
    var visibility = falseOnly && value === true ? ' hide' : '';
    return Ember.String.htmlSafe("<i class=\"".concat(color, " ").concat(size).concat(visibility, " material-icons\">").concat(icon, "</i>"));
  }
  var _default = _exports.default = Ember.Helper.helper(checkmark);
});
