define("tpf-fund-manager/helpers/app-version", ["exports", "tpf-fund-manager/config/environment", "ember-cli-app-version/utils/regexp"], function (_exports, _environment, _regexp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appVersion = appVersion;
  _exports.default = void 0;
  var version = _environment.default.APP.version;
  function appVersion(_) {
    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (hash.hideSha) {
      return version.match(_regexp.versionRegExp)[0];
    }
    if (hash.hideVersion) {
      return version.match(_regexp.shaRegExp)[0];
    }
    return version;
  }
  var _default = _exports.default = Ember.Helper.helper(appVersion);
});
