import DS from 'ember-data';

export default DS.Model.extend({
    advanceRate: DS.attr(),
    asOfDate: DS.attr(),
    clientDebtorUuid: DS.attr(),
    clientContractUuid: DS.attr(),
    concentration: DS.attr(),
    creditLimit: DS.attr(),
    effectiveDate: DS.attr(),
    maturityInterval: DS.attr(),
    maturityLength: DS.attr(),
    minimumTenor: DS.attr(),
    note: DS.attr(),
    productType: DS.attr(),
    rate: DS.attr(),
    rateType: DS.attr(),
    type: DS.attr(),
    uuid: DS.attr(),
    verifiedArThreshold: DS.attr(),
    clientContractVerifiedArThreshold: DS.attr(),
    commissionPercent: DS.attr(),
    arConcentration: DS.attr()
});
