define("tpf-fund-manager/helpers/format-loan-status", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatLoanStatus = formatLoanStatus;
  /**
   *Determines the style for a loan cell based on the status of the loan.
   *
   * @param status - the status of the loan.
   * @param text - the display value for the cell.
   * @returns {*}
   */
  function formatLoanStatus(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      status = _ref2[0],
      text = _ref2[1];
    var statusMap = {
      NEW: 'black-text',
      OPEN: 'green-text',
      HOLD: 'orange-text',
      INELIGIBLE: 'orange-text',
      REJECTED: 'red-text',
      CLOSED: 'black-text'
    };
    var labelType = statusMap[status] || 'black-text';
    return Ember.String.htmlSafe("<span class=\"".concat(labelType, "\">").concat(text, "</span>"));
  }
  var _default = _exports.default = Ember.Helper.helper(formatLoanStatus);
});
