import DS from 'ember-data';
import { memberAction } from 'ember-api-actions';

export default DS.Model.extend({
    uuid: DS.attr(),
    fileName: DS.attr(),
    fileCreationDate: DS.attr(),
    uploadTimestamp: DS.attr(),
    numSuccessfulAccounts: DS.attr(),
    numSuccessfulTransactions: DS.attr(),
    linkToS3: DS.attr(),
    downloadPath: DS.attr(),
    uploadStatus: DS.attr(),
    parseErrors: DS.attr(),
    commit: memberAction({
        path: 'commit',
        type: 'post',
        urlType: 'findRecord'
    })
});
