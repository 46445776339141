define("tpf-fund-manager/services/form-questions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OFFICERS_INFO_SECTION_KEY = 'OFFICER_INFO_QUESTIONS';
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    getOfficersInfoAdditionalQuestions: function getOfficersInfoAdditionalQuestions() {
      var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'en_US';
      return this.get('store').query('onboarding-additional-question', {
        section: OFFICERS_INFO_SECTION_KEY,
        locale: locale,
        isCurrent: true,
        sort: {
          sortOrder: 'DESC'
        }
      });
    }
  });
});
