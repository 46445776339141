define("tpf-fund-manager/routes/capfin/clients/show/bank", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      var client = this.modelFor('capfin.clients.show').client;
      var divisionUuid = client.get('divisionUuid');
      return Ember.RSVP.hashSettled(this.getPreviousFilters({
        bankAccounts: {
          _modelName: 'bank-account',
          divisionUuid: divisionUuid,
          isActive: true,
          pageSize: 1
        }
      })).then(function (model) {
        var bankAccount = model.bankAccounts.state != 'rejected' ? model.bankAccounts.value.get('firstObject') : null;
        var bankAccountExists = !Ember.isNone(bankAccount);
        return {
          bankAccountExists: bankAccountExists,
          bankAccount: bankAccount,
          divisionUuid: divisionUuid,
          client: client
        };
      });
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});
