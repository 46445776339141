define("tpf-fund-manager/initializers/app-version", ["exports", "ember-cli-app-version/initializer-factory", "tpf-fund-manager/config/environment"], function (_exports, _initializerFactory, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _config$APP = _environment.default.APP,
    name = _config$APP.name,
    version = _config$APP.version;
  var _default = _exports.default = {
    name: 'App Version',
    initialize: (0, _initializerFactory.default)(name, version)
  };
});
