import Ember from 'ember';

export default Ember.Component.extend({
    contractType: null,
    contractProductType: null,

    isValid: Ember.computed('contractType', 'contractProductType', function() {
        return this.get('contractType') !== null && this.get('contractProductType') !== null;
    })
});
