define("tpf-fund-manager/mixins/active-client-search-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    store: Ember.inject.service(),
    lookupClientByUuid: function lookupClientByUuid(uuid) {
      return this.get('store').findRecord('client', uuid);
    },
    /**
     * A function allowing classes using this mixin
     * to transform the query before it is sent.
     */
    transformClientSearchParameters: function transformClientSearchParameters(query) {
      return query;
    },
    actions: {
      searchClient: function searchClient(product_type, text) {
        var queryOptions = this.transformClientSearchParameters({
          $search: {
            clientName: text
          },
          sort: {
            clientName: 'DESC'
          },
          isApprovedAndConfigured: true,
          productType: product_type,
          pageSize: 25
        });
        return this.get('store').query('client', queryOptions);
      }
    }
  });
});
