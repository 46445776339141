define("tpf-fund-manager/components/staged-debtor-remittance-allocation-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var displayMap = {
    SEVERELY_UNDERPAID: 'Severely Underpaid',
    UNDERPAID: 'Underpaid',
    INELIGIBLE: 'Closed/Removed',
    OVERPAID: 'Overpaid',
    NONE: 'Fully paid',
    PASSTHROUGH: 'Passthrough',
    UNPURCHASED_INVOICE: 'Unpurchased invoice',
    DUPLICATE_ORDER_ID_INVOICE_ID: 'Order ID/Invoice ID Conflict'
  };
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var classifications = this.getWithDefault('classificationCounts', {});
      this.send('filterByRiskClassification', this._getDefaultTab(classifications));
    },
    /**
     * Set the default tab for the grid based on ordering rules
     * @see classificationList - this is the tab list that uses this default.
     * @param {Object} classifications response of grouped classifications of receipt upload.
     */
    _getDefaultTab: function _getDefaultTab(classifications) {
      var startingFilterKey = Object.keys(classifications).filter(function (key) {
        return classifications[key].count || 0 > 0;
      }).sort(function (x, y) {
        return classifications[y].count - classifications[x].count;
      })[0] || 'PASSTHROUGH';
      return startingFilterKey;
    },
    sortService: Ember.inject.service('sort-state'),
    showGrossAndStatus: Ember.computed('classificationFilter', function () {
      return ['SEVERELY_UNDERPAID', 'UNDERPAID', 'OVERPAID', 'NONE'].includes(this.get('classificationFilter'));
    }),
    showDeductionAndPartial: Ember.computed('classificationFilter', function () {
      return ['SEVERELY_UNDERPAID', 'UNDERPAID'].includes(this.get('classificationFilter'));
    }),
    showOverpaidAmount: Ember.computed('classificationFilter', function () {
      return this.get('classificationFilter') === 'OVERPAID';
    }),
    showAmountPaid: Ember.computed('classificationFilter', function () {
      return ['INELIGIBLE', 'PASSTHROUGH', 'DUPLICATE_ORDER_ID_INVOICE_ID', 'SEVERELY_UNDERPAID', 'UNDERPAID', 'OVERPAID'].includes(this.get('classificationFilter'));
    }),
    showRemoved: Ember.computed('classificationFilter', function () {
      return ['INELIGIBLE'].includes(this.get('classificationFilter'));
    }),
    showStatus: Ember.computed('classificationFilter', function () {
      return ['NONE', 'INELIGIBLE'].includes(this.get('classificationFilter'));
    }),
    showInvoiceAndOrderId: Ember.computed('classificationFilter', function () {
      return ['NONE', 'UNDERPAID', 'SEVERELY_UNDERPAID', 'OVERPAID'].includes(this.get('classificationFilter'));
    }),
    displayName: Ember.computed('classificationFilter', function () {
      return displayMap[this.get('classificationFilter')];
    }),
    classificationList: Ember.computed('classificationFilter', 'classificationCounts', function () {
      var _this = this;
      var classify = this.getWithDefault('classificationCounts', {});
      return Object.keys(classify).map(function (key) {
        return {
          key: key,
          name: displayMap[key],
          count: classify[key].count || 0,
          active: _this.get('classificationFilter') == key
        };
      }).filter(function (x) {
        return x.count > 0;
      }).sort(function (x, y) {
        return y.count - x.count;
      });
    }),
    actions: {
      togglePartiallyPaid: function togglePartiallyPaid(allocation, uploadUuid) {
        allocation.set('forcePartiallyPaid', !allocation.get('forcePartiallyPaid'));
        // May want to just include this in the allocations response object.
        allocation.set('stagedReceiptUploadUuid', uploadUuid);
        allocation.save();
      },
      filterByRiskClassification: function filterByRiskClassification(classification) {
        var _this2 = this;
        this.set('changingFilter', true);
        this.set('classificationFilter', classification);
        var allocations = this.get('allocations');
        // make sure sorts don't persist between different classifications
        this.get('sortService').clear(allocations.modelName);
        if (Ember.get(allocations, 'query.sort')) {
          delete allocations.query.sort;
        }
        allocations.query['riskClassification[$any]'] = [classification];
        allocations.update().finally(function () {
          _this2.set('changingFilter', false);
        });
      }
    }
  });
});
