define("tpf-fund-manager/helpers/item-count-icon", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "tpf-fund-manager/helpers/icon"], function (_exports, _slicedToArray2, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.itemCountIcon = itemCountIcon;
  /**
   * itemCountIcon displays an icon corresponding to the length of the array
   * provided in the collection argument.
   *
   * @param collection - the array of items to be counted.
   * @param size - CSS class name(s) to add to the icon element.  Typically used to declare
   *               the icon size (tiny, small, large...)
   */
  function itemCountIcon(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      collection = _ref2[0],
      size = _ref2[1];
    size = size || 'small';
    collection = collection || [];
    var length = 0;
    if (collection.length) {
      length = collection.length;
    }
    var icon;
    if (length === 0) {
      icon = 'filter_none';
    } else if (length < 10) {
      icon = "filter_".concat(length);
    } else {
      icon = 'filter_9_plus';
    }
    return (0, _icon.icon)([icon, size]);
  }
  var _default = _exports.default = Ember.Helper.helper(itemCountIcon);
});
