define("tpf-fund-manager/components/invoice-verification-priority-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    invoicePrioritization: Ember.computed('productType', function () {
      var application = Ember.getOwner(this);
      if (this.get('productType') === 'FACTORING') {
        return application.lookup("service:invoice-prioritization-factoring");
      }
      return application.lookup("service:invoice-prioritization");
    }),
    session: Ember.inject.service(),
    numUnprocessedInvoices: Ember.computed.reads('invoicePrioritization.unprocessedInvoicesCount'),
    actions: {
      onClick: function onClick() {
        var _this = this;
        this.get('invoicePrioritization.prioritizeInvoices').perform().then(function () {
          if (_this.get('onPrioritized')) {
            _this.get('onPrioritized')();
          }
        });
      }
    }
  });
});
