import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    clientName: Model.attr(),
    emailAddress: Model.attr(),
    isManuallyCreated: Model.attr(),
    divisionUuid: Model.attr(),
    validDivision: Model.attr(),
    isCreated: Model.attr()
});
