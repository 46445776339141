define("tpf-fund-manager/helpers/format-term", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "tpf-fund-manager/config/environment"], function (_exports, _slicedToArray2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatTerm = formatTerm;
  function formatTerm(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      term = _ref2[0];
    var value = parseFloat(term, 10);
    if (value <= 0 || isNaN(value)) {
      return _environment.default.formatHelpers['noValue'];
    }

    // TPF does not lend partial days.
    return Math.floor(term);
  }
  var _default = _exports.default = Ember.Helper.helper(formatTerm);
});
