define("tpf-fund-manager/components/i18n-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Values that must be passed as parameters to the compnent
   *
   * entityType - the entity type the address will be associated with
   * entityUuid - the entity Uuid the address will be associated with
   * additionalRequired - defaults to empty, will cause certain address fields
   *                      to be required in addition to the i18n required
   */
  var _default = _exports.default = Ember.Component.extend({
    addressMetadataService: Ember.inject.service('address-metadata'),
    init: function init() {
      this._super.apply(this, arguments);
      this.displayLabels = {
        O: 'Organization',
        N: 'Name/Attention-To',
        A: 'Address Line(s)',
        D: 'Dependent Locality',
        C: 'City',
        S: 'State/Province',
        Z: 'Postal Code',
        X: 'Sorting Code'
      };
      this.addressAttributes = {
        O: 'organization',
        N: 'name',
        A: 'addressLines',
        D: 'dependentLocality',
        C: 'city',
        S: 'adminArea',
        Z: 'postalCode',
        X: 'sortingCode'
      };
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.initAddress();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.initAddress();
    },
    initAddress: function initAddress() {
      var address = this.get('address');
      Ember.set(address, 'entityType', this.get('entityType'));
      Ember.set(address, 'entityUuid', this.get('entityUuid'));

      // Default to US for country code on an address
      if (!Ember.get(address, 'countryCode')) {
        Ember.set(address, 'countryCode', 'US');
      }
    },
    // Default attr values that are expected to be passed to the component
    entityType: '',
    entityUuid: '',
    // One of the display label keys
    additionalRequired: '',
    addressMetadata: Ember.computed('addressMetadataService', 'selectedCountryCode', function () {
      return this.get('addressMetadataService').getMetaData(this.get('address.countryCode'));
    }),
    // Values for selecting country codes
    countryCodeOptions: Ember.computed('addressMetadataService', function () {
      return this.get('addressMetadataService').getCountryCodeOptions();
    }),
    // The country code a user has selected for the given address
    selectedCountryCode: Ember.computed('address.countryCode', 'countryCodeOptions', function () {
      var options = this.get('countryCodeOptions');
      var code = this.get('address.countryCode');
      var option = {};
      options.forEach(function (countryOption) {
        if (countryOption.code == code) {
          option = countryOption;
        }
      });
      return option;
    }),
    // The format for the given counry code
    // Array of arrays. Each inner array will be on its own line
    formFormat: Ember.computed('address', 'addressMetadata', 'formRequiredFields', function () {
      var _this = this;
      var metaData = this.get('addressMetadata');
      var requiredFormats = metaData.require || [];
      var formatString = '';
      if (metaData.lfmt) {
        formatString = metaData.lfmt;
      } else {
        formatString = metaData.fmt;
      }
      // Getformat string into a usable array
      // Remove any characters that are not
      // * newlines (%n)
      // *  displayLabels (%O, %N, %A, %C, %D, %S, %Z, %X)
      // https://github.com/googlei18n/libaddressinput/wiki/AddressValidationMetadata
      var formatArray = formatString.split('%').map(function (x) {
        return x.trim().replace(' ', '').replace(',', '').replace('n', '!');
      }).filter(function (x) {
        return x !== '';
      }).filter(function (x) {
        return Object.keys(_this.get('displayLabels')).includes(x) || x === '!';
      });

      // Keep track of where we need to slice the array into multiple arrays
      // this is only done because handlebars does not allow dynamic html
      // that does not have matching HTML tags.
      var segments = [];
      formatArray.forEach(function (formatChar, index) {
        if (formatChar === '!') {
          segments.push(index);
        }
      });
      // Always get the last part of the array
      segments.push(formatArray.length);
      var formatArrayOfArray = [];
      var previousIndex = 0;
      segments.forEach(function (arrayIndex) {
        var subArray = formatArray.slice(previousIndex, arrayIndex);
        subArray = subArray.map(function (x) {
          return {
            attribute: _this.get('addressAttributes')[x],
            display: _this.get('displayLabels')[x],
            code: x,
            required: requiredFormats.includes(x) || _this.get('additionalRequired').includes(x)
          };
          // Remove the newline indicators
        }).filter(function (x) {
          return x.code !== '!';
        });
        formatArrayOfArray.push(subArray);
        previousIndex = arrayIndex + 1;
      });
      return formatArrayOfArray;
    }),
    actions: {
      // Whenever a country code is selected the address is automatically updated
      // to the proper name and country code from the ISO data.
      onCountryCodeSelect: function onCountryCodeSelect(countryCodeOption) {
        if (countryCodeOption) {
          this.set('address.countryCode', countryCodeOption.code);
          this.set('address.country', countryCodeOption.name);
        }
      }
    }
  });
});
