import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    loadId: Model.attr('number'),
    buyerId: Model.attr('number'),
    message: Model.attr('string'),
    cause: Model.attr('string'),
    level: Model.attr('string'),
    created: Model.attr('date'),
    value: Model.attr()
});
