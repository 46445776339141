define("tpf-fund-manager/components/site-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    role: 'navigation',
    actions: {
      // Used to allow logging out of the application.
      logout: function logout() {
        this.get('session').invalidate();
      },
      // Used to allow special action on a brand
      brandEditAction: function brandEditAction() {
        this.get('onEdit')();
      }
    }
  });
});
