define("tpf-fund-manager/routes/capfin/factoring/settlements/show", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model(_ref) {
      var settlement_uuid = _ref.settlement_uuid;
      var allocations = this.getPreviousFilters({
        fees: {
          _modelName: 'client-settlement-fee',
          clientSettlementUuid: settlement_uuid,
          pageSize: 10
        },
        purchases: {
          _modelName: 'client-settlement-invoice',
          clientSettlementUuid: settlement_uuid,
          itemType: 'ADVANCE_AMOUNT',
          pageSize: 10
        },
        rebates: {
          _modelName: 'client-settlement-invoice',
          clientSettlementUuid: settlement_uuid,
          itemType: 'REBATE',
          pageSize: 10
        },
        underpayments: {
          _modelName: 'client-settlement-invoice',
          clientSettlementUuid: settlement_uuid,
          itemType: 'UNDERPAYMENT',
          pageSize: 10
        },
        invoiceFees: {
          _modelName: 'client-settlement-invoice-fee',
          clientSettlementUuid: settlement_uuid,
          pageSize: 10
        },
        passthroughs: {
          _modelName: 'client-settlement-passthrough',
          clientSettlementUuid: settlement_uuid,
          pageSize: 10
        },
        chargeBacks: {
          _modelName: 'client-settlement-invoice',
          clientSettlementUuid: settlement_uuid,
          itemType: 'CHARGE_BACK',
          pageSize: 10
        }
      });
      var query = Object.assign({
        settlement: this.store.findRecord('client-settlement', settlement_uuid)
      }, allocations);
      return Ember.RSVP.hash(query);
    }
  });
});
