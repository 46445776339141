define("tpf-fund-manager/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "tpf-fund-manager/config/environment", "ember-local-storage"], function (_exports, _applicationRouteMixin, _environment, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STORE_NAMES = ['sort-store', 'filter-store', 'page-store', 'ui-state', 'sidenav-state'];
  var _default = _exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service('session'),
    sortService: Ember.inject.service('sort-state'),
    sessionAuthenticated: function sessionAuthenticated() {
      // no-op.  Overwrite the default behavior of the application route mixin
      // which is to redirect to the simple-auth configured 'routeAfterAuthentication'
      // but we rely on our c2fo login redirect-to to send us to the proper route.
    },
    sessionInvalidated: function sessionInvalidated(httpStatusCode) {
      // kick over to c2fo login whenever a session gets invalidated.
      // don't use a redirect to here because the markets page would still be
      // our top level.
      // logout url??
      if (httpStatusCode === 401) {
        var encodedRedirect = encodeURIComponent(window.location.href);
        var externalAuthUrl = "".concat(_environment.default.APP.c2foAdmin, "?redirect-to=").concat(encodedRedirect, "&tokenQueryParam=token");
        window.location.replace(externalAuthUrl);
      } else {
        var _externalAuthUrl = "".concat(_environment.default.APP.c2foAdmin, "/logout");
        window.location.replace(_externalAuthUrl);
      }
    },
    beforeModel: function beforeModel() {
      var _this = this;
      STORE_NAMES.map(function (s) {
        return _this.set(s, (0, _emberLocalStorage.storageFor)(s));
      });
      // check client-store version, will reset versioned stores if
      // version number not matching.
      var version = this.get('versionState').checkAndUpdate();
      if (version.didUpdate) {
        this.get('toast').info("<b>Everything is ok!</b> However you may notice some sections have been reset to their default state.  The version for your UI preferences was outdated.  <br/><br/>These preferences include filter, page-size, sorting, etc. Sorry for the inconvenience.");
      }
    },
    actions: {
      refreshRoute: function refreshRoute() {
        return this.refresh();
      },
      logout: function logout() {
        this.get('session').invalidate();
        window.location.href = "".concat(_environment.default.c2foAdmin, "/logout");
      },
      gotoLink: function gotoLink(url) {
        window.location.href = url;
      },
      goToAuthenticatedLink: function goToAuthenticatedLink(url) {
        var token = this.get('session.data.authenticated.token');
        window.location.href = url + '?token=' + token;
      },
      resetUIDefaults: function resetUIDefaults(confirmResult) {
        var _this2 = this;
        if (confirmResult === 'ok') {
          STORE_NAMES.map(function (name) {
            return _this2.get("".concat(name)).reset();
          });
          Ember.run.later(this, function () {
            return window.location.reload();
          }, 800);
        }
        this.controller.set('showResetUIConfirm', false);
      }
    }
  });
});
