define("tpf-fund-manager/services/sort-state", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var sortStoreName = 'sortStore';
  var _default = _exports.default = Ember.Service.extend({
    sortStore: (0, _emberLocalStorage.storageFor)('sort-store'),
    currentRouteName: '',
    // set in all routes' beforeModel()
    /**
     * Sets a sort field for the given model with the provided values.
     * @param {*} sortModel The model to namespace the sort fields under,
     * specified as the route concatenated with the ember model,
     * e.g. index_general|tpf-loan.
     * @param {*} sortField The model field to be sorted.
     * @param {*} value The direction to be sorted, e.g. "ASC", "DESC".
     * @returns {Object}
     */
    setParam: function setParam(sortModel, sortField, value) {
      var storeKey = this._buildStoreKey(sortModel);
      var sortStore = this.getWithDefault("".concat(sortStoreName, ".").concat(storeKey), []);
      var found = findFieldInArray(sortStore, sortField);
      if (found.sortItem) {
        found.sortItem.value = value;
      } else {
        sortStore.addObject({
          sortField: sortField,
          value: value
        });
      }
      this.set("".concat(sortStoreName, ".").concat(storeKey), sortStore);
      return sortStoreToObject(sortStore);
    },
    /**
     * Retrieves the sort parameter for the given field and model.
     * @param {*} sortModel The model to namespace the sort fields under,
     * specified as the route concatenated with the ember model,
     * e.g. index_general|tpf-loan.
     * @param {*} sortField The model field to fetch the value for.
     * @returns {String} - The sort direction, "ASC", "DESC", false (no direction), or undefined (not stored).
     */
    getParam: function getParam(sortModel, sortField) {
      var storeKey = this._buildStoreKey(sortModel);
      var sortStore = this.getWithDefault("".concat(sortStoreName, ".").concat(storeKey), []);
      var found = findFieldInArray(sortStore, sortField);
      if (found && found.sortItemIndex === -1) {
        return void 0;
      }
      return found.sortItem.value;
    },
    /**
     * Fetch all sort parameters for the given model.  If no params
     * exist, use the provided defaults to populate the store and
     * return them.  Omit false values as they indicate no sort.
     * @param {*} sortModel The model to namespace the sort fields under,
     * specified as the route concatenated with the ember model,
     * e.g. index_general|tpf-loan.
     * @param {*} defaults Object containing initial sort preferences,
     * if they exist.
     * @returns {Object} The object containing all sort parameters and
     * values for the given model.
     */
    getParams: function getParams(sortModel) {
      var defaults = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var storeKey = this._buildStoreKey(sortModel);
      var params = this.getWithDefault("".concat(sortStoreName, ".").concat(storeKey), []);
      if (params && params.length && params.length > 1) {
        return params.reduce(function (filteredParams, sortItem) {
          if (sortItem && sortItem.value !== false) {
            filteredParams[sortItem.sortField] = sortItem.value;
          }
          return filteredParams;
        }, {});
      }
      var sortStore = Object.keys(defaults).map(function (sortField) {
        return {
          sortField: sortField,
          value: defaults[sortField]
        };
      });
      this.set("".concat(sortStoreName, ".").concat(storeKey), sortStore);
      return defaults;
    },
    /**
     * Removes the sort value for the given model and field from the store.
     * @param {*} sortModel The model to namespace the sort fields under,
     * specified as the route concatenated with the ember model,
     * e.g. index_general|tpf-loan.
     * @param {*} sortField The model field for which to retrieve the sort.
     * @returns {Object}
     */
    deleteParam: function deleteParam(sortModel, sortField) {
      var storeKey = this._buildStoreKey(sortModel);
      var sortStore = this.getWithDefault("".concat(sortStoreName, ".").concat(storeKey), []);
      var found = findFieldInArray(sortStore, sortField);
      if (found.sortItemIndex !== -1) {
        sortStore.removeAt(found.sortItemIndex).compact();
        this.set("".concat(sortStoreName, ".").concat(storeKey), sortStore);
      } else {
        Ember.Logger.error("Could not find sort param \"".concat(sortField, "\" in ").concat(sortStoreName, ".").concat(storeKey));
      }
      return sortStoreToObject(sortStore);
    },
    /**
     * Clear all sorts from the store for the given model.  To override any
     * route set sort orders, set all existing keys for the given sortModel
     * to false.
     * @param {*} sortModel The model to namespace the sort fields under,
     * specified as the route concatenated with the ember model,
     * e.g. index_general|tpf-loan.
     */
    clear: function clear(sortModel) {
      var storeKey = this._buildStoreKey(sortModel);
      var store = this.getWithDefault("".concat(sortStoreName, ".").concat(storeKey), []);
      store.forEach(function (storeItem) {
        return storeItem.value = false;
      });
      this.set("".concat(sortStoreName, ".").concat(storeKey), store);
    },
    /**
     * Reset the given sort model to its application defined sort, removing
     * the custom sort from local storage.
     * @param {*} sortModel The model to namespace the sort fields under,
     * specified as the route concatenated with the ember model,
     * e.g. index_general|tpf-loan.
     */
    reset: function reset(sortModel) {
      var storeKey = this._buildStoreKey(sortModel);
      this.set("".concat(sortStoreName, ".").concat(storeKey), []);
    },
    _buildStoreKey: function _buildStoreKey(sortModel) {
      var currentRouteName = this.get('currentRouteName').replace(/\./g, '_');
      return "".concat(currentRouteName, "|").concat(sortModel);
    }
  });
  function findFieldInArray() {
    var sortStore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var sortField = arguments.length > 1 ? arguments[1] : undefined;
    var sortItemIndex = -1;
    var sortItem = sortStore.find(function (item, index) {
      var isFound = item && item.sortField === sortField;
      if (isFound) {
        sortItemIndex = index;
      }
      return isFound;
    });
    return {
      sortItemIndex: sortItemIndex,
      sortItem: sortItem
    };
  }
  function sortStoreToObject() {
    var sortStore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return sortStore.reduce(function (query, sortItem) {
      query[sortItem.sortField] = sortItem.value;
      return query;
    }, {});
  }
});
