define("tpf-fund-manager/serializers/tpf-loan-repayment-view", ["exports", "tpf-fund-manager/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // logic to preserve serializeIntoHash from JSONSerializer as referred below
  // https://github.com/emberjs/data/blob/v2.11.0/addon/serializers/json.js#L17
  var assign = Ember.assign || Ember.merge;
  var _default = _exports.default = _application.default.extend({
    // eslint-disable-next-line
    compositeKeys: ['uuid', 'loanUuid'],
    extractId: function extractId(modelClass, resourceHash) {
      return this.compositeKeys.map(function (key) {
        return resourceHash[key];
      }).join('-');
    },
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      // we want the default behavior of JSONSerializer instead of RESTSerializer:
      // https://github.com/emberjs/data/blob/v2.11.0/addon/serializers/json.js#L1085
      // (don't key data off model)
      assign(hash, this.serialize(snapshot, options));
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var compositeId = [payload.uuid, payload.loanUuid].join('-');
      return this._super(store, primaryModelClass, payload, compositeId, requestType, true);
    }
  });
});
