define("tpf-fund-manager/components/receipt-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    clientDebtorForReceipt: null,
    debtorForReceipt: null,
    isInvoiceClosed: false,
    isAllocatingReceipt: false,
    isWriteLimited: false,
    isAllocationReadonly: Ember.computed('Receipt.isPending', 'isAllocatingReceipt', function () {
      return !this.get('Receipt.isPending') && !this.get('Receipt.isNew');
    }),
    isAllocationPending: Ember.computed('Receipt.isPending', 'isAllocatingReceipt', function () {
      return this.get('Receipt.isPending') && !this.get('isAllocatingReceipt');
    }),
    remainingAllocation: Ember.computed('Receipt.receivedAmount', 'Receipt.invoiceAllocations.[]', function () {
      var receivedAmount = this.get('Receipt.receivedAmount') || 0;
      var total = this.get('Receipt.invoiceAllocations.[]').reduce(function (memo, item) {
        return memo + item.get('netAmount');
      }, 0);
      return Math.round((receivedAmount - total) * 100) / 100;
    }),
    isOverAllocated: Ember.computed('remainingAllocation', isAllocation(function (x) {
      return x < 0;
    })),
    isAllocated: Ember.computed('remainingAllocation', isAllocation(function (x) {
      return x === 0;
    })),
    saving: false,
    //don't show allocation percentage if empty or in the process of saving
    showAllocation: Ember.computed('saving', 'Receipt.invoiceAllocations.[]', function () {
      return !this.get('saving') && this.get('Receipt.invoiceAllocations.[]').get('length') > 0;
    }),
    selectedAllocationDate: Ember.computed('Receipt.isNew', 'Receipt.receivedDate', function () {
      if (this.get('Receipt.isNew')) {
        return this.get('Receipt.receivedDate');
      } else {
        var existingAllocationDate = this.get('Receipt.invoiceAllocations.firstObject.allocationDate');
        return existingAllocationDate || new Date();
      }
    }),
    actions: {
      searchDebtor: function searchDebtor(text) {
        var queryOptions = {
          $search: {
            name: text
          },
          sort: {
            name: 'DESC'
          },
          pageSize: 25
        };
        return this.get('store').query('debtor', queryOptions);
      },
      searchClient: function searchClient(text) {
        var debtorUuid = this.get('debtorForReceipt.uuid');
        var queryOptions = {
          $search: {
            clientName: text
          },
          sort: {
            clientName: 'DESC'
          },
          isActive: true,
          pageSize: 25
        };
        if (debtorUuid) {
          Object.assign(queryOptions, {
            debtorUuid: debtorUuid
          });
        }
        return this.get('store').query('client-debtor', queryOptions);
      },
      saveReceipt: function saveReceipt(Receipt, override) {
        var _this = this;
        var remainingAllocation = this.get('remainingAllocation');
        if (remainingAllocation === 0 || override) {
          Receipt.set('clientUuid', this.get('clientDebtorForReceipt.clientUuid'));
          Receipt.set('debtorUuid', this.get('debtorForReceipt.uuid'));
          if (Receipt.get('isNew')) {
            Receipt.set('isCollectionSuccessful', true);
          }
          this.set('saving', true);
          return this.get('saveReceipt')(Receipt)
          // at present, we always navigate away from the page.
          // Setting saving to false in success case would allow a user to submit twice
          .catch(function () {
            return _this.set('saving', false);
          });
        } else {
          this.set('showConfirmSaveReceiptDialog', true);
        }
      },
      cancelReceipt: function cancelReceipt(Receipt) {
        return this.get('cancelReceipt')(Receipt);
      },
      exit: function exit() {
        this.get('exit')();
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.transactionTypes = ['ACH', 'CHECK', 'WIRE', 'EXTERNAL', 'OTHER', 'CASHIER_CHECK'];
      this.set('initialCollectionState', this.get('Receipt.isCollectionSuccessful'));
    }
  });
  function isAllocation(fn) {
    return function () {
      return fn(this.get('remainingAllocation'));
    };
  }
});
