import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    uuid: Model.attr(),
    name: Model.attr(),
    organization: Model.attr(),
    addressLines: Model.attr(),
    dependentLocality: Model.attr(),
    city: Model.attr(),
    adminArea: Model.attr(),
    postalCode: Model.attr(),
    sortingCode: Model.attr(),
    country: Model.attr(),
    countryCode: Model.attr(),
    addressType: Model.attr(),
    entityUuid: Model.attr(),
    entityType: Model.attr()
});
