define("tpf-fund-manager/adapters/staged-debtor-remittance-classification-summary", ["exports", "tpf-fund-manager/adapters/application-v2-api"], function (_exports, _applicationV2Api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _applicationV2Api.default.extend({
    snapshotContextResolver: function snapshotContextResolver(snapshot) {
      return {
        uploadUuid: Ember.String.dasherize(snapshot.attr('stagedReceiptUploadUuid').toLowerCase())
      };
    },
    url: function url(context, id) {
      return "".concat(this.host, "/").concat(this.namespace, "/receipts/uploads/").concat(context.uploadUuid, "/debtor-remittance/").concat(id, "/classification-summary");
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return this.url(this.snapshotContextResolver(snapshot), id);
    }
  });
});
