define("tpf-fund-manager/routes/markets/show/buyer-toggle/index", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash(this.getPreviousFilters({
        buyerToggleClientsView: {
          _modelName: 'buyer-toggle-onboarding-status-view',
          sort: {
            created: 'DESC'
          }
        }
      }));
    }
  });
});
