define("tpf-fund-manager/helpers/format-currency", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "accounting", "tpf-fund-manager/config/environment"], function (_exports, _slicedToArray2, _accounting, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatCurrency = formatCurrency;
  function getFormatOptions(currencyCode) {
    var currencies = _environment.default.formatHelpers['currencyMap'];
    return Ember.assign({}, currencies[currencyCode] || currencies['USD']);
  }
  function formatCurrency(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      rawValue = _ref2[0],
      _ref2$ = _ref2[1],
      currency = _ref2$ === void 0 ? 'USD' : _ref2$;
    var options = getFormatOptions(currency);
    var value = parseFloat(rawValue);

    // NaN is treated as zero value.
    if (isNaN(value)) {
      value = 0;
    }

    // Due to decimal precision and rounding very small numbers can appear zero-like.
    // Do format zero since these values do exist.
    if (_accounting.default.toFixed(value, 3) === '0.000') {
      value = Math.abs(value);
      options.precision = 0;
    }
    return _accounting.default.formatMoney(value, options);
  }
  var _default = _exports.default = Ember.Helper.helper(formatCurrency);
});
