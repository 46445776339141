define("tpf-fund-manager/components/bank-account-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var fileTypeRegex = /(data:)(.*)(;.*base64.*,)(.*)/;
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.resetOptions = Ember.A(['CHANGE_REQUEST', 'INVALID_VERIFICATION_DOCUMENT', 'FAILED_VALIDATION']);
    },
    isVerifyDisabled: Ember.computed('bankAccount.status', function () {
      return this.get('bankAccount.status') === 'VERIFIED';
    }),
    isResetOptionNotSelected: Ember.computed('selectedResetOption', function () {
      return !this.get('selectedResetOption');
    }),
    verificationDocumentFileType: Ember.computed('verificationImage', function () {
      var fileType = 'Unknown';
      var img = this.get('verificationImage');
      if (img) {
        var groups = img.match(fileTypeRegex);
        fileType = groups && groups.length >= 3 ? groups[2] : fileType;
      }
      return fileType;
    }),
    selectedResetOption: null,
    actions: {
      markVerified: function markVerified(bankAccount) {
        var _this = this;
        bankAccount.set('status', 'VERIFIED');
        return bankAccount.save().then(function () {
          _this.get('refresh')();
          _this.set('showBankAccountModal', false);
        });
      },
      resetBankAccount: function resetBankAccount(bankAccount) {
        var _this2 = this;
        bankAccount.set('status', this.get('selectedResetOption'));
        return bankAccount.save().then(function () {
          _this2.get('refresh')();
        });
      },
      viewBankAccount: function viewBankAccount() {
        var _this3 = this;
        var divisionUuid = this.get('divisionUuid');
        this.get('bankAccount').bankAccountNumber({
          divisionUuid: divisionUuid
        }).then(function (response) {
          if (response.dataVgs) _this3.set('accountNumber', response.dataVgs);else _this3.set('accountNumber', response.data);
        }).then(function () {
          _this3.get('bankAccount').verificationImage({
            divisionUuid: divisionUuid
          }).then(function (response) {
            if (response.dataVgs) _this3.set('verificationImage', response.dataVgs);else _this3.set('verificationImage', response.data);
          });
        }).then(function () {
          _this3.set('showBankAccountModal', true);
        });
      },
      closeBankAccountModal: function closeBankAccountModal() {
        this.set('showBankAccountModal', false);
        this.set('verificationImage', null);
        this.set('accountNumber', null);
      },
      onErrorLoadingVerificationImage: function onErrorLoadingVerificationImage() {
        this.set('isErrorLoadingVerificationImage', true);
      }
    }
  });
});
