define("tpf-fund-manager/utils/is-index-transition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isIndexTransition;
  /**
   * isIndexTransition can be used in a route's 'beforeModel', 'afterModel', or
   * 'redirect' method to determine if the current transition is index-like.
   *
   * Usage example:
   *
   * ```JavaScript
   * import isIndexTransition from 'utils/isIndexTransition';
   *
   * export default Ember.Route.extend({
   *    redirect(model, transition) {
   *        if (isIndexTransition(transition, 'markets.show.loans.ops')) {
   *            this.transitionTo('markets.show.loans.ops.index.suppliers');
   *        }
   *    }
   * });
   * ```
   *
   * @param transition - transition provided in a route's beforeModel, afterModel, or redirect method.
   * @param sectionPath - a base state-path to compare current state to.  This should be provided for
   *                      'root' sections (see example)
   * @returns {boolean} - if any "<sectionPath><indexTarget>" combinations
   *                      matches the current transition.
   */
  function isIndexTransition(transition) {
    var sectionPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var indexTargets = ['', '.index', '.index.index'];
    return indexTargets.map(function (postfix) {
      return "".concat(sectionPath).concat(postfix);
    }).indexOf(transition.targetName) !== -1;
  }
});
