define("tpf-fund-manager/components/feathers-sort", ["exports", "tpf-fund-manager/templates/components/feathers-sort", "ember-keyboard"], function (_exports, _feathersSort, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ASC = 'ASC';
  var DESC = 'DESC';
  var _default = _exports.default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    layout: _feathersSort.default,
    tagName: 'span',
    store: Ember.inject.service(),
    sortService: Ember.inject.service('sort-state'),
    // enable / disable multiColumn sort with "Cmd+Click" or "Ctrl+click"
    // isMultiColumn: false,
    // eslint-disable-next-line
    activateKeyboard: Ember.on('init', function () {
      this.set('keyboardActivated', true);
    }),
    enableKeyModifier: Ember.on((0, _emberKeyboard.keyDown)(), function keyDown(evnt) {
      if (['Meta', 'Alt'].indexOf(evnt.key) > -1) {
        this.set('isMultiColumn', true);
      }
    }),
    disableKeyModifier: Ember.on((0, _emberKeyboard.keyUp)(), function keyUp(evnt) {
      if (['Meta', 'Alt'].indexOf(evnt.key) > -1) {
        this.set('isMultiColumn', false);
      }
    }),
    isAsc: Ember.computed.equal('sortValue', ASC),
    isDesc: Ember.computed.equal('sortValue', DESC),
    isSorted: Ember.computed('data.@each.isUpdating', 'sortValue', 'isMultiColumn', {
      get: function get() {
        var data = this.getWithDefault('data', {});
        var fieldName = this.get('fieldName');
        var querySortValue = this.get('sortService').getParam(data.modelName, fieldName);
        if (!querySortValue && data && data.query && data.query.sort && data.query.sort[fieldName]) {
          querySortValue = this.get('sortService').setParam(data.modelName, fieldName, data.query.sort[fieldName]);
        }
        if (!querySortValue) {
          // eslint-disable-next-line
          this.set('sortValue', '');
        }
        return Boolean(querySortValue);
      }
    }),
    toggleSort: function toggleSort() {
      var data = this.getWithDefault('data', {});
      var fieldName = this.get('fieldName');
      var sortValue = this.get('sortService').getParam(data.modelName, fieldName);
      if (!sortValue) {
        sortValue = ASC;
      } else if (sortValue === ASC) {
        sortValue = DESC;
      } else {
        sortValue = '';
      }
      if (!sortValue) {
        this.clearSort();
      } else {
        if (!data.query.sort) {
          data.query.sort = {};
        }

        // sorts that are not multi-column will reset all other filters.
        if (!this.get('isMultiColumn')) {
          this.get('sortService').clear(data.modelName);
          data.query.sort = {};
        }
        var sortParamVal = sortValue || false;
        this.get('sortService').setParam(data.modelName, fieldName, sortParamVal);
        this.set('sortValue', sortValue);
        data.query.sort[fieldName] = sortValue;
      }
    },
    clearSort: function clearSort() {
      var data = this.getWithDefault('data', {});
      var fieldName = this.get('fieldName');
      this.get('sortService').setParam(data.modelName, fieldName, false);
      this.set('sortValue', '');
      if (data.query.sort[fieldName]) {
        delete data.query.sort[fieldName];
      }

      // If at any point across all sort components there are
      // no values for the sort we should delete the sort object.
      if (data.query.sort && Object.keys(data.query.sort).length <= 0) {
        delete data.query.sort;
      }
    },
    actions: {
      toggleSort: function toggleSort() {
        var _this = this;
        var callToggle = function callToggle() {
          _this.toggleSort();
          _this.get('data').update();
        };
        Ember.run.debounce(this, callToggle, 200);
      },
      clearSort: function clearSort() {
        var _this2 = this;
        var callClear = function callClear() {
          _this2.clearSort();
          _this2.get('data').update();
        };
        Ember.run.debounce(this, callClear, 200);
      }
    }
  });
});
