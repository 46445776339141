import DS from 'ember-data';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
    uuid: DS.attr(),
    onboardingFormUuid: DS.attr(),
    onboardingAgreementTextUuid: DS.attr(),
    ipAddress: DS.attr(),
    authorizedBy: DS.attr(),
    loggedInUserEmail: DS.attr(),
    created: DS.attr('date'),
    updated: DS.attr('date')
});
