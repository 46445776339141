import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    uuid: Model.attr(),
    code: Model.attr(),
    description: Model.attr(),
    percent: Model.attr(),
    discountDays: Model.attr(),
    netDays: Model.attr(),
    extraDays: Model.attr(),
    eomCode: Model.attr(),
    created: Model.attr(),
    updated: Model.attr(),

    // related attrs
    // client_debtor_term_code.is_default
    isDefault: Model.attr()
});
