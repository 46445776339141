define("tpf-fund-manager/components/receipt-upload-summary-subgroup", ["exports", "tpf-fund-manager/mixins/selectable-grid-mixin"], function (_exports, _selectableGridMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_selectableGridMixin.default, {
    router: Ember.inject.service(),
    // Required for the SelectableGridMixin mixin.
    selectableItems: Ember.computed.alias('debtorRemittance'),
    // Required for the SelectableGridMixin mixin.
    id: 'uuid',
    /**
     * Overrides toggleSelection from superclass.
     *
     * @param value - boolean parameter that determine if the rows are selected or not.
     */
    toggleSelection: function toggleSelection(value) {
      var items = this.get('selectableItems');
      Ember.assert("Component with selectable-grid-mixin must have a property 'selectableItems'", !Ember.isEmpty(items));
      items.forEach(function (item) {
        if (item.get('status') !== 'REJECTED' && item.get('status') !== 'COMMITTED') {
          item.set('selected', value);
        }
      });
    },
    allReviewed: Ember.computed('debtorRemittance', function () {
      return this.get('debtorRemittance').every(function (remittance) {
        return remittance.get('status') === 'REJECTED' || remittance.get('status') === 'COMMITTED';
      });
    }),
    isProcessing: Ember.computed('debtorRemittance', function () {
      return this.get('debtorRemittance').every(function (remittance) {
        return remittance.get('status') === 'IMPORTED';
      });
    }),
    submitButtonMessage: Ember.computed('selectedItems', function () {
      if (this.get('selectedItems').length > 0) {
        return 'Submit';
      }
      return 'Submit All';
    }),
    riskSeverities: Ember.computed('riskSeverities', function () {
      return this.get('debtorRemittance').map(function (remittance) {
        return remittance.get('riskSeverity');
      });
    }),
    isRiskFree: Ember.computed(function () {
      return this.get('debtorRemittance').every(function (remittance) {
        return remittance.get('riskSeverity') === 0;
      });
    }),
    actions: {
      commitReceipts: function commitReceipts(upload) {
        var _this = this;
        this.set('committingReceipts', true);
        var selectedUuids = this.get('selectedItems').map(function (item) {
          return item.get('uuid');
        });
        upload.commit({
          debtorRemittanceUuids: selectedUuids,
          riskSeverities: this.get('riskSeverities')
        }).then(function () {
          _this.resetSelection();
          _this.get('onSubmit')(_this.get('stagedReceiptUploadUuid'));
        });
      },
      rejectReceipts: function rejectReceipts(upload) {
        var _this2 = this;
        this.set('rejectingReceipts', true);
        upload.set('status', 'CLOSED');
        upload.save({
          adapterOptions: {
            riskSeverities: this.get('riskSeverities').join()
          }
        }).then(function () {
          _this2.get('onReturnToAllReceipts')();
        });
      }
    }
  });
});
