define("tpf-fund-manager/routes/capfin/show", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    beforeModel: function beforeModel() {
      var takerUuid = this.paramsFor(this.routeName).taker_uuid;
      this.replaceWith('capfin.clients.show.index', takerUuid);
    }
  });
});
