import DS from 'ember-data';

export default DS.Model.extend({
    advanceRate: DS.attr(),
    asOfDate: DS.attr(),
    clientUuid: DS.attr(),
    collateral: DS.attr(),
    collateralOther: DS.attr(),
    collectionDays: DS.attr(),
    creditLimit: DS.attr(),
    effectiveDate: DS.attr(),
    fundingSourceUuid: DS.attr(),
    guarantor: DS.attr(),
    interestRateChangeFrequency: DS.attr(),
    maturityInterval: DS.attr(),
    invoiceFee: DS.attr(),
    maturityLength: DS.attr(),
    note: DS.attr(),
    productType: DS.attr(),
    productSubtype: DS.attr(),
    rate: DS.attr(),
    rateType: DS.attr(),
    type: DS.attr(),
    verifiedArThreshold: DS.attr(),
    uuid: DS.attr(),
    chargeLatePaymentFees: DS.attr(),
    provideRebatesForEarlyPayment: DS.attr()
});
