define("tpf-fund-manager/routes/capfin/clients/show/debtors/new", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      var client = this.modelFor('capfin.clients.show').client;
      return Ember.RSVP.hash({
        client: client
      });
    },
    actions: {
      handleCreate: function handleCreate() {
        var _this = this;
        var client = this.modelFor('capfin.clients.show').client;
        return client.reload().then(function () {
          return _this.transitionTo('capfin.clients.show.debtors');
        });
      }
    }
  });
});
