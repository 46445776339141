define("tpf-fund-manager/adapters/onboarding-status", ["exports", "tpf-fund-manager/adapters/application-v2-api"], function (_exports, _applicationV2Api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _applicationV2Api.default.extend({
    query: function query(store, type, _query) {
      var newQuery = Ember.copy(_query, true);
      // Add a default sort on name. Without this sort, pagination is at the whim of postgres.
      newQuery.sort = newQuery.sort || {};
      if (!newQuery.sort['clientName']) {
        newQuery.sort['clientName'] = 'ASC';
      }
      return this._super(store, type, newQuery);
    }
  });
});
