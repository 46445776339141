define("tpf-fund-manager/routes/capfin/receivables-finance/advances/index", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    modelBulkUpdate: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        tabs: Ember.A([Ember.Object.create({
          route: 'capfin.receivables-finance.advances.index.pending',
          label: 'Pending'
        }), Ember.Object.create({
          route: 'capfin.receivables-finance.advances.index.initiated',
          label: 'Initiated'
        }), Ember.Object.create({
          route: 'capfin.receivables-finance.advances.index.sent',
          label: 'Sent'
        })]),
        fundingSources: this.get('store').findAll('funding-source')
      }).then(function (results) {
        results.fundingSources = results.fundingSources.map(function (source) {
          return {
            name: source.get('name'),
            value: source.get('name')
          };
        });
        return results;
      });
    },
    actions: {
      onCreateNew: function onCreateNew() {
        this.transitionTo('capfin.receivables-finance.advances.new');
      },
      updateStatus: function updateStatus(uuid, status) {
        var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        var transferMethod = opts.transferMethod,
          transactionFee = opts.transactionFee,
          advanceDate = opts.advanceDate;
        return this.get('store').findRecord('Advance', uuid).then(function (Advance) {
          Advance.set('status', status);
          if (advanceDate !== undefined) {
            Advance.set('advanceDate', advanceDate);
          }
          if (transferMethod !== undefined) {
            Advance.set('transferMethod', transferMethod);
          }
          if (transactionFee !== undefined) {
            Advance.set('transactionFee', transactionFee);
          }
          if (Advance.get('hasDirtyAttributes')) {
            return Advance.save();
          }
        });
      },
      updateSoldDate: function updateSoldDate(uuid, soldDate) {
        return this.get('store').findRecord('Advance', uuid).then(function (advance) {
          advance.set('soldDate', soldDate);
          advance.save();
        });
      }
    }
  });
});
