import DS from 'ember-data';

export default DS.Model.extend({
    uuid: DS.attr(),
    clientUuid: DS.attr(),
    status: DS.attr(),
    s3Path: DS.attr(),
    userEmailAddress: DS.attr(),
    userIpAddress: DS.attr(),
    errorCount: DS.attr(),
    newCount: DS.attr(),
    newAmount: DS.attr(),
    updatedCount: DS.attr(),
    updatedAmount: DS.attr(),
    successCount: DS.attr(),
    successAmount: DS.attr(),
    productType: DS.attr(),
    created: DS.attr(),
    updated: DS.attr(),
    unmappedCount: DS.attr(),
    unmappedAmount: DS.attr(),
    identicalCount: DS.attr(),
    identicalAmount: DS.attr()
});
