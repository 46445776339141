import Ember from 'ember';

export default Ember.Component.extend({
    tagName: 'span',
    status: 'unknown',
    classNames: 'card',
    classNameBindings: [
        // success css
        'isSuccess:light-green',
        'isSuccess:lighten-1',
        'isSuccess:white-text',
        // uncollected css
        'isUnsuccessful:deep-orange',
        'isUnsuccessful:lighten-2',
        'isUnsuccessful:white-text',
        // pending css
        'isPending:amber',
        'isPending:lighten-2',
        'isPending:grey-text',
        'isPending:text-darken-3'
    ],
    isSuccess: Ember.computed('status', function() {
        const status = this.get('status');
        return ['SUCCESS', 'SUCCESSFUL'].includes(status);
    }),
    isUnsuccessful: Ember.computed.equal('status', 'UNSUCCESSFUL'),
    isPending: Ember.computed.equal('status', 'PENDING')
});
