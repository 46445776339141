define("tpf-fund-manager/services/connect", ["exports", "tpf-fund-manager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    oneTimeConnectToken: Ember.inject.service('one-time-connect-token'),
    /**
     * The company meta data endpoint returns the uuid of the company which is used to get the Codat url
     * @param divisionUuid
     * @param marketUuid
     */
    getCompanyCodatUrl: function getCompanyCodatUrl(divisionUuid) {
      var connectToken = this.get('oneTimeConnectToken').getToken(divisionUuid);
      return new Ember.RSVP.Promise(function (resolve) {
        var request = Ember.$.ajax({
          url: "".concat(_environment.default.APP.connectApi, "/api/codat/codatUrl/").concat(divisionUuid, "/"),
          type: 'GET',
          contentType: 'application/json',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', "Bearer ".concat(connectToken));
          }
        });
        request.done(resolve);
        request.fail(function () {
          resolve(null);
        });
      });
    }
  });
});
