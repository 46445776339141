import Ember from 'ember';

export default Ember.Component.extend({
    isPreAgreementSigned: Ember.computed('model.onboardingForm.preOnboardingAgreement', function() {
        return !Ember.isNone(this.get('model.onboardingForm.preOnboardingAgreement'));
    }).readOnly(),
    isPostAgreementSigned: Ember.computed('model.onboardingForm.postOnboardingAgreement', function() {
        return !Ember.isNone(this.get('model.onboardingForm.postOnboardingAgreement'));
    }).readOnly(),
    isPrivacyAgreementSigned: Ember.computed('model.onboardingForm.privacyPolicyAgreement', function() {
        return !Ember.isNone(this.get('model.onboardingForm.privacyPolicyAgreement'));
    }).readOnly()
});
