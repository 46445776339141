define("tpf-fund-manager/routes/capfin/admin/month-end-factoring-reports/index", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      return this.get('store').findAll('month-end-factoring-report-upload', {
        sort: {
          created: 'DESC'
        }
      }).then(function (uploads) {
        return Ember.RSVP.hash({
          uploads: uploads
        });
      });
    }
  });
});
