define("tpf-fund-manager/components/tpf-file-upload", ["exports", "ember-uploader/components/file-field", "ember-uploader/uploaders/uploader"], function (_exports, _fileField, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _fileField.default.extend({
    session: Ember.inject.service('session'),
    filesDidChange: function filesDidChange(files) {
      var session = this.get('session');
      var token = session.get('data.authenticated.token');
      var uploader = _uploader.default.create({
        url: "".concat(this.get('url'), "?token=").concat(token)
      });
      if (!Ember.isEmpty(files)) {
        uploader.upload(files[0], this.get('bodyData')).then(this.get('onUploadComplete'));
      }
    }
  });
});
