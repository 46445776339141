define("tpf-fund-manager/models/onboarding-form", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    uuid: _emberData.default.attr(),
    divisionUuid: _emberData.default.attr(),
    isActive: _emberData.default.attr(),
    businessLegalName: _emberData.default.attr(),
    industryTypeUuid: _emberData.default.attr(),
    annualRevenue: _emberData.default.attr(),
    entityType: _emberData.default.attr(),
    entityTypeOther: _emberData.default.attr(),
    address1: _emberData.default.attr(),
    address2: _emberData.default.attr(),
    city: _emberData.default.attr(),
    state: _emberData.default.attr(),
    postalCode: _emberData.default.attr(),
    website: _emberData.default.attr(),
    agentName: _emberData.default.attr(),
    agentAddress1: _emberData.default.attr(),
    agentAddress2: _emberData.default.attr(),
    agentCity: _emberData.default.attr(),
    agentState: _emberData.default.attr(),
    agentPostalCode: _emberData.default.attr(),
    taxId: _emberData.default.attr(),
    numberOfEmployees: _emberData.default.attr(),
    stateOfFormation: _emberData.default.attr(),
    fiscalYearEnd: _emberData.default.attr(),
    dateOfFormation: _emberData.default.attr(),
    numShareholdersGt10Stake: _emberData.default.attr(),
    rankingExecutiveEmployeeName: _emberData.default.attr(),
    rankingExecutiveEmployeeEmailAddress: _emberData.default.attr(),
    rankingExecutiveCanExecuteDocs: _emberData.default.attr(),
    rankingOperationsEmployeeName: _emberData.default.attr(),
    rankingOperationsEmployeeEmailAddress: _emberData.default.attr(),
    rankingOperationsCanExecuteDocs: _emberData.default.attr(),
    rankingFinancialEmployeeName: _emberData.default.attr(),
    rankingFinancialEmployeeEmailAddress: _emberData.default.attr(),
    rankingFinancialCanExecuteDocs: _emberData.default.attr(),
    amountToBorrow: _emberData.default.attr(),
    reasonForBorrowing: _emberData.default.attr(),
    reasonForBorrowingDescription: _emberData.default.attr(),
    continuingGuarantees: _emberData.default.attr(),
    debtProcessDescription: _emberData.default.attr(),
    isMerchantCategoryApproved: _emberData.default.attr(),
    preOnboardingAgreementUuid: _emberData.default.attr(),
    postOnboardingAgreementUuid: _emberData.default.attr(),
    hasSubsidiariesOrAffiliates: _emberData.default.attr(),
    hasAdditionalBusinessNames: _emberData.default.attr(),
    hasAdditionalProductNames: _emberData.default.attr(),
    // these fields have computed 'inverse properties' to make form logic easier.
    // the non-computed ones are the 'real' ones that get persisted.
    hasOutstandingDebts: _emberData.default.attr(),
    hasNoOutstandingDebts: Ember.computed('hasOutstandingDebts', {
      get: function get() {
        if (!Ember.isNone(this.get('hasOutstandingDebts'))) {
          return !this.get('hasOutstandingDebts');
        }
        return false;
      },
      set: function set(key, value) {
        this.set('hasOutstandingDebts', !value);
        return value;
      }
    }),
    hasContinuingGuarantees: _emberData.default.attr(),
    hasNoContinuingGuarantees: Ember.computed('hasContinuingGuarantees', {
      get: function get() {
        if (!Ember.isNone(this.get('hasContinuingGuarantees'))) {
          return !this.get('hasContinuingGuarantees');
        }
        return false;
      },
      set: function set(key, value) {
        this.set('hasContinuingGuarantees', !value);
        return value;
      }
    }),
    preOnboardingAgreement: (0, _attributes.fragment)('onboarding-agreement'),
    additionalQuestionAnswers: (0, _attributes.fragmentArray)('onboarding-additional-question-answer'),
    applicantSubsidiaries: (0, _attributes.fragmentArray)('onboarding-applicant-subsidiary'),
    additionalBusinessNames: (0, _attributes.fragmentArray)('onboarding-additional-business-name'),
    additionalProductNames: (0, _attributes.fragmentArray)('onboarding-additional-product-name'),
    relatedCustomers: (0, _attributes.fragmentArray)('onboarding-related-customer'),
    relatedSuppliers: (0, _attributes.fragmentArray)('onboarding-related-supplier'),
    existingDebts: (0, _attributes.fragmentArray)('onboarding-existing-debt'),
    postOnboardingAgreement: (0, _attributes.fragment)('onboarding-agreement'),
    privacyPolicyAgreement: (0, _attributes.fragment)('onboarding-agreement')
  });
});
