define("tpf-fund-manager/routes/capfin/clients/show/settlements", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* This is the route responsible for displaying settlements specific to a Factoring client.
   via expounding on the routes/capfin/factoring/settlements/index/sent.js route by adding
   the clientUUid property to filter settlements specific to that clientUuid */
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      var clientUuid = this.modelFor('capfin.clients.show').client.get('uuid');
      return Ember.RSVP.hash(this.getPreviousFilters({
        settlements: {
          _modelName: 'client-settlement',
          settlementStatus: 'SENT',
          clientUuid: clientUuid,
          sort: {
            issuedDate: 'DESC'
          },
          pageSize: 25
        }
      }));
    }
  });
});
