define("tpf-fund-manager/services/version-state", ["exports", "ember-local-storage/helpers/storage"], function (_exports, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    store: (0, _storage.storageFor)('version-store'),
    isCurrentVersion: Ember.computed('store.version', function isCurrentVersion() {
      return this.get('store').isInitialContent();
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.getWithDefault('store.versionedKeys', []).map(function (key) {
        return _this.set("".concat(key), (0, _storage.storageFor)(key));
      });
    },
    checkAndUpdate: function checkAndUpdate() {
      var result = {
        didUpdate: false
      };
      if (!this.get('isCurrentVersion')) {
        this.resetVersionedStores();
        result.didUpdate = true;
      }
      return result;
    },
    resetVersionedStores: function resetVersionedStores() {
      var _this2 = this;
      this.getWithDefault('store.versionedKeys', []).map(function (name) {
        var store = _this2.get("".concat(name));
        if (store && store.reset) {
          store.reset();
        }
      });
      this.get('store').reset();
    }
  });
});
