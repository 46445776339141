define("tpf-fund-manager/components/repayment-allocation-invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.initProperties();
    },
    initProperties: function initProperties() {
      this.setProperties({
        amountPaid: 0,
        grossAmount: 0,
        discountAmount: 0,
        description: undefined,
        selectedInvoice: undefined,
        isInvoiceClosed: false
      });
    },
    deductionAmount: Ember.computed('amountPaid', 'grossAmount', 'discountAmount', 'deductionAmount', function () {
      var amountPaid = this.amountPaid,
        grossAmount = this.grossAmount,
        discountAmount = this.discountAmount;
      if (!amountPaid) {
        return;
      }
      return Math.round((grossAmount - discountAmount - amountPaid) * 100) / 100;
    }),
    isSelectInvoiceDisabled: Ember.computed.empty('clientDebtor'),
    isAddInvoiceDisabled: Ember.computed('grossAmount', 'discountAmount', 'deductionAmount', 'selectedInvoice', function () {
      var _this = this;
      var either = ['selectedInvoice'];
      var checks = ['grossAmount', 'discountAmount', 'deductionAmount'];
      return either.every(function (x) {
        return Ember.isEmpty(_this.get(x));
      }) || checks.some(function (x) {
        return Ember.isEmpty(_this.get(x));
      });
    }),
    actions: {
      applyInvoice: function applyInvoice(invoice) {
        this.set('selectedInvoice', invoice);
        this.set('grossAmount', invoice.amount || 0);
        this.set('discountAmount', invoice.discountAmount || 0);
        return invoice;
      },
      addInvoice: function addInvoice() {
        var grossAmount = this.grossAmount,
          discountAmount = this.discountAmount,
          description = this.description,
          isInvoicePartiallyPaid = this.isInvoicePartiallyPaid;
        var allocation = {
          invoiceUuid: this.selectedInvoice.uuid,
          referenceNumber: this.selectedInvoice.invoiceId,
          grossAmount: grossAmount,
          deductionAmount: this.get('deductionAmount'),
          discountAmount: discountAmount,
          invoiceStatus: isInvoicePartiallyPaid ? 'PARTIALLY_PAID' : 'CLOSED',
          description: description
        };
        this.addInvoice(allocation);
        this.initProperties();
      }
    }
  });
});
