define("tpf-fund-manager/transforms/under-review-reason-enum", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.underReviewReasonMap = _exports.default = void 0;
  var underReviewReasonMap = _exports.underReviewReasonMap = {
    FINANCIAL_DOCUMENTS_EXPIRED_THIS_YEAR: 'Financial Docs Expired - This Year',
    FINANCIAL_DOCUMENTS_EXPIRED_BOTH_YEARS: 'Financial Docs Expired - Both Years',
    NO_PARTICIPATION_THRESHOLD_THIS_YEAR: "No Participation - This Year's Docs",
    NO_PARTICIPATION_THRESHOLD_BOTH_YEARS: "No Participation - Both Years' Docs",
    GENERAL_AUDIT: 'General Audit'
  };
  var _default = _exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return underReviewReasonMap[serialized];
    },
    serialize: function serialize(deserialized) {
      var serializedValue = Object.keys(underReviewReasonMap).find(function (key) {
        return underReviewReasonMap[key] === deserialized;
      });
      return serializedValue || null;
    }
  });
});
