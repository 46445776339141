define("tpf-fund-manager/storages/version-store", ["exports", "ember-local-storage/local/object", "tpf-fund-manager/config/environment"], function (_exports, _object, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend({
    //eslint-disable-next-line
    config: _environment.default['local-storage-version'] || {}
  }).reopenClass({
    initialState: function initialState() {
      var stateConf = _environment.default['local-storage-version'] || {};
      return stateConf.initialState || {};
    }
  });
});
