define("tpf-fund-manager/helpers/format-integer", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatInteger = formatInteger;
  function formatInteger(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      num = _ref2[0],
      zeroReturn = _ref2[1];
    var parsedNum = parseInt(num, 10);
    zeroReturn = zeroReturn || '-';
    if (parsedNum === 0) {
      return zeroReturn;
    }
    return parsedNum;
  }
  var _default = _exports.default = Ember.Helper.helper(formatInteger);
});
