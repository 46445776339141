define("tpf-fund-manager/components/repayment-allocation-ineligible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.initProperties();
    },
    initProperties: function initProperties() {
      this.setProperties({
        amountPaid: 0,
        grossAmount: 0,
        discountAmount: 0,
        description: undefined,
        selectedInvoice: undefined,
        isInvoiceClosed: false,
        referenceNumber: undefined
      });
    },
    isAddAllocationDisabled: Ember.computed('grossAmount', 'discountAmount', 'deductionAmount', 'referenceNumber', 'selectedInvoice', function () {
      var _this = this;
      var checks = ['grossAmount', 'discountAmount', 'deductionAmount'];
      return checks.some(function (x) {
        return Ember.isEmpty(_this.get(x));
      }) || Ember.isEmpty(this.get('selectedInvoice')) && Ember.isEmpty(this.get('referenceNumber'));
    }),
    isSelectInvoiceDisabled: Ember.computed.empty('clientDebtor'),
    actions: {
      applyInvoice: function applyInvoice(invoice) {
        this.set('selectedInvoice', invoice);
        this.set('grossAmount', invoice.amount || 0);
        this.set('discountAmount', invoice.discountAmount || 0);
        this.set('referenceNumber', invoice.invoiceId);
        return invoice;
      },
      addAllocation: function addAllocation() {
        var grossAmount = this.grossAmount,
          deductionAmount = this.deductionAmount,
          discountAmount = this.discountAmount,
          description = this.description,
          referenceNumber = this.referenceNumber;
        var invoiceUUID = this.selectedInvoice ? this.selectedInvoice.uuid : null;
        var allocation = {
          invoiceUuid: invoiceUUID,
          referenceNumber: referenceNumber || this.selectedInvoice.invoiceId,
          grossAmount: grossAmount,
          deductionAmount: deductionAmount,
          discountAmount: discountAmount,
          description: description
        };
        this.addAllocation(allocation);
        this.initProperties();
      }
    }
  });
});
