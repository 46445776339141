define("tpf-fund-manager/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "tpf-fund-manager/config/environment", "ember-inflector", "tpf-fund-manager/mixins/ember-simple-auth-authorizer"], function (_exports, _emberData, _dataAdapterMixin, _environment, _emberInflector, _emberSimpleAuthAuthorizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This will be the Feathersjs adapter for ember-data once it's done
  // Nothing has been tested other than GET of data. POST, PATCH, DELETE
  // will all need to be tested and added.
  // THE ORDER OF MIXINS IS IMPORTANT
  var _default = _exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, _emberSimpleAuthAuthorizer.default, {
    toast: Ember.inject.service(),
    defaultSerializer: 'serializer:application',
    host: _environment.default.APP.tpfApi,
    /**
     * Overridden to support feathersjs style end points which are always
     * hyphenated and use plurals.
     */
    pathForType: function pathForType(type) {
      var dasherized = Ember.String.dasherize(type);
      return (0, _emberInflector.pluralize)(dasherized);
    },
    handleResponse: function handleResponse(status, headers, payload) {
      var session = this.get('session');

      // Update the JWT on each response from the server.
      // This keeps the session alive over time as long
      // as the user is doing something with data inside
      // the application.
      if (payload && payload.jwt) {
        var authData = session.get('data');
        if (authData.authenticated) {
          // Use set so the token is actually updated.
          session.set('data.authenticated.token', payload.jwt);
        }
      }
      var encodedRedirect = encodeURIComponent(window.location.href);
      var externalAuthUrl = "".concat(_environment.default.APP.c2foAdmin, "/login?redirect-to=").concat(encodedRedirect, "&tokenQueryParam=token");
      switch (session && status) {
        case 401:
          session.invalidate();
          window.location.replace(externalAuthUrl);
          return;
        default:
          if (session && status >= 400) {
            var err = feathersErrorResponse.call(this, status, headers, payload, session);
            var errorMessages = err.errors.map(function (err) {
              return err.detail;
            }).join('<br/>');
            this.get('toast').error(errorMessages, err.name);
            return err;
          }
          return this._super.apply(this, arguments);
      }
    },
    // Sanitize/convert unsupported params to supported query actions.
    query: function query(store, type, _query) {
      var newQuery = Ember.copy(_query, true);

      // set the query.$limit
      if (!Ember.isEmpty(_query.pageSize)) {
        newQuery.$limit = _query.pageSize;
        delete newQuery.pageSize;
      }

      // set the query.$skip
      if (!Ember.isEmpty(_query.page)) {
        newQuery.$skip = (newQuery.page - 1) * newQuery.$limit;
        delete newQuery.page;
      }

      // Set the query.$sort.
      // The 'sort' property overrides the supported($sort).
      var sortDirectionMap = {
        ASC: 1,
        DESC: -1,
        NONE: null
      };
      if (!Ember.isEmpty(_query.sort)) {
        newQuery.$sort = newQuery.sort;
        delete newQuery.sort;
      }

      // convert non-numerical sort directions to values.
      if (Ember.typeOf(newQuery.$sort) === 'object') {
        Object.keys(newQuery.$sort).forEach(function (key) {
          var val = newQuery.$sort[key];
          if (['ASC', 'DESC', 'asc', 'desc'].indexOf(val) !== -1) {
            newQuery.$sort[key] = sortDirectionMap[val.toUpperCase()];
          } else if (isNaN(val)) {
            delete newQuery.$sort[key];
          }
        });
      }

      // Check all known unsupported query properties even if they've been handled above.
      var unsupported = ['page', 'pageSize', 'sort'];
      unsupported.forEach(function (unsupportedQuery) {
        if (Ember.isNone(newQuery[unsupportedQuery]) || Ember.isEmpty(newQuery[unsupportedQuery])) {
          return;
        }
        Ember.warn("An unknown service query parameter has not been sanitized and may cause errors: \"".concat(unsupportedQuery, "\""));
      });

      // Check for existence of include, change to $populate
      if (!Ember.isEmpty(newQuery.include)) {
        newQuery.$populate = newQuery.include;
        delete newQuery.include;
      }
      return this._super(store, type, newQuery);
    },
    /**
     Called by the store when an existing record is saved
     via the `save` method on a model record instance.
     The `updateRecord` method serializes the record and makes an Ajax (HTTP PUT) request
     to a URL computed by `buildURL`.
     See `serialize` for information on how to customize the serialized form
     of a record.
      This is a direct copy of the original implementation. The difference here is that
     this is now a PATCH instead of a PUT. Removing the "bonus" work of fancy requests.
      @method updateRecord
     @param {DS.Store} store
     @param {DS.Model} type
     @param {DS.Snapshot} snapshot
     @return {Promise} promise
     */
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var submittedData = {};
      var modelName = Ember.String.camelize(type.modelName);
      var serializer = store.serializerFor(modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var id = snapshot.id;
      var url = this.buildURL(modelName, id, snapshot, 'updateRecord');

      // Use data directly because serializeIntoHash gives the object directly differently for feathers.
      var dataKeys = Object.keys(data);

      // Ensure code only sends the data we are actually updating on a record.
      dataKeys.forEach(function (key) {
        if (key in snapshot.changedAttributes()) {
          submittedData[key] = data[key];
        }
      });
      return this.ajax(url, 'PATCH', {
        data: submittedData
      });
    },
    /**
     * Override of buildQuery.  Conform to our current adapter API by passing
     * "include" as "$populate" for joined models.  This method is called by
     * `store.findRecord` and `store.findAll`.
     *
     * https://github.com/emberjs/data/blob/v2.12.0/addon/adapters/rest.js#L1203
     * @param {*} snapshot
     */
    buildQuery: function buildQuery(snapshot) {
      var query = this._super(snapshot);
      if (!Ember.isEmpty(query.include)) {
        var newQuery = Object.assign({}, query);
        newQuery.$populate = query.include;
        delete newQuery.include;
        return newQuery;
      }
      return query;
    }
  });
  /**
   * Adapter methods to deal with errors returned from feathers.js
   * See docs for more info - https://docs.feathersjs.com/middleware/error-handling.html
   *
   * @param status
   * @param headers
   * @param payload - the error payload object from feathers.js
   *        payload.name - The error name (ie. "BadRequest", "ValidationError", etc.)
   *        payload.message - The error message string
   *        payload.code - The HTTP status code
   *        payload.className - A CSS class name that can be handy for styling errors based on the error type. (ie. "bad-request" , etc.)
   *        payload.data - An object containing anything you passed to a Feathers error except for the errors object.
   *        payload.errors - An object containing whatever was passed to a Feathers error inside errors. This is typically validation errors or if you want to group multiple errors together.
   * @returns {DS.ServerError}
   */
  function feathersErrorResponse(status, headers, payload) {
    var errors = [{
      detail: payload.message,
      source: {
        pointer: '/data'
      }
    }];
    if (payload.errors && payload.errors.length > 0) {
      errors = errors.concat(payload.errors);
    }
    errors.name = payload.name || null;

    //TODO: use DS.InvalidError or DS.ServerError?
    return new _emberData.default.InvalidError(errors);
  }
});
