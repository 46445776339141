define("tpf-fund-manager/components/document-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    toast: Ember.inject.service('session'),
    store: Ember.inject.service(),
    actions: {
      dirtyAttribute: function dirtyAttribute() {
        this.set('isFormDirty', true);
      },
      saveDocumentChecklist: function saveDocumentChecklist(clientRequiredDocuments, refreshRouteFunc) {
        var _this = this;
        clientRequiredDocuments.save().then(function (response) {
          _this.set('isFormDirty', false);
          _this.set('clientRequiredDocuments', response);
          refreshRouteFunc();
        });
      }
    }
  });
});
