define("tpf-fund-manager/components/client-contract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    initFundingSource: function initFundingSource() {
      if (!this.get('fundingSources')) {
        return;
      }
      this.set('selectedFundingSource', this.get('fundingSources').findBy('uuid', this.get('clientContract.fundingSourceUuid')));
    },
    isFrozenField: Ember.computed('clientContract.productType', 'isEditingContracts', 'isClientDebtor', function () {
      if (!this.get('isEditingContracts')) {
        /**
         * If we're not editing then yes the field is disabled
         */
        return true;
      }
      if (this.get('isClientDebtor') && this.get('clientContract.productType') === 'FACTORING') {
        /**
         * If we're editing a c/d factoring contract then the fields this
         * applies to should be set when we're setting up the client/debtor
         */
        return true;
      }
      return false;
    }),
    /**
     * RECEIVABLES FINANCE has two client debtor level contract values we care about right now.
     * Since we use this form in 3 other places :
     * client level [ RF AND FACTORING]
     * client debtor level [FACTORING]
     * we can handle this with a check:
     * See : TPF-5659 for more info
     */
    showRFClientDebtorForm: Ember.computed('clientContract.productType', 'isClientDebtor', function () {
      // show if we are editing client debtor information for an RF client.
      return this.get('isClientDebtor') && this.get('clientContract.productType') === 'RECEIVABLES_FINANCE';
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.initFundingSource();
      this.maturityIntervalOptions = Ember.A(['Days', 'Months', 'Years']);
      this.collateralOptions = Ember.A(['Receivables', 'Inventory', 'Other']);
      this.rateTypeOptions = Ember.A(['FIXED', 'PRIME_PLUS', 'LIBOR_PLUS', 'SOFR_PLUS']);
      this.rateChangeFrequencyOptions = Ember.A(['Annually', 'Monthly', 'Quarterly']);
      this.productSubtypes = Ember.A(['LEDGERED_ABL', 'FULLY_FOLLOWED_ABL', 'TERM_LOAN', 'TERM_LOAN__TOTAL_DEBT_RESTRUCTURE', 'INVENTORY', 'RECEIVABLES_FINANCE']);
    },
    isContractCollateralTypeOther: Ember.computed('clientContract.collateral', {
      get: function get() {
        return this.get('clientContract.collateral') === 'Other';
      }
    }),
    contractHasEffectiveDate: Ember.computed('clientContract.effectiveDate', {
      get: function get() {
        return !Ember.isEmpty(this.get('clientContract.effectiveDate'));
      }
    }),
    actions: {
      selectFundingSource: function selectFundingSource(fundingSource) {
        this.set('clientContract.fundingSourceUuid', fundingSource.get('uuid'));
        this.set('clientContract.fundingSource', fundingSource);
        return fundingSource;
      },
      _saveClientContract: function _saveClientContract(contract) {
        var _this = this;
        this.set('isSavingContract', true);
        return this.get('clientContractSaveAction')(contract).finally(function () {
          _this.set('showSaveConfirmModal', false);
          _this.set('isSavingContract', false);
        });
      },
      setClientDebtorContractInvalid: function setClientDebtorContractInvalid(isClientDebtorContractInvalid) {
        this.set('isClientDebtorContractInvalid', isClientDebtorContractInvalid);
        return isClientDebtorContractInvalid;
      }
    }
  });
});
