define("tpf-fund-manager/routes/capfin/review-receipt/show/success", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      var receiptUploadUuid = this.paramsFor('capfin.review-receipt.show').stagedReceiptUploadUuid;
      return this.get('store').findRecord('staged-receipt-upload', receiptUploadUuid).then(function (receipt) {
        return Ember.RSVP.hash({
          receipt: receipt
        });
      });
    },
    afterModel: function afterModel(model) {
      // Make sure that receipt is in appropriate status to be showing success screen for.
      if (model.receipt.get('status') !== 'CLOSED') {
        window.console.log('Bypassing complete check for success block. Change when APIs are in place');
        //this.transitionTo('capfin.all-receipts.uploads.index');
      }
    },
    actions: {
      onConfirmSuccess: function onConfirmSuccess() {
        this.transitionTo('capfin.all-receipts.uploads.index');
      }
    }
  });
});
