define("tpf-fund-manager/services/filter-state", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var filterStoreName = 'filterStore';
  var _default = _exports.default = Ember.Service.extend({
    filterStore: (0, _emberLocalStorage.storageFor)('filter-store'),
    currentRouteName: '',
    // set in all routes' beforeModel()
    /**
     * Returns the filter field values for the given query parameter.
     * @param {*} filterModel The model to namespace the sort fields under,
     * specified as the underscore-delimited route + field, e.g. index_general|showCompleted.
     * @param {*} filterKey The query parameter holding filter values.
     * @returns {*} The query values for the field.
     */
    getParam: function getParam(filterModel, filterKey) {
      var storeKey = this._buildStoreKey(filterModel);
      var filterFields = this.get("".concat(filterStoreName, ".").concat(storeKey)) || this.set("".concat(filterStoreName, ".").concat(storeKey), {});
      return filterFields && filterFields[filterKey] || [];
    },
    /**
     * Sets a sort field for the given model with the provided values.
     * @param {*} filterModel The model to namespace the sort fields under,
     * specified as the underscore-delimited route + field, e.g. index_general|showCompleted.
     * @param {*} filterKey The query parameter to store filter values under.
     * @param {*} filters The object containing query filters to be set.
     * @returns {*} The query values for the field.
     */
    setParam: function setParam(filterModel, filterKey, filters) {
      var storeKey = this._buildStoreKey(filterModel);
      var filterFields = this.get("".concat(filterStoreName, ".").concat(storeKey)) || this.set("".concat(filterStoreName, ".").concat(storeKey), {});
      if (Ember.isEmpty(filters)) {
        delete filterFields[filterKey];
      } else {
        filterFields[filterKey] = filters;
      }
      this.set("".concat(filterStoreName, ".").concat(storeKey), filterFields);
      return filters;
    },
    /**
     * Returns the filter parameters for the provided model.
     * @param {*} filterModel The model to namespace the sort fields under,
     * specified as the underscore-delimited route + field, e.g. index_general|showCompleted.
     * @returns {Object} A set of k:v pairs with all existing filters
     * for the model.
     */
    getParams: function getParams(filterModel) {
      var storeKey = this._buildStoreKey(filterModel);
      if (storeKey == "|".concat(filterModel)) {
        return {};
      }
      var modelParams = this.get("".concat(filterStoreName, ".").concat(storeKey));
      return modelParams || {};
    },
    /**
     * Remove filters for the provided model and key.
     * @param {*} filterModel The model to namespace the sort fields under,
     * specified as the underscore-delimited route + field, e.g. index_general|showCompleted.
     * @param {*} filterKey The query parameter to store filter values under.
     */
    deleteParam: function deleteParam(filterModel, filterKey) {
      var storeKey = this._buildStoreKey(filterModel);
      var filterFields = this.get("".concat(filterStoreName, ".").concat(storeKey)) || this.set("".concat(filterStoreName, ".").concat(storeKey), {});
      delete filterFields[filterKey];
      return this.set("".concat(filterStoreName, ".").concat(storeKey), filterFields);
    },
    /**
     * Clear all filter parameters for the given model+route.
     * @param {*} filterModel The model to namespace the sort fields under
     */
    clearParams: function clearParams(filterModel) {
      var storeKey = this._buildStoreKey(filterModel);
      return this.set("".concat(filterStoreName, ".").concat(storeKey), {});
    },
    _buildStoreKey: function _buildStoreKey(filterModel) {
      var currentRouteName = this.get('currentRouteName').replace(/\./g, '_');
      return "".concat(currentRouteName, "|").concat(filterModel);
    }
  });
});
