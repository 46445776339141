define("tpf-fund-manager/components/advance-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    totalAdvanceCount: Ember.computed('advanceView', function () {
      return this.get('advanceView.length');
    }),
    isExpanded: true,
    totalAdvanceAmount: Ember.computed('advanceView.@each.advanceAmount', function () {
      var sum = 0;
      this.get('advanceView').forEach(function (advance) {
        sum += parseInt(advance.get('advanceAmount'));
      });
      return sum;
    }),
    actions: {
      updateStatus: function updateStatus(uuid, status) {
        var _this = this;
        // If the status is change to any thing other than SENT it should
        // null out the advance date.
        var advanceDate = status === 'SENT' ? this.get('advanceDate') : null;
        return this.get('updateStatus')(uuid, status, {
          advanceDate: advanceDate
        }).then(function () {
          _this.get('advanceView').update();
        });
      },
      updateSoldDate: function updateSoldDate(uuid, soldDate) {
        var _this2 = this;
        return this.get('updateSoldDate')(uuid, soldDate).then(function () {
          _this2.get('advanceView').update();
          _this2.set('showEditAdvanceSoldDateDialog', false);
        });
      },
      showEditAdvanceSoldDateModal: function showEditAdvanceSoldDateModal(advance) {
        this.set('currentAdvance', advance);
        this.set('showEditAdvanceSoldDateDialog', true);
      },
      markAsSent: function markAsSent() {
        var _this3 = this;
        var advance = this.get('selectedAdvance');

        // TODO: remove this after cutover to new advance creation is fully supported.
        // in the future we only want to use the existing advance's payment method.
        // putting in a hard check here to ensure we never send transferMethod EXTERNAL
        // to the API when marking SENT.
        var transferMethod = this.get('transferMethod') ? this.get('transferMethod') : this.get('selectedAdvance.paymentMethod');
        if (transferMethod === 'EXTERNAL') {
          this.get('toast').error('Tried to mark an advance to SENT with payment type of EXTERNAL');
          return;
        }
        var opts = {
          advanceDate: this.get('advanceDate'),
          transferMethod: transferMethod,
          transactionFee: this.get('transactionFee')
        };
        return this.get('updateStatus')(advance.get('uuid'), 'SENT', opts).then(function () {
          _this3.get('advanceView').update();
          _this3.set('showConfirmMarkSendDialog', false);
        });
      },
      updateInvoiceSentStatus: function updateInvoiceSentStatus(advance) {
        var _this4 = this;
        this.get('store').findRecord('client', advance.get('clientUuid')).then(function (client) {
          // TODO remove this conditional after cutover to new advance creation is complete
          // (no pending old-style created advances).
          // the "else" should go away when this is the case.
          var transferMethod;
          if (advance.get('paymentMethod') && advance.get('paymentMethod') !== 'EXTERNAL') {
            transferMethod = advance.get('paymentMethod');
          } else {
            transferMethod = client.get('transferMethodPreference');
          }
          _this4.set('transferMethod', transferMethod);
          _this4.set('transactionFee', transferMethod === 'WIRE' ? client.get('wireFee') : 0);
          _this4.set('showConfirmMarkSendDialog', true);
          _this4.set('selectedAdvance', advance);
        });
      },
      showEditAdvanceModal: function showEditAdvanceModal(currentAdvance) {
        var _this5 = this;
        this.get('store').findRecord('advance', currentAdvance.get('uuid')).then(function (advance) {
          _this5.set('currentAdvance', advance);
          _this5.set('showEditAdvanceDialog', true);
        });
      },
      saveEditedAdvance: function saveEditedAdvance() {
        var _this6 = this;
        this.get('currentAdvance').save().then(function () {
          _this6.get('advanceView').update();
          _this6.set('showEditAdvanceDialog', false);
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.transferMethods = ['WIRE', 'ACH'];
      this.advanceDate = new Date();
      this.soldDate = new Date();
      this.transferMethod = null;
      this.transactionFee = null;
      this.selectedAdvance = null;
      this.advanceTypes = ['CLIENT_ADVANCE', 'PAYOFF', 'CARD_ADVANCE', 'REIMBURSABLE'];
    }
  });
});
