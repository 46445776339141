define("tpf-fund-manager/components/adjustment-grid", ["exports", "moment", "tpf-fund-manager/mixins/active-client-search-mixin", "tpf-fund-manager/fixtures/client-balance-adjustment-subtypes"], function (_exports, _moment, _activeClientSearchMixin, _clientBalanceAdjustmentSubtypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_activeClientSearchMixin.default, {
    customApiRoutes: Ember.inject.service('custom-api-routes'),
    toast: Ember.inject.service('toast'),
    init: function init() {
      this._super.apply(this, arguments);
      this.balanceTypes = ['OUTSTANDING', 'AR'];
      this.adjustmentSubtypes = _clientBalanceAdjustmentSubtypes.default;
    },
    actions: {
      createNewAdjustment: function createNewAdjustment() {
        this.set('selectedClient', null);
        var newAdjustment = this.get('createAdjustment')();
        this.set('currentAdjustment', newAdjustment);
        this.set('showAddAdjustmentDialog', true);
      },
      confirmInterestRecalc: function confirmInterestRecalc() {
        var monthToRecalc = (0, _moment.default)().subtract(1, 'month').startOf('month').format('YYYY-MM');
        var adjustmentDate = (0, _moment.default)().startOf('month').format('YYYY-MM-DD');
        this.set('monthToRecalc', monthToRecalc);
        this.set('effectiveAdjustmentDate', adjustmentDate);
        this.set('showRecalcInterestDialog', true);
      },
      recalcInterest: function recalcInterest() {
        var _this = this;
        this.set('isCalcingInterest', true);
        this.get('customApiRoutes').recalcRfInterest().then(function (resp) {
          _this.set('isCalcingInterest', false);
          _this.set('showRecalcInterestDialog', false);
          _this.set('newAdjustments', resp);
          _this.set('showRecalcSummaryDialog', true);
        }).catch(function (errText) {
          var toastMsg = 'Error recalculating interest';
          if (errText) {
            toastMsg = "".concat(toastMsg, ": ").concat(errText);
          }
          _this.get('toast').error(toastMsg);
        }).finally(function () {
          _this.set('isCalcingInterest', false);
          _this.set('showRecalcInterestDialog', false);
        });
      },
      cancelAdjustment: function cancelAdjustment() {
        var currentAdjustment = this.get('currentAdjustment');
        if (currentAdjustment) {
          currentAdjustment.rollbackAttributes();
        }
        this.set('showAddAdjustmentDialog', false);
      },
      saveAdjustment: function saveAdjustment(currentAdjustment) {
        var _this2 = this;
        currentAdjustment.set('clientUuid', this.get('selectedClient.uuid'));
        currentAdjustment.save().then(function () {
          _this2.set('showAddAdjustmentDialog', false);
          _this2.get('ClientAdjustments').update();
        });
      },
      editAdjustment: function editAdjustment(adjustment) {
        var _this3 = this;
        this.get('lookupClientByUuid').bind(this)(adjustment.get('clientUuid')).then(function (selectedClient) {
          _this3.set('selectedClient', selectedClient);
          _this3.set('currentAdjustment', adjustment);
          _this3.set('showAddAdjustmentDialog', true);
        });
      }
    }
  });
});
