define("tpf-fund-manager/routes/markets/show/buyer-toggle/show/onboarding-answers", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      var _this = this;
      var buyerToggleClient = this.modelFor('markets.show.buyer-toggle.show').buyerToggleClient;
      var divisionUuid = buyerToggleClient.get('divisionUuid');
      return Ember.RSVP.hash({
        onboardingForm: this.get('store').query('onboarding-form', {
          divisionUuid: divisionUuid
        })
      }).then(function (data) {
        var uuid = data.onboardingForm.get('industryTypeUuid');
        return Ember.RSVP.hash({
          onboardingForm: data.onboardingForm,
          industryType: _this.get('store').query('onboarding-industry-group', {
            uuid: uuid
          })
        });
      }).then(function (data) {
        var onboardingForm = data.onboardingForm.get('firstObject');
        var industryType = data.industryType.get('firstObject');
        return {
          onboardingForm: onboardingForm,
          industryType: industryType
        };
      });
    }
  });
});
