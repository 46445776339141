define("tpf-fund-manager/serializers/tpf-import-upload-error", ["exports", "tpf-fund-manager/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload.data) {
        return this._super(store, primaryModelClass, payload, id, requestType);
      }
      var convertedPayload = Object.assign({}, payload);
      convertedPayload.data = convertedPayload.data.map(function (item, idx) {
        var data = Object.assign({}, item);
        data.id = "".concat(data.loadId, "_").concat(idx);
        return data;
      });
      return this._super(store, primaryModelClass, convertedPayload, id, requestType);
    }
  });
});
