define("tpf-fund-manager/helpers/receipt-upload-summary-show-review", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.receiptUploadSummaryShowReview = receiptUploadSummaryShowReview;
  var showOn = ['IMPORTED', 'LINKED', 'CHANGED', 'APPROVED', 'COMMITTED', 'REJECTED'];
  function receiptUploadSummaryShowReview(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      status = _ref2[0],
      pending = _ref2[1];
    return showOn.includes(status) && !pending;
  }
  var _default = _exports.default = Ember.Helper.helper(receiptUploadSummaryShowReview);
});
