define("tpf-fund-manager/services/custom-api-routes", ["exports", "tpf-fund-manager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Service to consolidate api route access to endpoints in the ktor
   * api that do not conform to the usual ember model behavior.
   *
   * Expectation:
   * All functions calling an API return a promise that resolves/rejects accordingly
   * based on API response.
   */
  var _default = _exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    /**
     * (Re)calculate interest charges for the previous month based on today's date.
     * @returns - list of 'client-adjustment' models.
     */
    recalcRfInterest: function recalcRfInterest() {
      var sessionToken = this.get('session').get('data.authenticated.token');
      var store = this.get('store');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var request = Ember.$.ajax({
          url: "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/client-adjustments/calc-rf-interest"),
          type: 'PUT',
          contentType: 'application/json',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', "Bearer ".concat(sessionToken));
          }
        });
        request.done(function (data) {
          var storeMappedData = data.map(function (item) {
            // normalize returns the object type expected for pushing to the store,
            // including metadata.
            // As we are using it to simply translate json to an ember model attributes,
            // just grab the attributes off the normalized json list item.
            return store.normalize('client-adjustment', item).data.attributes;
          });
          resolve(storeMappedData);
        });
        request.fail(function (jqXHR, textStatus, errorThrown) {
          reject(errorThrown);
        });
      });
    }
  });
});
