define("tpf-fund-manager/helpers/on-index", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.onIndex = onIndex;
  // display an arbitrary template-string every N number of items.
  function onIndex(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 4),
      index = _ref2[0],
      everyCount = _ref2[1],
      max = _ref2[2],
      template = _ref2[3];
    if (index % everyCount == 0 && index !== max && index > 0 && everyCount != 1) {
      return Ember.String.htmlSafe(template);
    }
    return '';
  }
  var _default = _exports.default = Ember.Helper.helper(onIndex);
});
