define("tpf-fund-manager/routes/capfin/receivables-finance/advances/index/pending", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      var fundingSources = this.modelFor('capfin.receivables-finance.advances.index').fundingSources;
      var model = this.getPreviousFilters({
        advanceView: {
          _modelName: 'advance-view',
          'status[$in]': ['PENDING', 'AUTHORIZED'],
          pageSize: 25
        }
      });
      model.fundingSources = fundingSources;
      return Ember.RSVP.hash(model);
    }
  });
});
