define("tpf-fund-manager/components/materialize-card-action", ["exports", "tpf-fund-manager/components/md-card-action"], function (_exports, _mdCardAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mdCardAction.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      Ember.deprecate("{{materialize-card-action}} has been deprecated. Please use {{md-card-action}} instead", false, {
        url: "https://github.com/sgasser/ember-cli-materialize/issues/67"
      });
    }
  });
});
