define("tpf-fund-manager/controllers/capfin/factoring/settlements/index/unsettled/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    creditUtilizationChange: null,
    roundedCreditUtilizationChange: null,
    roundedInterimCreditUtilization: null,
    settlementStatus: null,
    actions: {
      onPurchasesSelectedSumChanged: function onPurchasesSelectedSumChanged(sum) {
        var creditLimit = this.get('model.balance.creditLimit');
        var creditUtilizationChange = creditLimit ? sum / creditLimit * 100 : 0;
        var interimCreditUtilization = this.get('model.balance.creditUtilizationPercent') + creditUtilizationChange;
        this.set('creditUtilizationChange', creditUtilizationChange);
        this.set('roundedCreditUtilizationChange', creditUtilizationChange.toFixed(2));
        this.set('roundedInterimCreditUtilization', interimCreditUtilization.toFixed(2));
      }
    }
  });
});
