define("tpf-fund-manager/routes/capfin/review-receipt/show/debtor-remittance/show/allocations", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      var _this = this;
      var store = this.get('store');
      var receiptUploadUuid = this.paramsFor('capfin.review-receipt.show').stagedReceiptUploadUuid;
      var stagedDebtorRemittanceUuid = this.paramsFor('capfin.review-receipt.show.debtor-remittance.show').stagedDebtorRemittanceUuid;
      return store.query('staged-debtor-remittance-allocation', {
        stagedReceiptUploadUuid: receiptUploadUuid,
        stagedDebtorRemittanceUuid: stagedDebtorRemittanceUuid,
        'riskClassification[$any]': ['SEVERELY_UNDERPAID']
      }).then(function (allocations) {
        return store.findRecord('staged-debtor-remittance-classification-summary', stagedDebtorRemittanceUuid, {
          preload: {
            stagedReceiptUploadUuid: receiptUploadUuid
          }
        }).then(function (classificationSummary) {
          return Ember.RSVP.hash({
            allocations: allocations,
            // this wasn't populated when coming from remittance list page -> review (re-querying it)
            remittanceSummary: _this.get('store').findRecord('staged-debtor-remittance', stagedDebtorRemittanceUuid, {
              preload: {
                stagedReceiptUploadUuid: receiptUploadUuid
              }
            }),
            classificationSummary: classificationSummary
          });
        });
      });
    },
    actions: {
      onCancelReceipt: function onCancelReceipt(stagedDebtorRemittanceSummary) {
        var _this2 = this;
        var controller = this.controllerFor(this.routeName);
        controller.set('isRejecting', true);
        stagedDebtorRemittanceSummary.set('status', 'REJECTED');
        return stagedDebtorRemittanceSummary.save().then(function () {
          _this2.transitionTo('capfin.review-receipt.show.summary', stagedDebtorRemittanceSummary.get('stagedReceiptUploadUuid')).then(function () {
            controller.set('isRejecting', false);
          });
        }).finally(function () {
          controller.set('isRejecting', false);
        });
      },
      onApproveReceipt: function onApproveReceipt(stagedDebtorRemittanceSummary) {
        this.transitionTo('capfin.review-receipt.show.summary', stagedDebtorRemittanceSummary.get('stagedReceiptUploadUuid'));
      },
      onSubmitReceipt: function onSubmitReceipt(stagedDebtorRemittanceSummary) {
        var _this3 = this;
        //TODO: if this is the last one being submitted, show success
        // Presently routing back to summary page
        //this.transitionTo('capfin.review-receipt.index');
        var controller = this.controllerFor(this.routeName);
        controller.set('isSubmittingReceipt', true);
        return stagedDebtorRemittanceSummary.commit().then(function () {
          _this3.get('store').findRecord('staged-receipt-upload', stagedDebtorRemittanceSummary.get('stagedReceiptUploadUuid')).then(function (receipt) {
            if (receipt.get('pendingReviewCount') === 0) {
              _this3.transitionTo('capfin.review-receipt.show.success', {
                stagedReceiptUploadUuid: stagedDebtorRemittanceSummary.get('stagedReceiptUploadUuid')
              });
            } else {
              _this3.transitionTo('capfin.review-receipt.show.summary', stagedDebtorRemittanceSummary.get('stagedReceiptUploadUuid')).then(function () {
                controller.set('isSubmittingReceipt', false);
              });
            }
          });
        }).finally(function () {
          controller.set('isSubmittingReceipt', false);
        });
      },
      toggleRecievedFromClient: function toggleRecievedFromClient(stagedDebtorRemittanceSummary) {
        stagedDebtorRemittanceSummary.set('isClientRemitted', !stagedDebtorRemittanceSummary.get('isClientRemitted'));
        stagedDebtorRemittanceSummary.save({
          adapterOptions: {
            status: stagedDebtorRemittanceSummary.get('status')
          }
        });
      }
    }
  });
});
