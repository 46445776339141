define("tpf-fund-manager/routes/capfin/receivables-finance/invoices/index/sent", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash(this.getPreviousFilters({
        sentInvoices: {
          _modelName: 'invoice',
          'status[$in]': ['SENT', 'PARTIALLY_PAID'],
          productType: 'RECEIVABLES_FINANCE',
          sort: {
            dueDate: 'DESC'
          },
          pageSize: 25
        }
      }));
    }
  });
});
