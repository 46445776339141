define("tpf-fund-manager/routes/capfin/clients/show/status", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    codifiedList: Ember.inject.service(),
    formQuestions: Ember.inject.service(),
    model: function model() {
      var _this = this;
      var client = this.modelFor('capfin.clients.show').client;
      var divisionUuid = client.get('divisionUuid');
      var uuid = client.get('uuid');
      return Ember.RSVP.hash({
        clientRequiredDocuments: this.get('store').findRecord('client-required-document', uuid),
        onboardingStatus: this.get('store').findRecord('onboarding-status', divisionUuid),
        client: this.get('store').query('client', {
          divisionUuid: divisionUuid
        }),
        onboardingFormNonAggregated: this.get('store').query('onboarding-form', {
          divisionUuid: divisionUuid
        }),
        industryGroups: this.get('codifiedList').getIndustryGroups(),
        formationStructures: this.get('codifiedList').getFormationStructures(),
        additionalQuestions: this.get('formQuestions').getOfficersInfoAdditionalQuestions()
      }).then(function (model) {
        var clientRequiredDocuments = model.clientRequiredDocuments;
        var onboardingStatus = model.onboardingStatus;
        var client = model.client.get('firstObject');
        var onboardingForm = model.onboardingFormNonAggregated.get('firstObject') ? _this._mapRelatedDisplayText(model.onboardingFormNonAggregated.get('firstObject'), model.industryGroups, model.formationStructures, model.additionalQuestions) : undefined;
        return {
          clientRequiredDocuments: clientRequiredDocuments,
          onboardingStatus: onboardingStatus,
          client: client,
          onboardingForm: onboardingForm
        };
      });
    },
    // from risk/show, which is being deprecated.
    _mapRelatedDisplayText: function _mapRelatedDisplayText(form, industryGroups, formationStructures, additionalQuestions) {
      // tag on industryGroupName for industryGroupUuid
      var industryGroup = industryGroups.find(function (i) {
        return i.uuid === form.get('industryTypeUuid');
      });
      if (industryGroup) {
        form.set('industryGroupName', industryGroup.groupDisplayName);
      }

      // tag on formation structure name to form based on entityType key
      var formationStructureName;
      if (form.get('entityType') === 'OTHER') {
        formationStructureName = form.get('entityTypeOther');
      } else {
        var structure = formationStructures.find(function (f) {
          return f.value === form.get('entityType');
        });
        formationStructureName = structure && structure.display;
      }
      form.set('formationStructureName', formationStructureName);

      // tag on question text to all answers.
      var answers = form.get('additionalQuestionAnswers').map(function (answer) {
        var matchedQuestion = additionalQuestions.find(function (q) {
          return q.get('uuid') === answer.get('onboardingAdditionalQuestionUuid');
        });
        answer.set('questionText', matchedQuestion.get('questionText'));
        return answer;
      });
      form.set('additionalQuestionAnswers', answers);
      return form;
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    }
  });
});
