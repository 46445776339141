define("tpf-fund-manager/services/address-metadata", ["exports", "tpf-fund-manager/fixtures/country-info-iso", "tpf-fund-manager/config/environment"], function (_exports, _countryInfoIso, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    // Gets the meta data for the selected country code.
    // If the selected country code has no format, it will default
    // to the format of the `ZZ` country code recommended by Google
    getMetaData: function getMetaData(countryCode) {
      var response = this._getMetaDataByCountryCode(countryCode);

      // Default to using the 'ZZ' if the gotten country info has no format
      if (response.fmt === undefined) {
        var zzResponse = this._getMetaDataByCountryCode('ZZ');
        response.fmt = zzResponse.fmt;
      }
      return response;
    },
    // Private method used to get the country code data from the API
    _getMetaDataByCountryCode: function _getMetaDataByCountryCode(countryCode) {
      var session = this.get('session');
      var authToken = session.get('data.authenticated.token');
      return JSON.parse(Ember.$.ajax({
        url: "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/address-metadata/").concat(countryCode, "/"),
        type: 'GET',
        contentType: 'application/json',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', "Bearer ".concat(authToken));
        },
        async: false
      }).responseText);
    },
    /**
     * Returns all country codes as list of country maps
     *
     * @returns Array[Map] -
     *  Map - {
     *             name: "United States",
     *             alpha2: "US"
     *         }
     *  The map has more values than shown, however, those are the only two
     *  values that currently matter.
     */
    getCountryCodes: function getCountryCodes() {
      return _countryInfoIso.default;
    },
    /**
     * Helper for getting form options for country codes
     */
    getCountryCodeOptions: function getCountryCodeOptions() {
      if (!this._countryCodeOptions) {
        this._countryCodeOptions = this.getCountryCodes().map(function (cc) {
          return {
            code: cc.alpha2,
            name: cc.name,
            display: "".concat(cc.alpha2, " (").concat(cc.name, ")")
          };
        });
      }
      return this._countryCodeOptions;
    }
  });
});
