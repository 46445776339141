define("tpf-fund-manager/routes/capfin/admin/reference-rates/show", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model(params) {
      var referenceRateUuid = params.reference_rate_uuid;
      return Ember.RSVP.hash({
        tpfReferenceRate: this.get('store').findRecord('reference-rate', referenceRateUuid, {
          reload: true
        })
      });
    },
    actions: {
      saveReferenceRate: function saveReferenceRate(tpfReferenceRate) {
        var _this = this;
        return tpfReferenceRate.save().then(function () {
          _this.transitionTo('capfin.admin.reference-rates');
        });
      },
      cancelReferenceRate: function cancelReferenceRate(tpfReferenceRate) {
        tpfReferenceRate.rollbackAttributes();
        this.transitionTo('capfin.admin.reference-rates');
      }
    }
  });
});
