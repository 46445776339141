define("tpf-fund-manager/components/material-modal-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Material Modal Form - Boilerplate for creating a form submission modal with
   * material styles that is triggered by a button.  It provides the form element and
   * submit/cancel in the modal footer.  Example usage:
   * 
   * {{#material-modal-form 
   *      submitText="Submit!"
   *      cancelText="Cancel"
   *      formTitle="Create An Item"
   *      formSubtitle="Create a sweet item.  Be sure to add all the data!"
   *      submitAction=(action 'createItem' myItem)
   *      buttonText='Add Invoice'
   *      buttonClasses='right'}}
   *          <div class="row">
   *              <div class="col s6">
   *                  <label for="field1">Datas:</label>
   *                  {{input class="input" id="field1" value=myItem.field1 required=true}}
   *              </div>
   *              <div class="col s6">
   *                  <label for="field2">More Datas:</label>
   *                  {{input class="input" id="field2" value=myItem.field2 required=true}}
   *              </div>
   *          </div>
   *          <div class="row">
   *              {{!-- more inputs... --}}
   *          </div>
   *  {{/material-modal-form}}
   *
   * It takes the following params:
   *
   * @required buttonText || iconName - one of these must be provided to get a trigger
   *                                    iconName takes precedence over the buttonText.
   * @required submitAction           - action to be called on clicking the 'Submit' button
   *                                    for the form.  
   *
   * @param iconName                 - Makes the modal trigger be an icon with that name.
   * @param submitAction             - Action to use for handling submission of form within the modal.
   *                                   Must return a promise that resolves true on successful submission
   *                                   in order to close the modal.  Falsey value will leave the modal
   *                                   open.
   * @param buttonText               - The text to appear on the modal open button.
   * @param iconSize      (optional) - Set the size of the icon that triggers the modal
   * @param submitText    (optional) - the text to show on the form submit button.
   * @param cancelText    (optional) - text to show for cancel action of the modal.
   * @param isDisabled    (optional) - Boolean value for whether the modal-triggering button should be disabled.
   * @param buttonClasses (optional) - Additional CSS styles to use on the modal-triggering button.
  
   */
  var _default = _exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('uuid', Ember.guidFor(this));
    },
    cancelText: 'Cancel',
    submitText: 'Submit',
    buttonClasses: '',
    isDisabled: null,
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.$('.modal').modal) {
        this.$('.modal').modal();
      }
    },
    closeModal: function closeModal() {
      this.$("#".concat(this.get('uuid'))).modal('close');
    },
    actions: {
      onSubmit: function onSubmit() {
        var _this = this;
        var submitAction = this.get('submitAction');
        if (submitAction) {
          submitAction().then(function (shouldClose) {
            if (shouldClose) {
              _this.closeModal();
            }
          }).catch(function () {
            // defer submit errors to unique implementations.
            return;
          });
        }
      }
    }
  });
});
