define("tpf-fund-manager/components/feathers-sort-clear", ["exports", "tpf-fund-manager/templates/components/feathers-sort-clear"], function (_exports, _feathersSortClear) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _feathersSortClear.default,
    sortService: Ember.inject.service('sort-state'),
    hasSort: Ember.computed('data.@each.isUpdating', {
      get: function get() {
        var sort = this.getWithDefault('data.query.sort', {});
        return Boolean(Object.keys(sort).length > 0);
      }
    }),
    clearSort: function clearSort() {
      var data = this.get('data');
      this.get('sortService').clear(data.modelName);
      if (Ember.get(data, 'query.sort')) {
        delete data.query.sort;
      }
    },
    actions: {
      clearSort: function clearSort() {
        var _this = this;
        var callClear = function callClear() {
          _this.clearSort();
          var data = _this.get('data');
          data.update();
        };
        Ember.run.debounce(this, callClear, 200);
      }
    }
  });
});
