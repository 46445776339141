define("tpf-fund-manager/components/charges-grid", ["exports", "tpf-fund-manager/mixins/active-client-search-mixin"], function (_exports, _activeClientSearchMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_activeClientSearchMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.chargeTypes = [];
    },
    createChargeTypes: Ember.computed('productType', function () {
      // factoring allows a few more options than RF on charges.
      if (this.get('productType') === 'FACTORING') {
        return ['FEE', 'PASS_THROUGH', 'RESERVE_FEE', 'RESERVE_RELEASE', 'PAYOFF', 'OTHER', 'ACCOUNT_CONSOLIDATION', 'OVER_ADVANCE', 'OVER_LINE', 'INVOICE_FEE', 'CLOSING_ACCOUNT_ADJUSTMENT', 'OFFSET', 'DISCOUNT'];
      } else {
        return ['INTEREST', 'FEE', 'PASS_THROUGH', 'INVOICE_FEE', 'CLOSING_ACCOUNT_ADJUSTMENT'];
      }
    }),
    actions: {
      updateChargeType: function updateChargeType(type) {
        this.set('currentCharge.type', type);
        this.set('currentCharge.subtype', null);
      },
      createNewCharge: function createNewCharge() {
        this.set('selectedClient', null);
        var newCharge = this.get('createCharge')();
        this.set('currentCharge', newCharge);
        this.set('showAddChargeDialog', true);
        this.set('chargeTypes', this.get('createChargeTypes'));
      },
      cancelCharge: function cancelCharge() {
        var currentCharge = this.get('currentCharge');
        if (currentCharge) {
          currentCharge.rollbackAttributes();
        }
        this.set('showAddChargeDialog', false);
      },
      saveCharge: function saveCharge(currentCharge) {
        var _this = this;
        currentCharge.set('clientUuid', this.get('selectedClient.uuid'));
        currentCharge.save().then(function () {
          _this.set('showAddChargeDialog', false);
          _this.get('ClientAdjustments').update();
        });
      },
      editCharge: function editCharge(charge) {
        var _this2 = this;
        this.set('chargeTypes', [charge.get('type')]);
        this.get('lookupClientByUuid').bind(this)(charge.get('clientUuid')).then(function (selectedClient) {
          _this2.set('selectedClient', selectedClient);
          _this2.set('currentCharge', charge);
          _this2.set('showAddChargeDialog', true);
        });
      }
    }
  });
});
