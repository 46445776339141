define("tpf-fund-manager/routes/capfin/receivables-finance/clients", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      var searchedClientName = '';
      if (this.controller) {
        searchedClientName = this.controller.get('clientName');
      }
      return Ember.RSVP.hash(this.getPreviousFilters({
        clients: {
          _modelName: 'client',
          sort: {
            outstandingBalance: 'DESC'
          },
          productType: 'RECEIVABLES_FINANCE',
          isClosed: false,
          $search: {
            clientName: searchedClientName
          }
        }
      }));
    }
  });
});
