define("tpf-fund-manager/components/client-contracts-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var REPLICABLE_CONTRACT_TYPES = Object.freeze(['RENEWAL', 'AMENDMENT']);
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.editableContract = {};
    },
    hasContracts: Ember.computed.notEmpty('existingContract'),
    /**
     * Gross copy of the contract model so we can just use object assign.
     */
    _setValuesForRenewalOrAdmendment: function _setValuesForRenewalOrAdmendment() {
      var contractValues = this.get('existingContract').getProperties('fundingSourceUuid', 'fundingSource', 'maturityLength', 'maturityInterval', 'collateral', 'collateralOther', 'guarantor', 'creditLimit', 'advanceRate', 'rate', 'rateType', 'interestRateChangeFrequency', 'invoiceFee', 'collectionDays', 'verifiedArThreshold', 'chargeLatePaymentFees');
      Object.assign(this.editableContract, contractValues);
    },
    actions: {
      showContractEdit: function showContractEdit(newContractProductType, newContractType) {
        if (REPLICABLE_CONTRACT_TYPES.includes(newContractType)) {
          this._setValuesForRenewalOrAdmendment();
        }
        this.toggleProperty('showNewContract');
        this.toggleProperty('showCreateContractModal');
        this.editableContract.productType = newContractProductType;
        this.editableContract.type = newContractType;
        this.editableContract.clientUuid = this.get('clientUuid');
      },
      createClientContract: function createClientContract(contract) {
        var _this = this;
        return this.get('saveClientContract')(contract).finally(function () {
          _this.send('resetContractForm');
        });
      },
      updateClientContract: function updateClientContract(contract) {
        var _this2 = this;
        if (contract.get('hasDirtyAttributes')) {
          this.set('isSavingContract', true);
          return this.get('updateClientContract')(contract).finally(function () {
            _this2.send('resetContractForm');
          });
        } else {
          return Ember.RSVP.resolve(this.send('resetContractForm'));
        }
      },
      resetContractForm: function resetContractForm() {
        this.set('editableContract', {});
        this.set('showNewContract', false);
        this.set('allowEditExisting', false);
      }
    }
  });
});
