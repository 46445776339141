import DS from 'ember-data';

export default DS.Model.extend({
    FirstName: DS.attr(),
    LastName: DS.attr(),
    Email: DS.attr(),
    Phone: DS.attr(),
    CreatedDate: DS.attr(),
    Title: DS.attr(),
    Account_Owner__c: DS.attr(),
    Account_Owner_Email__c: DS.attr()
});
