define("tpf-fund-manager/components/site-sidenav-global-nav", ["exports", "ember-local-storage", "tpf-fund-manager/config/environment"], function (_exports, _emberLocalStorage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['site-sidenav-global-nav'],
    // currentRouteName - provided by a route template.
    ui: (0, _emberLocalStorage.storageFor)('sidenav-state'),
    sidebar: Ember.inject.service(),
    active: Ember.computed.alias('ui.activeGlobalNav'),
    isLoansOpen: Ember.computed.equal('active', 'loans'),
    isCapfinOpen: Ember.computed.equal('active', 'capfin'),
    isOnboardingOpen: Ember.computed.equal('active', 'onboarding'),
    isFactoringOpen: Ember.computed.equal('active', 'factoring'),
    isRfOpen: Ember.computed.equal('active', 'rf'),
    actions: {
      setActive: function setActive() {
        var sectionName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        var isOpen = this.get('active') === sectionName;
        var active = isOpen ? '' : sectionName;
        this.set('active', active);
      },
      goToDashboard: function goToDashboard() {
        window.location.replace(_environment.default.APP.adminDashboard);
      }
    }
  });
});
