define("tpf-fund-manager/components/loading-indicator", ["exports", "tpf-fund-manager/templates/components/loading-indicator"], function (_exports, _loadingIndicator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _loadingIndicator.default,
    tagName: 'span',
    size: '',
    // tiny, small, medium, large
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var wrapper = this.get('wrapper');
      if (wrapper) {
        this.set('openElement', Ember.String.htmlSafe("<".concat(wrapper, ">")));
        this.set('closeElement', Ember.String.htmlSafe("</".concat(wrapper, ">")));
      }
    }
  });
});
