define("tpf-fund-manager/authenticators/jwt", ["exports", "ember-simple-auth/authenticators/base", "tpf-fund-manager/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var jwtdecode = function jwtdecode(token) {
    return JSON.parse(atob(token.split('.')[1]));
  };
  var INSECURE = _environment.default.APP.insecure === true;
  var EXPIRATION_BUFFER_MS = 10000;
  var _default = _exports.default = _base.default.extend({
    session: Ember.inject.service('session'),
    restore: function restore(data) {
      return this.authenticateOrRestore(data.token);
    },
    authenticateOrRestore: function authenticateOrRestore(token) {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(token)) {
          if (!_this.isTokenExpired(token)) {
            var tokenData;
            // only thrown when empty token or invalid token
            try {
              tokenData = jwtdecode(token);
              // tokenData = { payload: { user: { userAuthUuid: '123213'} } };
            } catch (e) {
              window.console.log('Invalid token ', e);
              // exception only if invalid token
              reject();
            }

            // user is null if a valid token, but not formed as we expect it
            // allowing success if token is valid, but we expect a different payload
            var _ref = tokenData.payload || {},
              user = _ref.user;
            var featuresPromise = _this.retrieveFeatures(token, user.uuid);
            featuresPromise.then(function (features) {
              // the features object can come back double-nested.
              // This is a bit complicated, but it allows us to still work if the behavior is
              // "fixed" on the backend
              if (!Array.isArray(features) && features && features.features && Array.isArray(features.features)) {
                features = features.features;
              }
              user.features = features;
              resolve({
                token: token,
                data: user
              });
            }).catch(function (err) {
              reject(err);
            });
          } else {
            // Ensure the local storage is deleted if the token is bad.
            _this.get('session').invalidate();
            reject();
          }
        } else {
          reject();
        }
      });
    },
    retrieveFeatures: function retrieveFeatures(token, userAuthUuid) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var request = Ember.$.ajax({
          url: "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/features/").concat(userAuthUuid),
          type: 'GET',
          contentType: 'application/json',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', "Bearer ".concat(token));
          }
        });
        request.done(function (features) {
          resolve({
            features: features
          });
        });
        request.fail(function (jqXHR, textStatus, errorThrown) {
          reject(errorThrown);
        });
      });
    },
    isTokenExpired: function isTokenExpired(token) {
      if (INSECURE) {
        return false;
      }
      // If this errors we've stored our token incorrectly.
      var tokenAsObject = JSON.parse(atob(token.split('.')[1]));

      // 1. The JWT stores expiration in seconds, we need to adjust it to
      //    ms by multiplying by 1000 in order to compare to Date.now()
      // 2. Subtract a buffer amount (default 10s) as we don't really want
      //    to make ajax calls with a token that close to expiring.
      var tokenExpirationThresholdMs = tokenAsObject.exp * 1000 - EXPIRATION_BUFFER_MS;
      return Date.now() >= tokenExpirationThresholdMs;
    },
    /**
     * Currently the supplier app only supports accepting tokens to authenticate
     * as un/password entry is done in the main c2fo webapp
     * @param {*} token - jwt token of c2fo webapp user
     */
    authenticate: function authenticate(token) {
      return this.authenticateOrRestore(token);
    },
    invalidate: function invalidate(data) {
      if (data.token) {
        delete data.token;
      }
      return Ember.RSVP.resolve(data);
    }
  });
});
