define("tpf-fund-manager/helpers/has-feature", ["exports", "tpf-fund-manager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * has-feature is used to determine feature-access for a given user, most commonly the
   * current session's user.  The current session is provided by the simple-auth session service
   * and injected into the helper.  For more information about class-based helpers see:
   * https://guides.emberjs.com/v2.0.0/templates/writing-helpers/#toc_class-based-helpers
   *
   * Examples:
   *
   * ```handlebars
   *
   * A basic example:
   * {{#if (has-feature 'feature_name')}}
   *      Yay, you have access.
   * {{else}}
   *      No access for you.
   * {{/if}}
   * ```
   *
   * OR allow any in a list of features:
   * ```handlebars
   * {{#if (has-feature 'feature_name,another_feature')}}
   *      Yay, you have access through 'feature_name' or 'another_feature_name'
   * {{/if}}
   * ```
   *
   * You can explicitly pass a user to the helper if you need to. In this example the someUser variable
   * should follow the structure: someUser = {features: ['feature_name']}
   * ```handlebars
   * {{#if (has-feature 'feature_name' user=someUser)}}
   *      Yay, you have access through 'feature_name' or 'another_feature_name'
   * {{/if}}
   * ```
   *
   * or use equality helpers in conjunction with has-feature to disable elements
   * ```handlebars
   * {{input disabled=(not (has-feature 'feature-name'))}}
   * ```
   */
  var _default = _exports.default = Ember.Helper.extend({
    session: Ember.inject.service(),
    isFeatureAccessEnabled: _environment.default.isFeatureAccessEnabled,
    // flag for token that is set to a known 'insecure env' value.
    isDevEnvironment: Ember.computed(function () {
      return _environment.default.environment.includes('development');
    }).readOnly(),
    // Refresh UI to reflect correct values after auth status changes.
    onSessionAuth: Ember.observer('session.data', function () {
      this.recompute();
    }),
    // Compute function required for helper interfaces.
    compute: function compute(params) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        user: null
      };
      Ember.assert('No feature supplied', params.length > 0);

      // bypass features with config or using NODE_ENV=insecure to run the tpf-api.
      if (!this.get('isFeatureAccessEnabled') || this.get('isDevEnvironment')) {
        return true;
      }
      var features = params[0];
      if (Ember.typeOf(features) === 'string') {
        features = features.split(',').map(function (s) {
          return s.trim();
        });
      }
      var user = options.user || this.getWithDefault('session.data.authenticated.data', {
        features: []
      });
      return features.any(function (feat) {
        return user.features.includes(feat);
      });
    }
  });
});
