define("tpf-fund-manager/routes/capfin/clients/show/invoice-testing", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    setupController: function setupController(controller) {
      var _this$paramsFor = this.paramsFor('capfin.clients.show'),
        client_uuid = _this$paramsFor.client_uuid;
      controller.set('clientUuid', client_uuid);
    }
  });
});
