define("tpf-fund-manager/controllers/capfin/factoring/fees/index/invoice-fees", ["exports", "tpf-fund-manager/mixins/active-client-search-mixin"], function (_exports, _activeClientSearchMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend(_activeClientSearchMixin.default, {
    store: Ember.inject.service(),
    transformClientSearchParameters: function transformClientSearchParameters(query) {
      query.productType = 'FACTORING';
      return query;
    },
    actions: {
      searchInvoice: function searchInvoice(text) {
        var params = {
          $search: {
            invoiceId: text
          },
          sort: {
            invoiceId: 'ASC'
          },
          pageSize: 10,
          clientUuid: this.get('selectedClient.uuid')
        };
        return this.get('store').query('factoring-invoice', params);
      }
    }
  });
});
