define("tpf-fund-manager/helpers/transaction-status", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.transactionStatus = transactionStatus;
  /**
   * Returns the HTML for a checkmark or an ex-out based on the passed `value`.
   *
   * @param value {Boolean} - the boolean value to make into an icon
   * @param size {String} - the size of the icon, defaults to 'small'
   * @param namedArgs {Object} - impacts how the checkmark is created
   *          invert - If true, will invert the value given for the `value` parameter, defaults to false.
   *
   * @returns {String} - the HTML for the icon
   */
  function transactionStatus(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      value = _ref2[0],
      status = _ref2[1];
    var approvedSettlementStatuses = ['To Be Originated', 'New', 'Originated / Settlement Pending', 'Approved', 'Processed', 'Originated', 'Not Processed'];
    if (value === null) {
      value = 'Not Processed';
    }
    var color = 'red';
    if (approvedSettlementStatuses.indexOf(status) !== -1) {
      color = 'grey darken-1';
    }
    if (status === 'Settled') {
      color = 'green';
    }
    return Ember.String.htmlSafe("<span class=\"card white-text ".concat(color, "\"><b class=\"card-content\">").concat(value, "</b></span>"));
  }
  var _default = _exports.default = Ember.Helper.helper(transactionStatus);
});
