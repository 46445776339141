define("tpf-fund-manager/components/navigation-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    tabList: Ember.computed('tabs', 'router.currentRouteName', function () {
      var _this = this;
      return this.get('tabs').map(function (tab) {
        return Object.assign({
          active: _this.get('router.currentRouteName').includes(tab.route)
        }, tab);
      });
    }),
    bodyClasses: Ember.computed('centered', function () {
      var defaultClasses = 'layout-xs-12 layout-column';
      if (this.get('centered')) {
        defaultClasses += ' layout-align-center-center';
      }
      return defaultClasses;
    })
  });
});
