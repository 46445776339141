define("tpf-fund-manager/components/feathers-filter-clear", ["exports", "tpf-fund-manager/templates/components/feathers-filter-clear"], function (_exports, _feathersFilterClear) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _feathersFilterClear.default,
    filterService: Ember.inject.service('filter-state'),
    hasFilter: Ember.computed('data.isUpdating', {
      get: function get() {
        var query = this.getWithDefault('data.query', {});
        var filterSuffixes = this.get('filterSuffixes');
        var hasFilter = false;
        var _loop = function _loop(prop) {
          if (query.hasOwnProperty(prop)) {
            // Filters can be Objects, Arrays, or primitives and may be empty.
            hasFilter = filterSuffixes.some(function (suffix) {
              if (prop.toLowerCase().includes(suffix)) {
                if (query[prop] === Object(query[prop])) {
                  return Object.keys(query[prop]).length > 0;
                } else if (Array.isArray(query[prop])) {
                  return query[prop].length > 0;
                } else {
                  return true;
                }
              }
              return false;
            });
            if (hasFilter) {
              return "break";
            }
          }
        };
        for (var prop in query) {
          var _ret = _loop(prop);
          if (_ret === "break") break;
        }
        return hasFilter;
      }
    }),
    clearFilters: function clearFilters() {
      var data = this.get('data');
      var filterService = this.get('filterService');
      var storedFilters = filterService.getParams(data.modelName);
      for (var filter in storedFilters) {
        if (storedFilters.hasOwnProperty(filter)) {
          if (data.query[filter] === Object(data.query[filter])) {
            data.query[filter] = {};
          } else {
            delete data.query[filter];
          }
        }
      }
      if (data.query.$search === Object(data.query.$search)) {
        data.query.$search = {};
      } else {
        delete data.query.$search;
      }
      // TODO: figure why this data.update() doesn't trigger on all date filter components
      data.update();
      filterService.clearParams(data.modelName);
    },
    actions: {
      clearFilters: function clearFilters() {
        this.clearFilters();
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.filterSuffixes = ['$in', '$notin', 'gte', 'lte', 'date'];
    }
  });
});
