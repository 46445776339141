define("tpf-fund-manager/components/feathers-date-range-filter", ["exports", "tpf-fund-manager/templates/components/feathers-date-range-filter"], function (_exports, _feathersDateRangeFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _feathersDateRangeFilter.default,
    classNames: 'feathers-filter feathers-date-range-filter',
    filterService: Ember.inject.service('filter-state'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('uuid', Ember.guidFor(this));
      this.setup();
    },
    setup: function setup() {
      this.filterService = this.get('filterService');
      var data = this.get('data');
      var fieldName = this.get('field');
      var existingFilters = this.filterService.getParam(data.modelName, fieldName);
      // only update if we have a valid range filter.
      if (existingFilters && Ember.typeOf(existingFilters) === 'object') {
        data.query[fieldName] = existingFilters;
        Ember.run.debounce(data, data.update, 200);
      }
    },
    /**
     * The start date for the range filter.  Updated anytime the data store changes.
     */
    startDate: Ember.computed('data.isUpdating', {
      get: function get() {
        var data = this.get('data');
        return this.getDate(data, 'gte');
      }
    }),
    /**
     * The end date for the range filter.  Updated anytime the data store changes.
     */
    endDate: Ember.computed('data.isUpdating', {
      get: function get() {
        var data = this.get('data');
        return this.getDate(data, 'lte');
      }
    }),
    /**
     * Gets the date off of the current data query on the datastore for the particular operator.
     *
     * @param data - data store
     * @param operator - the operator such as 'gte' or 'lte'
     * @returns {*}
     */
    getDate: function getDate(data, operator) {
      var fieldName = this.get('field');
      var fieldValue = data.query[fieldName] || {};
      if (!fieldValue[operator]) {
        return null;
      } else {
        return fieldValue[operator];
      }
    },
    /**
     * Adds the date to the data stores query.
     *
     * @param data - the data store
     * @param selected - the selected date.
     * @param operator - the operator.
     */
    applyDate: function applyDate(data, selected, operator) {
      var fieldName = this.get('field');
      var fieldValue = data.query[fieldName];
      if (fieldValue === undefined) {
        fieldValue = {};
      }
      var value = selected ? selected.toISOString() : null;

      //If the value is empty then delete it.
      if (!value) {
        delete fieldValue[operator];
        this.filterService.deleteParam(data.modelName, fieldName);
      } else {
        fieldValue[operator] = value;
      }

      // Don't put empty objects as filters
      if (Object.keys(fieldValue).length > 0) {
        this.filterService.setParam(data.modelName, fieldName, fieldValue);
        data.query[fieldName] = fieldValue;
      }
    },
    /**
     * Updates the query and shows the loading icon for the filter.
     *
     * @param data - data store.
     */
    setLoading: function setLoading(data) {
      var _this = this;
      this.set('isLoading', true);
      data.update().then(function () {
        _this.set('isLoading', false);
        _this.rerender();
      });
    },
    actions: {
      onStartDateSelect: function onStartDateSelect(selected) {
        var data = this.get('data');
        this.applyDate(data, selected, 'gte');
        this.setLoading(data);
      },
      onEndDateSelect: function onEndDateSelect(selected) {
        var data = this.get('data');
        this.applyDate(data, selected, 'lte');
        this.setLoading(data);
      },
      onDateClear: function onDateClear(operator) {
        var data = this.get('data');
        this.applyDate(data, null, operator);
        this.setLoading(data);
      }
    }
  });
});
