define("tpf-fund-manager/controllers/capfin/clients/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    clientName: '',
    actions: {
      clearNewSupplier: function clearNewSupplier() {
        this.set('name', null);
        this.set('userEmail', null);
      },
      closeModal: function closeModal() {
        this.set('showNewSupplierDialog', false);
        this.send('clearNewSupplier');
      },
      closeConfirmationModal: function closeConfirmationModal() {
        this.set('showNewSupplierDialog', false);
        this.set('showExistingSupplierDialog', false);
        this.send('clearNewSupplier');
      },
      openConfirmationModal: function openConfirmationModal() {
        this.set('showNewSupplierDialog', false);
        this.set('showExistingSupplierDialog', true);
      }
    }
  });
});
