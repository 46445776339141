define("tpf-fund-manager/routes/capfin/receivables-finance/invoices/index/pending", ["exports", "tpf-fund-manager/routes/capfin/receivables-finance/invoices/index/-base-pending"], function (_exports, _basePending) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _basePending.default.extend({
    getModelKey: function getModelKey() {
      return 'pendingInvoices';
    },
    getFilters: function getFilters() {
      return {
        verificationPriority: 'LOW'
      };
    }
  });
});
