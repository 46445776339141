define("tpf-fund-manager/utils/country-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // ISO3166 country codes for use in forms.
  // realistically it's only US right now...
  var _default = _exports.default = ['US'];
});
