define("tpf-fund-manager/components/client-debtor-mapped-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    actions: {
      removeMapping: function removeMapping(mappedCustomer) {
        var _this = this;
        return mappedCustomer.destroyRecord().then(function () {
          _this.get('InvoiceCustomerMappings').update();
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.searchFieldNames = ['clientName'];
    }
  });
});
