import DS from 'ember-data';

export default DS.Model.extend({
    uuid: DS.attr(),
    clientUuid: DS.attr(),
    type: DS.attr(),
    remainingAmount: DS.attr(),
    invoiceId: DS.attr(),
    description: DS.attr(),
    postedDate: DS.attr()
});
