define("tpf-fund-manager/initializers/route-model-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    Ember.Route.reopen({
      toast: Ember.inject.service(),
      versionState: Ember.inject.service('version-state'),
      filterState: Ember.inject.service('filter-state'),
      pageState: Ember.inject.service('page-state'),
      sortState: Ember.inject.service('sort-state'),
      session: Ember.inject.service('session'),
      // before each model is computed, set the current Route name on the services
      // and on the market.show route controller (since market toolbar/nav are there)
      // this is an optimistic change that you can't get when injecting the private
      // -router service.
      beforeModel: function beforeModel() {
        var routeName = this.get('routeName');
        this.get('pageState').set('currentRouteName', routeName);
        this.get('filterState').set('currentRouteName', routeName);
        this.get('sortState').set('currentRouteName', routeName);
        // set the activeCurrentRoute (the intended route's deepest state)
        // application has the nav component, so notify that controller
        // with the route name.
        this.controllerFor('application').set('activeRouteName', routeName);
      },
      // it is necessary to access a controller at least once before accessing it,
      // as we have done in the 'beforeModel'.  Setup controller explicitly declares
      // a route's controller.  If all controllers were explicitly created, this method
      // would not be needed.
      setupController: function setupController(controller, model) {
        this._super(controller, model);
        controller.set('currentRouteName', this.get('routeName'));
        var user = this.getWithDefault('session.data.authenticated', {
          data: {}
        });
        controller.set('user', user);
      },
      /**
       * getPreviousFilters merges user-defined filters/sort/page in localstorage
       * for the current route.
       * @param options - same as the options provided to store.query(<model=name>, options)
       *          options._modelName
       *          options.<filter_param>
       *          options.sort
       *          options.page
       *          options.pageSize
       * @returns {{}}
       */
      getPreviousFilters: function getPreviousFilters() {
        var _this = this;
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var result = {};
        Object.keys(options).map(function (namespace) {
          var defaults = Ember.assign({}, options[namespace]);
          var exportedAs = namespace;
          // allow caller provide a modelName different that the key
          var modelName = defaults._modelName || exportedAs;
          delete defaults._modelName;

          // find existing sort params and merge with defaults.
          var sort = {};
          if (defaults.sort) {
            sort = _this.get('sortState').getParams(modelName, defaults.sort);
          } else {
            sort = _this.get('sortState').getParams(modelName);
          }
          if (sort && Object.keys(sort).length !== 0) {
            Ember.merge(defaults, {
              sort: sort
            });
          }

          // find existing filter params and merge with defaults.
          var filter = _this.get('filterState').getParams(modelName);
          Ember.merge(defaults, filter);

          // find existing page sizes and override defaults.
          // if page size is set to false, remove it from the filter.
          // if a special page size is specified on the model and none is stored
          // in the state service, use it and save it to the state service.
          // otherwise use the default state service value if one is not stored.
          if (Ember.isPresent(defaults.pageSize) && defaults.pageSize === false) {
            delete defaults.pageSize;
          } else {
            var pageSize = _this.get('pageState').getParam(modelName);
            if (pageSize) {
              defaults.pageSize = pageSize;
            } else if (defaults.pageSize) {
              _this.get('pageState').setParam(modelName, defaults.pageSize);
            } else {
              defaults.pageSize = _this.get('pageState').defaultPageSize;
            }
          }
          result[exportedAs] = _this.get('store').query(modelName, defaults);
        });
        return result;
      }
    });
  }
  var _default = _exports.default = {
    name: 'route-model-query',
    initialize: initialize
  };
});
