define("tpf-fund-manager/routes/markets/show/risk/show", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    modelBulkUpdate: Ember.inject.service(),
    codifiedList: Ember.inject.service(),
    formQuestions: Ember.inject.service(),
    connect: Ember.inject.service(),
    model: function model(params) {
      var _this = this;
      var existingParams = this.paramsFor('markets.show');
      var marketId = existingParams.market_id;
      var takerId = params.supplier_id;
      var divisionId = takerId;
      return Ember.RSVP.hash(this.getPreviousFilters({
        tpfRisk: {
          _modelName: 'tpf-risk',
          divisionId: divisionId
        },
        customerInvoiceStats: {
          _modelName: 'customer-invoice-stat',
          takerId: takerId,
          marketId: marketId,
          sort: {
            totalArAmountConcentration: 'DESC',
            id: 'DESC'
          }
        },
        loanAgreements: {
          _modelName: 'tpf-loan-agreement',
          divisionId: divisionId
        },
        tpfTakersMarketStats: {
          _modelName: 'tpfTakersMarketStat',
          takerId: takerId
        },
        marketTakerConfiguration: {
          _modelName: 'marketTakerConfiguration',
          takerId: takerId,
          marketId: marketId
        },
        tpfTaker: {
          _modelName: 'tpfTaker',
          takerId: takerId
        },
        tpfFinancialFiles: {
          _modelName: 'tpfFinancialFile',
          divisionId: divisionId
        },
        tpfLoans: {
          _modelName: 'tpfLoan',
          takerId: takerId,
          marketId: marketId,
          pageSize: 10
        }
      })).then(function (results) {
        var divisionUuid = results.tpfTaker.get('firstObject').get('takerUuid');
        return Ember.RSVP.hash(Object.assign(results, {
          onboardingForm: _this.get('store').query('onboarding-form', {
            divisionUuid: divisionUuid
          }),
          industryGroups: _this.get('codifiedList').getIndustryGroups(),
          formationStructures: _this.get('codifiedList').getFormationStructures(),
          additionalQuestions: _this.get('formQuestions').getOfficersInfoAdditionalQuestions(),
          codatUrl: _this.get('connect').getCompanyCodatUrl(divisionUuid)
        }));
      }).then(function (results) {
        results.marketTakerConfiguration = results.marketTakerConfiguration.get('firstObject');
        if (!results.marketTakerConfiguration) {
          results.marketTakerConfiguration = _this.get('store').createRecord('marketTakerConfiguration', {
            takerId: takerId,
            marketId: marketId
          });
        }
        var onboardingForm = results.onboardingForm.get('firstObject');
        if (onboardingForm) {
          results.onboardingForm = _this._mapRelatedDisplayText(onboardingForm, results.industryGroups, results.formationStructures, results.additionalQuestions);
        }

        // convert any array's that should be get requests.
        results.tpfRisk = results.tpfRisk.get('firstObject');
        results.tpfTakersMarketStat = results.tpfTakersMarketStats.get('firstObject');
        results.tpfTaker = results.tpfTaker.get('firstObject');
        return results;
      });
    },
    _mapRelatedDisplayText: function _mapRelatedDisplayText(form, industryGroups, formationStructures, additionalQuestions) {
      // tag on industryGroupName for industryGroupUuid
      var industryGroup = industryGroups.find(function (i) {
        return i.uuid === form.get('industryTypeUuid');
      });
      if (industryGroup) {
        form.set('industryGroupName', industryGroup.groupDisplayName);
      }

      // tag on formation structure name to form based on entityType key
      var formationStructureName;
      if (form.get('entityType') === 'OTHER') {
        formationStructureName = form.get('entityTypeOther');
      } else {
        var structure = formationStructures.find(function (f) {
          return f.value === form.get('entityType');
        });
        formationStructureName = structure && structure.display;
      }
      form.set('formationStructureName', formationStructureName);

      // tag on question text to all answers.
      var answers = form.get('additionalQuestionAnswers').map(function (answer) {
        var matchedQuestion = additionalQuestions.find(function (q) {
          return q.get('uuid') === answer.get('onboardingAdditionalQuestionUuid');
        });
        answer.questionText = matchedQuestion.get('questionText');
        return answer;
      });
      form.set('additionalQuestionAnswers', answers);
      return form;
    },
    actions: {
      bulkUpdateFinancialFiles: function bulkUpdateFinancialFiles(data, query) {
        var modelBulkUpdate = this.get('modelBulkUpdate');
        return modelBulkUpdate.update(data, query, 'tpf-financial-files');
      }
    }
  });
});
