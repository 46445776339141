import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    uuid: Model.attr(),
    clientUuid: Model.attr(),
    clientName: Model.attr(),
    accruedInterestAmount: Model.attr(),
    advanceAmount: Model.attr(),
    arAdjustments: Model.attr(),
    outstandingAdjustments: Model.attr(),
    totalReceiptAmount: Model.attr(),
    netInvoiceAmount: Model.attr(),
    outstandingBalance: Model.attr(),
    arBalance: Model.attr(),
    marginalCollateralBalance: Model.attr(),
    availabilityBalance: Model.attr()
});
