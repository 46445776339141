define("tpf-fund-manager/components/info-box", ["exports", "tpf-fund-manager/templates/components/info-box"], function (_exports, _infoBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * InfoBox is a component to display and emphasize a specific metric.
   * By default the component expects a component block.
   *
   * Example Usage:
   * ```JavaScript
   * {{#info-box icon='pool'
   *             layoutType=<1-6>
   *             primaryColor='lighten-3 grey'
   *             secondaryColor='text-darken-3 grey'
   *             classNames='col s12 m6'}}
   *      <div class="text">Feels like</div>
   *      <div class="number">$1,000,000.00</div>
   * {{/info-box}}
   * ```
   */
  var _default = _exports.default = Ember.Component.extend({
    layout: _infoBox.default,
    icon: '',
    layoutType: 1,
    // different layouts (1-6)
    primaryColor: 'darken-2 grey',
    // bold backgrounds
    secondaryColor: '',
    // usually text
    hoverEffect: '',
    // hover-expand-effect | hover-zoom-effect
    lineCountClass: Ember.computed('lineCount', {
      get: function get() {
        var lineCount = this.get('lineCount');
        if (!lineCount) {
          return '';
        }
        return "info-box-lines-".concat(lineCount);
      }
    })
  });
});
