import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    uuid: Model.attr(),
    name: Model.attr(),
    created: Model.attr(),
    isWhiteOakRecommended: Model.attr(),
    whiteOakSalesforceAccountId: Model.attr(),
    whiteOakSalesforceOpportunityId: Model.attr(),
    whiteOakCustomerNumber: Model.attr(),
    billToCustomerName: Model.attr(),
    billToEmail: Model.attr(),
    billToPhone: Model.attr(),
    shortTermSnpCreditRating: Model.attr(),
    longTermSnpCreditRating: Model.attr(),
    shortTermMoodysCreditRating: Model.attr(),
    longTermMoodysCreditRating: Model.attr(),
    industryGroupUuid: Model.attr(),
    isEarlyPay: Model.attr(),
    isPublic: Model.attr(),
    riskRating: Model.attr(),
    riskNotes: Model.attr(),
    stockSymbol: Model.attr(),
    hasParentCompany: Model.attr(),
    parentCompanyName: Model.attr(),
    isParentCompanyPublic: Model.attr(),
    parentCompanyStockSymbol: Model.attr(),
    website: Model.attr(),
    vendorPortalUrl: Model.attr(),
    approvedCreditLimit: Model.attr(),
    remittanceChangeNotes: Model.attr(),

    // join table fields
    // will be null on direct query to debtor, but are populated when querying
    // debtors through client.  They exist in our schema under client_debtor
    creditLimit: Model.attr(),
    defaultAdvanceRate: Model.attr(),
    defaultConcentration: Model.attr(),
    defaultTermCodeDescription: Model.attr(),
    isEnabled: Model.attr()
});
