define("tpf-fund-manager/helpers/is-subheading", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isSubheading = isSubheading;
  function isSubheading(_ref /*, hash*/) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      routeName = _ref2[0],
      activeRoute = _ref2[1];
    return activeRoute === routeName;
  }
  var _default = _exports.default = Ember.Helper.helper(isSubheading);
});
