define("tpf-fund-manager/routes/capfin/admin/funding-sources/index", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash(this.getPreviousFilters({
        fundingSources: {
          _modelName: 'funding-source',
          sort: {
            name: 'DESC'
          }
        }
      }));
    },
    actions: {
      clearNewFundingSource: function clearNewFundingSource() {
        var controller = this.controllerFor(this.routeName);
        controller.setProperties({
          sourceName: null,
          sourceCashPool: null
        });
      },
      addFundingSource: function addFundingSource(sourceName, sourceCashPool) {
        var _this = this;
        var fundingSource = this.get('store').createRecord('funding-source', {
          name: sourceName,
          cashPool: sourceCashPool
        });
        var controller = this.controllerFor(this.routeName);
        controller.set('isCreatingFundingSource', true);
        return fundingSource.save().then(function () {
          controller.set('isCreatingFundingSource', false);
          _this.send('clearNewFundingSource');
          _this.refresh();
        });
      }
    }
  });
});
