define("tpf-fund-manager/components/paper-masked-input", ["exports", "ember-paper/components/paper-input", "ember-paper/templates/components/paper-input", "inputmask"], function (_exports, _paperInput, _paperInput2, _inputmask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Extension of paper-input input field to utilize Inputmask
  // uses all of the paper-input options, and additionally a `mask` option
  // E.G. mask="999-9999" will mask input for a 3 digit dash 4 digit number.
  // https://github.com/RobinHerbots/Inputmask for mask options.
  var _default = _exports.default = _paperInput.default.extend({
    layout: _paperInput2.default,
    mask: '',
    debounce: 0,
    value: 'value',
    options: Ember.computed(function () {
      return {};
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.inputElement = this.$('input');
      this.propertyDidChange('mask');
    },
    // Below mostly pulled from ember-inputmask with some updates
    // around not using observers
    // https://github.com/pzuraq/ember-inputmask
    setMask: function setMask() {
      if (!this.element) {
        return;
      }
      if (this.inputElement.inputmask) {
        this.inputElement.inputmask.remove();
      }
      var mask = this.get('mask');
      var options = this.getWithDefault('options', {});
      var inputmask = new _inputmask.default(mask, options);
      inputmask.mask(this.inputElement);

      // Initialize the unmasked value if it exists
      if (Ember.isPresent(this.get('unmaskedValue'))) {
        this.inputElement.value = this.get('unmaskedValue');
      }

      // If the mask has changed, we need to refocus the input to show the
      // proper mask preview. Since the caret is not positioned by the focus
      // even, but the click event, we need to trigger a click as well.
      if (this.inputElement === document.activeElement) {
        this.inputElement.blur();
        this.inputElement.focus();
        this.inputElement.click();
      }
    },
    updateMask: function updateMask() {
      if (this.get('mask').toLowerCase() === 'regex') {
        // Note: I like pattern better, but I'll leave regex in as an option
        // as well since that's what the plugin defines on the options hash
        this.set('options.regex', this.get('pattern') || this.get('regex'));
        this.set('options.mask', '');
      }
      this.setProperties({
        'options.placeholder': this.get('maskPlaceholder'),
        'options.showMaskOnFocus': this.get('showMaskOnFocus'),
        'options.showMaskOnHover': this.get('showMaskOnHover'),
        'options.rightAlign': this.get('rightAlign'),
        'options.clearIncomplete': this.get('clearIncomplete'),
        'options.greedy': this.get('greedyMask')
      });
      this.setMask();
    },
    _maskShouldChange: Ember.observer('mask', 'maskPlaceholder', 'showMaskOnFocus', 'showMaskOnHover', 'rightAlign', 'clearIncomplete', 'greedyMask', 'pattern', 'regex', function () {
      Ember.run.once(this, 'updateMask');
    }),
    unmaskedValue: Ember.computed('value', function () {
      if (this.element && this.element.inputmask) {
        return this.element.inputmask.unmaskedvalue();
      } else {
        return this.get('value');
      }
    })
  });
});
