define("tpf-fund-manager/routes/capfin/clients/show/salesforce", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      var client = this.modelFor('capfin.clients.show').client;
      var clientUuid = client.get('uuid');
      return Ember.RSVP.hash({
        sfContact: this.get('store').query('sf-contact', {
          clientUuid: clientUuid
        }).catch(function () {
          return null;
        }),
        store: this.get('store'),
        client: client,
        sfAccountConnected: client.get('salesforceAccountId')
      }).then(function (data) {
        if (data.sfContact) {
          data.sfContact = data.sfContact.get('firstObject');
        }
        return data;
      });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    }
  });
});
