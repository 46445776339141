define("tpf-fund-manager/components/td-formatted", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "tpf-fund-manager/config/environment", "tpf-fund-manager/helpers/format-currency", "tpf-fund-manager/helpers/format-date", "tpf-fund-manager/helpers/format-integer", "tpf-fund-manager/helpers/format-percent", "tpf-fund-manager/helpers/format-term", "tpf-fund-manager/helpers/format-null-bool"], function (_exports, _toConsumableArray2, _environment, _formatCurrency, _formatDate, _formatInteger, _formatPercent, _formatTerm, _formatNullBool) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var formats = {
    currency: _formatCurrency.formatCurrency,
    date: _formatDate.formatDate,
    timestamp: _formatDate.formatDate,
    integer: _formatInteger.formatInteger,
    percent: _formatPercent.formatPercent,
    term: _formatTerm.formatTerm,
    text: function text(params) {
      return params[0];
    },
    nullbool: _formatNullBool.formatNullBool
  };
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'td',
    classNameBindings: ['isCurrency:table-cell-currency', 'isDate:table-cell-date', 'isTimestamp:table-cell-timestamp', 'isInteger:table-cell-integer', 'isPercent:table-cell-percent', 'isTerm:table-cell-term', 'isText:table-cell-text', 'isNullBool:table-cell-text'],
    // boolean helpers for className binding
    isCurrency: Ember.computed.equal('format', 'currency'),
    isDate: Ember.computed.equal('format', 'date'),
    isTimestamp: Ember.computed.equal('format', 'timestamp'),
    isInteger: Ember.computed.equal('format', 'integer'),
    isPercent: Ember.computed.equal('format', 'percent'),
    isTerm: Ember.computed.equal('format', 'term'),
    isText: Ember.computed.equal('format', 'text'),
    isNullBool: Ember.computed.equal('format', 'nullbool'),
    // use character padding to align columns.
    alignValues: false,
    // ==========================
    // CALLER SUPPLIED PROPERTIES
    // ==========================
    //
    // table: typically, it's your "data" element with all rows
    // row: your current "item"
    // cell: your propertyName in the given row.
    // format: one of the supported helper formats held in the ```formats``` value.
    // formatArgs: optional formatting overrides that your given formatter supports.

    // retrieve the ```this.row[this.cell]``` and hand to the given formatter.
    cellValue: Ember.computed('table.@each.isUpdating', 'row', {
      get: function get() {
        var row = this.get('row');
        var cell = this.get('cell');
        var formatType = this.get('format');
        var val;
        if (row && !row.get) {
          val = row[cell];
        } else if (row) {
          val = row.get(cell);
        }
        if (!val && formatType !== 'nullbool') {
          return _environment.default.formatHelpers['noValue'];
        }
        var formatArgs = this.getWithDefault('formatArgs', {});
        if (this.get('isTimestamp')) {
          formatArgs = {
            timestamp: true
          };
        }
        return this.get('formatValue')(val, formatType, formatArgs);
      }
    }),
    padChars: Ember.computed('table.@each.isUpdating', 'row', {
      get: function get() {
        var numPadChars = this.getWithDefault('padNum', 0);
        var charArr = [];
        for (var i = 0; i < numPadChars; i++) {
          charArr.push(true);
        }
        return charArr;
      }
    }),
    padNum: Ember.computed('table.@each.isUpdating', 'row', {
      get: function get() {
        var cellVal = "".concat(this.getWithDefault('cellValue', ''));
        var maxPad = this.getWithDefault('maxPad', 0);
        var pad = maxPad - cellVal.trim().length;
        return pad < 1 ? 0 : pad;
      }
    }),
    maxPad: Ember.computed('table.@each.isUpdating', 'row', {
      get: function get() {
        if (!this.get('alignValues') && !this.get('table')) {
          return 0;
        }
        var table = this.getWithDefault('table', Ember.A());
        var cell = this.get('cell');
        var formatType = this.get('format');
        var formatValue = this.get('formatValue');
        var cellValueLengths = table.map(function (tr) {
          var val = Ember.get(tr, cell);
          return "".concat(formatValue(val, formatType)).length;
        });
        return Math.max.apply(Math, (0, _toConsumableArray2.default)(cellValueLengths));
      }
    }),
    /**
     * formatValue applies the intended helper.  It's separated from the cell value to
     * enable extending this component with less work.
     * @param val
     * @param formatType
     * @param args
     * @returns {*}
     */
    formatValue: function formatValue(val, formatType, args) {
      if (!formatType && !formats[formatType]) {
        return val;
      }
      var formatHelper = formats[formatType];
      return formatHelper([val], args);
    }
  });
});
