define("tpf-fund-manager/components/tool-tip", ["exports", "tpf-fund-manager/templates/components/tool-tip"], function (_exports, _toolTip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Depends on the materialize.css library
  var _default = _exports.default = Ember.Component.extend({
    layout: _toolTip.default,
    classNames: 'tooltip',
    'data-position': 'top',
    'data-delay': 50,
    'data-tooltip': null,
    attributeBindings: ['data-position', 'data-delay', 'data-tooltip'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().tooltip({
        html: true
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$().tooltip('remove');
      this._super.apply(this, arguments);
    },
    didRender: function didRender() {
      this.$().tooltip();
    }
  });
});
