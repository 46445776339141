define("tpf-fund-manager/routes/capfin/clients/show/debtors/show", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model(params) {
      var _this = this;
      var client = this.modelFor('capfin.clients.show').client;
      var debtorUuid = params.debtor_uuid;
      return Ember.RSVP.hash({
        clientDebtor: this.get('store').query('client-debtor', {
          clientUuid: client.get('uuid'),
          debtorUuid: debtorUuid
        }),
        debtor: this.get('store').findRecord('debtor', debtorUuid),
        client: client,
        fundingSources: this.get('store').findAll('funding-source')
      }).then(function (results) {
        var clientDebtorUuid = results.clientDebtor.get('firstObject').get('uuid');
        return Ember.RSVP.hash(Object.assign(results, {
          clientDebtorContract: _this.get('store').query('client-debtor-contract', {
            clientDebtorUuid: clientDebtorUuid,
            // only get latest contract (for now)
            // optional: have a better way to definitively query for these
            // or delete pending ones.
            sort: {
              created: 'DESC'
            },
            pageSize: 1
          })
        })).then(function (results) {
          return {
            fundingSources: results.fundingSources,
            clientDebtor: results.clientDebtor.get('firstObject') || {},
            debtor: results.debtor,
            client: results.client,
            clientDebtorUuid: clientDebtorUuid,
            // Only get latest contract for now.
            clientDebtorContract: results.clientDebtorContract.get('firstObject')
          };
        });
      });
    },
    actions: {
      removeDebtor: function removeDebtor(debtor) {
        var client = this.modelFor('capfin.clients.show').client;
        return client.removeDebtor(debtor);
      },
      saveClientDebtorContract: function saveClientDebtorContract(contract) {
        var _this2 = this;
        return this.get('store').createRecord('client-debtor-contract', contract).save().then(function () {
          _this2.refresh();
        });
      },
      refreshClientDebtorShowRoute: function refreshClientDebtorShowRoute() {
        this.refresh();
      }
    }
  });
});
