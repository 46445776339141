import DS from 'ember-data';
import { memberAction, serializeAndPush } from 'ember-api-actions';

export default DS.Model.extend({
    refreshAmounts: memberAction({
        path: 'refresh-unsettled-aggregate-data',
        type: 'POST',
        after: serializeAndPush
    })
});
