define("tpf-fund-manager/helpers/receipt-upload-summary-line-item", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.receiptUploadSummaryLineItem = receiptUploadSummaryLineItem;
  var errorRiskClassifications = ['DUPLICATE', 'INVALID_CLIENT_DEBTOR'];
  function receiptUploadSummaryLineItem(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      riskClassification = _ref2[0],
      remittanceStatus = _ref2[1];
    // we need to error out on remittance with status "INVALID" because there is a potential
    // unpurchased invoice in the receipt allocation process.
    // Unpurchased invoice is a factor invoice that got approved, but not yet purchased(settled)
    // but we already post receipt on that invoice.
    return !!(riskClassification || []).find(function (x) {
      return errorRiskClassifications.includes(x);
    }) || remittanceStatus === 'INVALID';
  }
  var _default = _exports.default = Ember.Helper.helper(receiptUploadSummaryLineItem);
});
