import DS from 'ember-data';
import Ember from 'ember';
import { memberAction } from 'ember-api-actions';

export default DS.Model.extend({
    uuid: DS.attr(),
    divisionUuid: DS.attr(),
    accountNumberConnectIdentifier: DS.attr(),
    accountNumberVgsIdentifier: DS.attr(),
    name: DS.attr(),
    address: DS.attr(),
    city: DS.attr(),
    state: DS.attr(),
    postalCode: DS.attr(),
    nameOnAccount: DS.attr(),
    routingNumberWire: DS.attr(),
    routingNumberAch: DS.attr(),
    lastFourBankAccountNumber: DS.attr(),
    verificationImageConnectIdentifier: DS.attr(),
    verificationImageVgsIdentifier: DS.attr(),
    authorizedByEmailAddress: DS.attr(),
    authorizedByIpAddress: DS.attr(),
    status: DS.attr(),
    isActive: DS.attr(),
    created: DS.attr(),
    updated: DS.attr(),
    transferDate: DS.attr('date'),
    verificationImage: memberAction({ type: 'GET', path: 'connect-filedata' }),
    bankAccountNumber: memberAction({ type: 'GET', path: 'connect-data' }),
    maskedLastFourBankAccountNumber: Ember.computed('lastFourBankAccountNumber', function() {
        if (this.get('lastFourBankAccountNumber')) {
            return this.get('lastFourBankAccountNumber').padStart(17, '*');
        }
    })
});
