define("tpf-fund-manager/components/settlement-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    actions: {
      changeStatus: function changeStatus(model, state) {
        // talked to Anne.  Disabling this, pending a way to show the updated amounts
        // NOTE:  Must be finalized before INITIATED (as it updates cash pool at this point)
        if (state === 'IMPOSSIBLE') {
          // defer to mark sent when attempting to change status to SENT
          this.set('isShowingFinalizeSettlement', true);
        } else {
          return this.get('updateSettlements')([model], {
            settlementStatus: state
          });
        }
      },
      finalizeSettlement: function finalizeSettlement() {
        return this.get('updateSettlements')([this.get('model')], {
          settlementStatus: this.get('toState'),
          issuedDate: this.get('issuedDate')
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.issuedDate = new Date();
    }
  });
});
