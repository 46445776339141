define("tpf-fund-manager/routes/capfin/all-receipts/receipts/show", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model(params) {
      var _this = this;
      var receiptUuid = params.receipt_uuid;
      return this.get('store').findRecord('receipt', receiptUuid, {
        reload: true
      }).then(function (Receipt) {
        return Ember.RSVP.hash({
          Receipt: Receipt,
          debtorForReceipt: _this.get('store').findRecord('debtor', Receipt.get('debtorUuid')),
          clientDebtorForReceipt: _this.get('store').query('client-debtor', {
            clientUuid: Receipt.get('clientUuid'),
            debtorUuid: Receipt.get('debtorUuid')
          }).then(function (cd) {
            return cd.get('firstObject');
          })
        });
      });
    },
    actions: {
      exit: function exit() {
        this.transitionTo('capfin.all-receipts.receipts');
      },
      saveReceipt: function saveReceipt(Receipt) {
        var _this2 = this;
        return Receipt.save().then(function () {
          // Unload invoice allocation records.  Otherwise they will hang around and there
          // will be duplicates when the receipt is fetched.
          var store = _this2.get('store');
          store.unloadAll('receipt-invoice-allocation');
          _this2.transitionTo('capfin.all-receipts.receipts');
        });
      },
      cancelReceipt: function cancelReceipt(Receipt) {
        Receipt.rollbackAttributes();
        this.transitionTo('capfin.all-receipts.receipts');
      }
    }
  });
});
