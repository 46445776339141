define("tpf-fund-manager/serializers/provider", ["exports", "tpf-fund-manager/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var convertedPayload = {};
      convertedPayload.data = payload;
      convertedPayload.meta = {};
      return this._super(store, primaryModelClass, convertedPayload, id, requestType, true);
    }
  });
});
