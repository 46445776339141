define("tpf-fund-manager/adapters/client-unsettled-passthrough", ["exports", "tpf-fund-manager/adapters/application-v2-api"], function (_exports, _applicationV2Api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _applicationV2Api.default.extend({
    pathForType: function pathForType() {
      return 'unsettled-clients/passthroughs';
    }
  });
});
