import DS from 'ember-data';
import { memberAction } from 'ember-api-actions';

export default DS.Model.extend({
    uuid: DS.attr('string'),
    amount: DS.attr('number'),
    clientUuid: DS.attr('string'),
    clientName: DS.attr('string'),

    issuedDate: DS.attr('naive-date'),
    remittanceType: DS.attr('string'),
    remittanceFee: DS.attr('number'),
    settlementStatus: DS.attr('string'),

    feeAmount: DS.attr('number'),
    invoiceChargebackAmount: DS.attr('number'),
    passthroughAmount: DS.attr('number'),
    invoiceFeeAmount: DS.attr('number'),
    invoiceAmount: DS.attr('number'),
    invoiceDiscountFeeAmount: DS.attr('number'),
    invoiceAdvanceAmount: DS.attr('number'),
    invoicePurchasePrice: DS.attr('number'),
    invoiceUnderpaymentAmount: DS.attr('number'),
    invoiceRebateAmount: DS.attr('number'),
    totalAmount: DS.attr('number'),
    createSettlementFromItems: memberAction({
        path: 'create-from-line-items',
        type: 'POST',
        urlType: 'findAll'
    }),

    preferredTransactionType: memberAction({
        path: 'preferred-transaction-type',
        type: 'GET'
    })
});
