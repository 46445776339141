define("tpf-fund-manager/components/client-debtors-form", ["exports", "tpf-fund-manager/fixtures/client-debtor-invoice-verification-ratings"], function (_exports, _clientDebtorInvoiceVerificationRatings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    clientDebtorInvoiceVerificationRatings: _clientDebtorInvoiceVerificationRatings.default,
    clientRfContract: null,
    clientFactoringContract: null,
    resetForm: function resetForm() {
      if (this.get('isProductTypeOptionsDisabled')) {
        this.set('productType', this.get('productTypeOptions.firstObject'));
      }
      this.set('clientDebtor', this.get('store').createRecord('client-debtor', {
        debtor: null,
        clientUuid: this.get('client.uuid'),
        isEnabled: true,
        isControlledRemittance: false,
        invoiceVerificationRating: null,
        invoiceVerificationNotes: '',
        termCodes: []
      }));

      // set product-agnostic record defaults for client-debtor-contracts.
      this.set('contract', this.get('store').createRecord('client-debtor-contract', {
        effectiveDate: null
      }));
      if (this.get('clientFactoringContract')) {
        var factorClientContract = this.get('clientFactoringContract');
        // set factoring-specific defaults, to be filled out in form:
        this.get('contract').setProperties({
          creditLimit: null,
          advanceRate: null,
          rateType: null,
          rate: null,
          minimumTenor: null,
          effectiveDate: null
        });
        // set factoring-specific client-contract inherited fields
        this.get('contract').setProperties(Object.assign(factorClientContract.getProperties('creditLimit', 'effectiveDate', 'maturityInterval', 'maturityLength', 'productType', 'type'), {
          clientContractVerifiedArThreshold: factorClientContract.get('verifiedArThreshold')
        }));
        this.set('contract.clientContractUuid', factorClientContract.get('uuid'));
      } else if (this.get('clientRfContract')) {
        var rfClientContract = this.get('clientRfContract');
        // rf client-debtor contracts have values that mirror their client- counterparts
        // for now.
        this.get('contract').setProperties(Object.assign(rfClientContract.getProperties('effectiveDate', 'maturityInterval', 'maturityLength', 'productType', 'type')));
        this.set('contract.clientContractUuid', rfClientContract.get('uuid'));
      }
    },
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('rateTypeOptions', ['FIXED', 'PRIME_PLUS', 'LIBOR_PLUS', 'SOFR_PLUS']);
      this.get('store').query('client-contract', {
        clientUuid: this.get('client.uuid'),
        sort: {
          asOfDate: 'DESC'
        }
      }).then(function (clientContracts) {
        _this.set('clientRfContract', clientContracts.toArray().findBy('productType', 'RECEIVABLES_FINANCE'));
        _this.set('clientFactoringContract', clientContracts.toArray().findBy('productType', 'FACTORING'));
        _this.resetForm();
      });
    },
    productTypeOptions: Ember.computed('clientRfContract', 'clientFactoringContract', function () {
      return [this.get('clientRfContract') && 'RECEIVABLES_FINANCE', this.get('clientFactoringContract') && 'FACTORING'].filter(Boolean);
    }),
    isProductTypeOptionsDisabled: Ember.computed.equal('productTypeOptions.length', 1),
    actions: {
      setProductType: function setProductType(productType) {
        this.set('productType', productType);
      },
      searchDebtor: function searchDebtor(text) {
        var queryOptions = {
          $search: {
            name: text
          },
          sort: {
            name: 'DESC'
          },
          pageSize: 25
        };
        return this.get('store').query('debtor', queryOptions);
      },
      handleSubmit: function handleSubmit() {
        var _this2 = this;
        this.set('clientDebtor.termCodeIds', this.get('clientDebtor.termCodes').map(function (code) {
          return code.get('uuid');
        }));
        this.set('isSubmittingClientDebtor', true);
        this.set('clientDebtor.debtorUuid', this.get('clientDebtor.debtor.uuid'));
        return this.get('client').addDebtor(this.get('clientDebtor')).then(function (clientDebtor) {
          _this2.set('contract.clientDebtorUuid', clientDebtor.uuid);
          return _this2.get('contract').save();
        }).then(function () {
          var clientDebtor = _this2.get('clientDebtor');
          if (_this2.get('onCreate')) {
            return _this2.get('onCreate')(clientDebtor);
          }
        }).finally(function () {
          _this2.set('isSubmittingClientDebtor', false);
          _this2.resetForm();
        });
      },
      refresh: function refresh() {
        return this.refreshClient();
      }
    }
  });
});
