define("tpf-fund-manager/components/client-debtor-unmapped-grid", ["exports", "tpf-fund-manager/fixtures/client-debtor-invoice-verification-ratings"], function (_exports, _clientDebtorInvoiceVerificationRatings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    unmappedCustomer: null,
    selectedClientDebtor: null,
    clientDebtorInvoiceVerificationRatings: _clientDebtorInvoiceVerificationRatings.default,
    sidebar: Ember.inject.service(),
    saveCustomerMapping: function saveCustomerMapping(recordData) {
      var _this = this;
      this.set('isSavingMapping', true);
      var InvoiceCustomerMapping = this.get('store').createRecord('invoice-customer-mapping', recordData);
      return InvoiceCustomerMapping.save().then(function () {
        _this.get('UnmappedCustomerView').update();
        _this.set('showMappingDialog', false);
        return _this.get('sidebar.loadUnmappedDebtors').perform();
      }).finally(function () {
        return _this.set('isSavingMapping', false);
      });
    },
    actions: {
      mapCustomer: function mapCustomer(unmappedCustomer) {
        this.set('unmappedCustomer', unmappedCustomer);
        this.set('showMappingDialog', true);
      },
      saveMapping: function saveMapping() {
        var _this2 = this;
        return this.saveCustomerMapping({
          clientDebtorUuid: this.get('selectedClientDebtor.uuid'),
          clientUuid: this.get('unmappedCustomer.clientUuid'),
          companyId: this.get('unmappedCustomer.companyId'),
          displayName: this.get('unmappedCustomer.companyName')
        }).then(function () {
          return _this2.get('selectedClientDebtor').save();
        });
      },
      ignoreCompany: function ignoreCompany() {
        return this.saveCustomerMapping({
          invoiceIsIneligible: true,
          clientUuid: this.get('unmappedCustomer.clientUuid'),
          companyId: this.get('unmappedCustomer.companyId'),
          displayName: this.get('unmappedCustomer.companyName')
        });
      },
      searchDebtor: function searchDebtor(text) {
        var unmappedCustomer = this.get('unmappedCustomer');
        if (unmappedCustomer) {
          var queryOptions = {
            $search: {
              debtorName: text
            },
            clientUuid: this.get('unmappedCustomer.clientUuid'),
            hasDefaultTermCode: true,
            sort: {
              debtorName: 'DESC'
            },
            pageSize: 25
          };
          return this.get('store').query('ClientDebtor', queryOptions);
        }
        return null;
      },
      onDebtorSelect: function onDebtorSelect(value) {
        this.set('selectedClientDebtor', value);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.searchFieldNames = ['name'];
    }
  });
});
