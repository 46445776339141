define("tpf-fund-manager/components/risk-severity-debtor-remittance", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      showRiskClassifications: function showRiskClassifications(receiptUploadUuid, debtorRemittanceUuid) {
        var _this = this;
        var riskSummary = [];
        this.get('store').findRecord('staged-debtor-remittance-classification-summary', debtorRemittanceUuid, {
          preload: {
            stagedReceiptUploadUuid: receiptUploadUuid
          }
        }).then(function (classificationSummary) {
          var allocationRisks = classificationSummary.get('riskClassificationCounts');
          for (var _i = 0, _Object$entries = Object.entries(allocationRisks); _i < _Object$entries.length; _i++) {
            var _Object$entries$_i = (0, _slicedToArray2.default)(_Object$entries[_i], 2),
              riskType = _Object$entries$_i[0],
              details = _Object$entries$_i[1];
            if (riskType === 'NONE') {
              continue;
            }
            var riskItemsCount = details['count'];
            riskSummary.push("".concat(riskItemsCount, " ").concat(riskType.toLowerCase()));
          }
          var remittanceRisks = classificationSummary.get('remittanceRisks');
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;
          try {
            for (var _iterator = remittanceRisks[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var _riskType = _step.value;
              riskSummary.push("".concat(_riskType.toLowerCase()));
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
          _this.set('riskSummary', riskSummary);
        });
      }
    }
  });
});
