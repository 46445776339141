define("tpf-fund-manager/components/md-btn-dropdown", ["exports", "ember-cli-materialize/components/md-btn-dropdown"], function (_exports, _mdBtnDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Override needed for materialize.css dropdown.  ember-cli-materialize has a bug where it
   * passes constraintWidth to the jquery plugin incorrectly wiht the key of 'constraint_width'.
   *
   * https://github.com/mike-north/ember-cli-materialize/commit/8cf48f0eda184630ac5028ac08c5801743bfac12#diff-7408ffe32d5384e7514536155a42ebf1
   */
  var _default = _exports.default = _mdBtnDropdown.default.extend({
    _setupDropdown: function _setupDropdown() {
      // needed until the Materialize.dropdown plugin is replaced
      this.$().attr('data-activates', this.get('_dropdownContentId'));
      var options = {
        hover: !!this.getWithDefault('hover', false),
        constrainWidth: !!this.getWithDefault('constrainWidth', true),
        inDuration: this.getWithDefault('inDuration', this.get('_mdSettings.dropdownInDuration')),
        outDuration: this.getWithDefault('outDuration', this.get('_mdSettings.dropdownOutDuration')),
        gutter: this.getWithDefault('gutter', 0),
        belowOrigin: !!this.getWithDefault('belowOrigin', false),
        alignment: this.getWithDefault('alignment', 'left')
      };
      this.$().dropdown(options);
    }
  });
});
