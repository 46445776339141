define("tpf-fund-manager/helpers/icon", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.icon = icon;
  function icon(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      icon = _ref2[0],
      size = _ref2[1];
    size = size || 'small';
    return Ember.String.htmlSafe("<i class=\"".concat(size, " material-icons\">").concat(icon, "</i>"));
  }
  var _default = _exports.default = Ember.Helper.helper(icon);
});
