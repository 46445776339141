import DS from 'ember-data';
import { memberAction } from 'ember-api-actions';

export default DS.Model.extend({
    uuid: DS.attr('string'),
    amount: DS.attr('number'),
    clientUuid: DS.attr('string'),

    issuedDate: DS.attr('naive-date'),
    remittanceType: DS.attr('string'),
    remittanceFee: DS.attr('number'),
    settlementStatus: DS.attr('string'),
    status: DS.attr('string'),
    statusMessage: DS.attr('string'),

    createSettlementFromItems: memberAction({
        path: 'create-from-line-items',
        type: 'POST',
        urlType: 'findAll'
    })
});
