define("tpf-fund-manager/routes/capfin/clients/show", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin", "tpf-fund-manager/config/environment"], function (_exports, _c2foAuthenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    model: function model(params) {
      var _this = this;
      var clientUuid = params.client_uuid;
      return Ember.RSVP.hash({
        client: this.get('store').findRecord('client', clientUuid),
        clientContract: this.get('store').query('client-contract', {
          clientUuid: clientUuid,
          // only get latest contract (for now)
          // optional: have a better way to definitively query for these
          // or delete pending ones.
          sort: {
            created: 'DESC'
          },
          pageSize: 1
        }),
        fundingSources: this.get('store').findAll('funding-source')
      }).then(function (data) {
        var divisionUuid = data.client.get('divisionUuid');
        var session = _this.get('session');
        var token = session.get('data.authenticated.token');
        var graphqlQuery = {
          query: "\n                        query Division ($uuid: ID!)  {\n                            division(id: $uuid) {\n                                c2foDbId\n                                organizationUuid\n                            }\n                        }\n                    ",
          variables: {
            uuid: divisionUuid
          }
        };
        function getDivisionData() {
          return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open('POST', "".concat(_environment.default.APP.earlyPayApi, "/api/user-details/graphql"));
            xhr.onreadystatechange = handler;
            xhr.responseType = 'json';
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', "Bearer ".concat(token));
            xhr.send(JSON.stringify(graphqlQuery));
            function handler() {
              if (this.readyState === this.DONE) {
                if (this.status === 200) {
                  resolve(this.response);
                } else {
                  reject(new Error("getEarlyPayData failed with status [".concat(this.status, "]")));
                }
              }
            }
          });
        }
        return getDivisionData().then(function (response) {
          if (response.data != null) {
            var divisionId = response.data.division.c2foDbId;
            var organizationUuid = response.data.division.organizationUuid;
            data = Object.assign({
              earlyPayUrl: "".concat(_environment.default.APP.c2foAdmin, "/org/").concat(organizationUuid, "/division/").concat(divisionId, "?token=").concat(token)
            }, data);
          } else {
            Ember.Logger.error("Division not found: ".concat(divisionUuid));
          }
          return data;
        }, function (reason) {
          Ember.Logger.error("".concat(reason));
          return data;
        });
      }).then(function (data) {
        var productType = data.client.get('productType');
        var url = data.earlyPayUrl;
        // only get latest contract (for now)
        data.clientContract = data.clientContract.get('firstObject');
        data.adminDivisionUrl = url;
        data.clientUuid = clientUuid;
        data.tabs = Ember.A([Ember.Object.create({
          route: 'capfin.clients.show.detail',
          label: 'Detail'
        }), Ember.Object.create({
          route: 'capfin.clients.show.status',
          label: 'Onboarding'
        }), Ember.Object.create({
          route: 'capfin.clients.show.files',
          label: 'Files'
        }), Ember.Object.create({
          route: 'capfin.clients.show.debtors',
          label: 'debtors'
        }), Ember.Object.create({
          route: 'capfin.clients.show.invoices',
          label: 'Invoices'
        }), Ember.Object.create({
          route:
          /* This route differs based on whether the client's product type is Factoring
          or RF. */
          productType === 'FACTORING' ? 'capfin.clients.show.settlements' : 'capfin.clients.show.advances',
          label: productType === 'FACTORING' ? 'Settlements' : 'Advances'
        }), Ember.Object.create({
          route: 'capfin.clients.show.receipts',
          label: 'Receipts'
        }), Ember.Object.create({
          route: 'capfin.clients.show.bank',
          label: 'Bank Account'
        }), Ember.Object.create({
          route: 'capfin.clients.show.client-invoice-uploads',
          label: 'Invoice uploads'
        })]);
        return data;
      }).then(function (model) {
        var uuid = model.client.get('uuid');
        var productType = model.client.get('productType');
        var productBalanceModel = productType === 'FACTORING' ? 'factoring-client-balance' : 'rf-client-balance-view';
        return Ember.RSVP.hash(Object.assign(model, {
          balance: _this.get('store').findRecord(productBalanceModel, uuid)
        }));
      });
    },
    actions: {
      saveClient: function saveClient(client) {
        return client.save();
      },
      saveClientContract: function saveClientContract(contract) {
        var _this2 = this;
        return this.get('store').createRecord('client-contract', contract).save().then(function () {
          return _this2.refresh();
        });
      },
      updateClientContract: function updateClientContract(contract) {
        var _this3 = this;
        return contract.save().then(function () {
          return _this3.refresh();
        });
      },
      cancelClient: function cancelClient(client) {
        client.rollbackAttributes();
      },
      addDebtor: function addDebtor(options) {
        var client = options.client;
        return client.addDebtor({
          debtorUuid: options.debtor.get('uuid'),
          creditLimit: options.creditLimit,
          defaultAdvanceRate: options.advanceRate,
          defaultConcentration: options.defaultConcentration,
          isEnabled: options.isEnabled,
          isControlledRemittance: options.isControlledRemittance,
          termCodeIds: options.termCodeIds,
          invoiceVerificationRating: options.invoiceVerificationRating,
          invoiceVerificationNotes: options.invoiceVerificationNotes,
          bufferDays: options.bufferDays
        });
      },
      removeDebtor: function removeDebtor(client, debtor) {
        return client.removeDebtor(debtor);
      },
      refreshClientShowRoute: function refreshClientShowRoute() {
        this.refresh();
      }
    }
  });
});
