define("tpf-fund-manager/models/invoice", ["exports", "ember-data", "tpf-fund-manager/utils/model-util", "ember-api-actions"], function (_exports, _emberData, _modelUtil, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    uuid: _modelUtil.default.attr(),
    clientUuid: _modelUtil.default.attr(),
    debtorUuid: _modelUtil.default.attr(),
    clientDebtorUuid: _modelUtil.default.attr(),
    divisionUuid: _modelUtil.default.attr(),
    clientName: _modelUtil.default.attr(),
    debtorName: _modelUtil.default.attr(),
    companyName: _modelUtil.default.attr(),
    tpfImportUploadStatusId: _modelUtil.default.attr(),
    amount: _modelUtil.default.attr('currency'),
    netAmount: _modelUtil.default.attr('currency'),
    currency: _modelUtil.default.attr(),
    invoiceId: _modelUtil.default.attr(),
    orderId: _modelUtil.default.attr(),
    whiteOakInvoiceId: _modelUtil.default.attr(),
    dueDate: _modelUtil.default.attr('date'),
    projectedDueDate: _modelUtil.default.attr('date'),
    approvedDate: _modelUtil.default.attr('naive-date'),
    transactionDate: _modelUtil.default.attr('date'),
    removedDate: _modelUtil.default.attr('date'),
    removalReason: _modelUtil.default.attr(),
    removalNotes: _modelUtil.default.attr(),
    chargeBackDate: _modelUtil.default.attr(),
    status: _modelUtil.default.attr(),
    isVerified: _modelUtil.default.attr(),
    advanceAmount: _modelUtil.default.attr('currency'),
    created: _modelUtil.default.attr('date'),
    updated: _modelUtil.default.attr('date'),
    uploadedDate: _modelUtil.default.attr('date'),
    verificationReason: _modelUtil.default.attr(),
    verificationMethod: _modelUtil.default.attr(),
    verificationNote: _modelUtil.default.attr(),
    verificationPriority: _modelUtil.default.attr('string'),
    verificationReasonCode: _modelUtil.default.attr('string'),
    anomalyReasons: _modelUtil.default.attr(),
    editNotes: _modelUtil.default.attr(),
    rejectionReason: _modelUtil.default.attr('string'),
    rejectionNotes: _modelUtil.default.attr('string'),
    // aggregates from allocation:
    advanceAllocated: _modelUtil.default.attr('currency'),
    feeAllocated: _modelUtil.default.attr('currency'),
    receivedGrossAmount: _modelUtil.default.attr('currency'),
    // RF Past Due fields
    daysPastDue: _modelUtil.default.attr(),
    toBeRemovedOn: _modelUtil.default.attr('naive-date'),
    discountAmount: Ember.computed('amount', 'netAmount', function () {
      return Math.round((+this.get('amount') - +this.get('netAmount')) * 100) / 100;
    }),
    totalRepaidAmount: Ember.computed('advanceAllocated', 'feeAllocated', function () {
      return +this.get('feeAllocated') + +this.get('advanceAllocated');
    }),
    unreceivedGrossAmount: Ember.computed('amount', 'receivedGrossAmount', function () {
      return +this.get('amount') - +this.get('receivedGrossAmount');
    }),
    advanceUnallocated: Ember.computed('advanceAllocated', 'advanceAmount', function () {
      return this.get('advanceAmount') - this.get('advanceAllocated');
    }),
    isAuthorized: Ember.computed('status', function () {
      return this.get('status') === 'AUTHORIZED';
    }),
    isPrePurchased: Ember.computed('status', function () {
      return this.get('status') === 'PURCHASED';
    }),
    canDelete: Ember.computed('status', function () {
      return ['NEW', 'AUTHORIZED'].includes(this.get('status'));
    }),
    labelPath: Ember.computed('invoiceId', 'status', 'unreceivedGrossAmount', function () {
      return "".concat(this.get('invoiceId'), " - ").concat(this.get('status'), " - ").concat(this.get('unreceivedGrossAmount'));
    }),
    prioritizeVerification: (0, _emberApiActions.collectionAction)({
      path: 'prioritize-verification',
      type: 'POST'
    }),
    findByInvoiceOrderId: (0, _emberApiActions.memberAction)({
      path: 'find-by-invoice-order-id',
      type: 'get',
      after: _emberApiActions.serializeAndPush
    })
  });
});
