define("tpf-fund-manager/components/confirm-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * TODO: https://honeycomb.jira.com/browse/TPF-1516 replace material-modal component
   * usage across the app with this component.
   * Confirm dialog component with an affirm and cancel button using ember-paper theming.
   * @required buttonText || iconName - one of these must be present to show the button.  iconName
   *                                    takes precedence.
   * @param iconName      (optional) - Name of material icon for button.
   * @param iconSize      (optional) - sizes for the icon button : http://miguelcobain.github.io/ember-paper/#/components/icon
   * @param buttonText    required   - The text to appear on the dialog open button
   * @param affirmText    (optional) - the text to show on the affimration button
   * @param cancelText    (optional) - text to show for cancel action of the dialog
   * @param isDisabled    (optional) - Boolean value for whether the button should be disabled.
   * @param buttonClasses (optional) - Additional CSS styles to use on the dialog-triggering button
   * @param affirmAction  (optional) - Action to use for handling closing the dialog on affirmation.
   *                                   should return a promise that resolves to a truthy value to determine
   *                                   successful closing of the dialog.
   * @param dialogTitle   (optional) - Text for the title of the dialog
   * @param skipCleanup   (optional) - Boolean value for skipping the actions after the affirm promise. Use this when the affirm action destroys the component.
   */
  var _default = _exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var hasButton = !!this.get('buttonText') || !!this.get('iconName');
      Ember.assert('Must provide button text or icon name for confirm-dialog', hasButton);
      this.set('uuid', Ember.guidFor(this));
    },
    cancelText: 'Cancel',
    affirmText: 'Close',
    buttonClasses: '',
    isDisabled: null,
    dialogTitle: null,
    iconSize: 24,
    isDialogOpen: false,
    dialogOrigin: Ember.computed('uuid', function () {
      return ".dialog-target-".concat(this.get('uuid'));
    }).readOnly(),
    skipCleanup: false,
    actions: {
      affirm: function affirm() {
        var _this = this;
        var affirmAction = this.get('affirmAction');
        this.set('performingAffirmAction', true);
        if (!affirmAction) {
          return this.set('isDialogOpen', false);
        }
        var skipCleanup = this.get('skipCleanup');
        affirmAction().then(function (shouldClose) {
          if (!skipCleanup) {
            _this.set('performingAffirmAction', false);
            if (shouldClose) {
              _this.set('isDialogOpen', false);
            }
          }
        }).catch(function () {
          return _this.set('performingAffirmAction', false);
        });
      }
    }
  });
});
