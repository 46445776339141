define("tpf-fund-manager/routes/capfin/onboarding/rf/index", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        tabs: Ember.A([Ember.Object.create({
          route: 'capfin.onboarding.rf.index.prequalification',
          label: 'Pre-qualification'
        }), Ember.Object.create({
          route: 'capfin.onboarding.rf.index.diligence',
          label: 'Diligence'
        })])
      });
    }
  });
});
