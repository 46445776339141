define("tpf-fund-manager/routes/capfin/receivables-finance/invoices/index/-base-pending", ["exports", "@babel/runtime/helpers/esm/defineProperty", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin", "tpf-fund-manager/config/environment"], function (_exports, _defineProperty2, _c2foAuthenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var transitionTemplateDownloadUrl = "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/invoices/transition-template-download");
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    /**
     * @returns { [x: string]: any }
     */
    getFilters: function getFilters() {},
    /**
     * @returns string
     */
    getModelKey: function getModelKey() {},
    /**
     * @private
     */
    _getFilters: function _getFilters() {
      var _this$paramsFor = this.paramsFor('capfin.receivables-finance.invoices.index'),
        currentClientUuid = _this$paramsFor.currentClientUuid;
      var filters = {
        'status[$in]': ['PENDING'],
        productType: 'RECEIVABLES_FINANCE'
      };
      if (currentClientUuid !== 'all-clients') {
        filters.clientUuid = currentClientUuid;
      }
      return Object.assign(filters, this.getFilters());
    },
    model: function model() {
      var modelKey = this.getModelKey();
      var filters = this._getFilters();
      // add model hook specific filters like page size and sorting
      filters.pageSize = 25;
      filters.sort = {
        dueDate: 'ASC'
      };
      filters._modelName = 'invoice';
      return Ember.RSVP.hash(this.getPreviousFilters((0, _defineProperty2.default)({}, modelKey, filters)));
    },
    actions: {
      onDownloadInvoices: function onDownloadInvoices() {
        var filters = this._getFilters();
        // add the token since we're opening this in a different window.
        filters.token = this.get('session.data.authenticated.token');

        // serialize with the same method that ember-data does (basically)
        var params = Ember.$.param(filters);
        var url = "".concat(transitionTemplateDownloadUrl, "?").concat(params);
        var downloadWindow = window.open(url);
        downloadWindow.onload = function () {
          downloadWindow.close();
        };
      }
    }
  });
});
