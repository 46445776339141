define("tpf-fund-manager/routes/capfin/admin/term-code/new", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        TermCode: this.get('store').createRecord('term-code', {})
      });
    },
    actions: {
      saveTermCode: function saveTermCode(TermCode) {
        var _this = this;
        return TermCode.save().then(function () {
          _this.transitionTo('capfin.admin.term-code');
        });
      },
      cancelTermCode: function cancelTermCode(TermCode) {
        TermCode.rollbackAttributes();
        this.transitionTo('capfin.admin.term-code');
      }
    }
  });
});
