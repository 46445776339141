define("tpf-fund-manager/routes/capfin/all-receipts", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin", "tpf-fund-manager/utils/is-index-transition", "tpf-fund-manager/config/environment"], function (_exports, _c2foAuthenticatedRouteMixin, _isIndexTransition, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var stagedReceiptUploadUrl = "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/receipts/uploads");
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    toast: Ember.inject.service(),
    redirect: function redirect(model, transition) {
      if ((0, _isIndexTransition.default)(transition, 'capfin.all-receipts')) {
        this.transitionTo('capfin.all-receipts.receipts');
      }
    },
    uploadCashActivity: function uploadCashActivity(uploadUrl, file) {
      var _this = this;
      var controller = this.controllerFor(this.routeName);
      controller.set('cashActivityUploadInProgress', true);
      var token = this.get('session').get('data.authenticated.token');
      return file.uploadBinary(uploadUrl, {
        contentType: file.blob.type,
        headers: {
          authorization: "Bearer ".concat(token)
        }
      }).then(function (result) {
        if (result && Array.isArray(result.body)) {
          var failures = result.body.filter(function (x) {
            return x.status === 'FAILURE';
          });
          if (failures.length > 0) {
            failures.forEach(function (failure) {
              window.console.log('Cash activity upload response', failure);
              _this.get('toast').error("".concat(failure.error));
            });
          } else {
            _this.get('toast').success('Upload completed successfully');
          }
        } else {
          _this.get('toast').success('Upload completed successfully');
        }
        return result;
      }).catch(function (result) {
        if (result.body.message) {
          _this.get('toast').error(result.body.message);
        } else {
          _this.get('toast').error(result.body);
        }
      }).finally(function () {
        controller.set('cashActivityUploadInProgress', false);
      });
    },
    actions: {
      onCreateNew: function onCreateNew() {
        this.transitionTo('capfin.all-receipts.receipts.new');
      },
      onStagedReceiptUpload: function onStagedReceiptUpload(file) {
        var _this2 = this;
        this.get('uploadCashActivity').bind(this)("".concat(stagedReceiptUploadUrl, "/").concat(file.blob.name), file).then(function (result) {
          if (result.body.uuid) {
            _this2.transitionTo('capfin.review-receipt.show.summary', result.body.uuid);
          } else {
            _this2.refresh();
          }
        });
      }
    }
  });
});
