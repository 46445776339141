define("tpf-fund-manager/adapters/salesforce-adapter", ["exports", "tpf-fund-manager/adapters/application-v2-api"], function (_exports, _applicationV2Api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _applicationV2Api.default.extend({
    sfAccountIdAttr: 'Id',
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var sfId = query[this.get('sfAccountIdAttr')];
      return "".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType(modelName), "/").concat(sfId);
    },
    handleResponse: function handleResponse(status) {
      if (status >= 400) {
        return;
      }
      return this._super.apply(this, arguments);
    },
    sortQueryParams: function sortQueryParams() {
      var queryParams = this._super.apply(this, arguments);

      // Copy the query params and then delete the clientUuid.
      var newQueryParams = Object.assign({}, queryParams);
      delete newQueryParams['Id'];
      return newQueryParams;
    }
  });
});
