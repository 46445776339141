define("tpf-fund-manager/mixins/ember-simple-auth-authorizer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    headers: Ember.computed('session.isAuthenticated', 'session.data.authenticated.token', function () {
      var headers = {};
      if (this.get('session.isAuthenticated')) {
        headers['Authorization'] = "Bearer ".concat(this.get('session.data.authenticated.token'));
      }
      return headers;
    }),
    ajaxOptions: function ajaxOptions() {
      /**
       * https://github.com/simplabs/ember-simple-auth/issues/2046
       * https://github.com/simplabs/ember-simple-auth/blob/2.1.0/addon/mixins/data-adapter-mixin.js#L106
       *
       * Ember-simple-auth did something really weird with authorizers:
       * they deprecated them, but they made it very difficult to remove
       * the deprecation warning.
       *
       * Basically you have 3 options to get the jwt token onto the request
       * object:
       * - override the `headers` property somehow, either via mantually
       *   setting them or a computed property (we have done this)
       * - using an authorize() method on this adapter (deprecated)
       * - setting an authorizer: 'some:thing' property on the adapter,
       *   which ESA will use to look up an authorizer
       *
       * Now the latter 2 are deprecated, but unfortunately ESA will
       * do the following:
       * if (authorizerPropertyIsSet) {
       *     deprecateAuthorizerProperty()
       *     doStuffWithAuthorizerProperty()
       * } else {
       *     deprecateAuthorizeFunction()
       *     this.authorize()
       * }
       * Which means that it will _always_ deprecate
       *
       * The solution? Override the hook they overrode to log those
       * deprecations. Essentially our "inheritance chain" looks
       * like the following:
       * ApplicationAdapter -> ESA DataAdapterMixin -> DS.RestAdapter
       * Which means that if we want to access the RestAdapter ajaxOptions
       * code, we can't just call this._super(): that will log the deprecations.
       * Instead we have to manually bypass the DataAdapterMixin step in the
       * chain.
       *
       * We also cannot remove DataAdapterMixin, because we rely on it to
       * invalidate the session on a 401 response.
       *
       * Note: this can be removed when we upgrade to ESA 3.x, which at this
       * time is unreleased.
       */
      return _emberData.default.RESTAdapter.prototype.ajaxOptions.apply(this, arguments);
    }
  });
});
