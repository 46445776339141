import DS from 'ember-data';
import { memberAction } from 'ember-api-actions';

export default DS.Model.extend({
    uuid: DS.attr(),
    stagedReceiptUploadUuid: DS.attr(),
    clientUuid: DS.attr(),
    client: DS.attr(),
    debtorUuid: DS.attr(),
    debtor: DS.attr(),
    receivedDate: DS.attr('date'),
    amount: DS.attr('number'),
    paymentType: DS.attr(),
    paymentReferenceNumber: DS.attr(),
    pendingState: DS.attr(),
    allocationDate: DS.attr(),
    status: DS.attr(),
    isApproved: DS.attr('boolean'),
    isClientRemitted: DS.attr('boolean'),
    riskClassifications: DS.attr(),
    riskSeverity: DS.attr(),
    riskClassificationCount: DS.attr(),
    currency: DS.attr(),
    bankAccountId: DS.attr(),
    commit: memberAction({
        path: 'commit',
        type: 'post',
        urlType: 'findRecord'
    })
});
