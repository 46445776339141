define("tpf-fund-manager/helpers/receipt-upload-summary-past-future-dated", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.receiptUploadSummaryPastFutureDated = receiptUploadSummaryPastFutureDated;
  var errorRiskClassifications = ['PAST_DATED', 'FUTURE_DATED'];
  function receiptUploadSummaryPastFutureDated(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      riskClassification = _ref2[0];
    return !!(riskClassification || []).find(function (x) {
      return errorRiskClassifications.includes(x);
    });
  }
  var _default = _exports.default = Ember.Helper.helper(receiptUploadSummaryPastFutureDated);
});
