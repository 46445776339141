import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    uuid: Model.attr(),
    clientUuid: Model.attr(),
    clientDebtorUuid: Model.attr(),
    companyId: Model.attr(),
    displayName: Model.attr(),
    termCodeUuid: Model.attr(),
    invoiceIsIneligible: Model.attr(),
    created: Model.attr(),
    updated: Model.attr(),
    clientName: Model.attr(),
    debtorName: Model.attr()
});
