define("tpf-fund-manager/routes/capfin/testing/demo-gen", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    setupController: function setupController(controller) {
      controller.set('productTypes', Ember.A(['RECEIVABLES_FINANCE', 'FACTORING']));
    }
  });
});
