define("tpf-fund-manager/routes/capfin/debtors/show", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model(params) {
      var _this = this;
      var debtorUuid = params.debtor_uuid;
      var debtor = debtorUuid == 'new' ? this.get('store').createRecord('debtor', {}) : this.get('store').findRecord('debtor', debtorUuid);
      var address = debtorUuid == 'new' ? this.get('store').createRecord('address', {}) : this.get('store').query('address', {
        entityUuid: debtorUuid
      });
      var industryGroups = this.get('store').query('onboarding-industry-group', {
        sort: {
          groupDisplayName: 'DESC'
        },
        size: 100
      });
      return Ember.RSVP.hash({
        debtor: debtor,
        industryGroups: industryGroups,
        address: address
      }).then(function (results) {
        // always use the first address for now.
        // TODO: when we need more than one, we can fix this to allow
        // all the addresses to be modified
        results.address = results.address.get('firstObject');
        return results;
      }).then(function (results) {
        // Ensure we always have an address, even if it is not yet saved
        if (results.address === null || results.address === undefined) {
          results.address = _this.get('store').createRecord('address');
        }
        return results;
      });
    },
    actions: {
      saveDebtor: function saveDebtor(args) {
        var _this2 = this;
        if (args.debtor.get('hasDirtyAttributes')) {
          return args.debtor.save().then(function (savedDebtor) {
            // Force set the entityUuid here for creation
            var entityUuid = savedDebtor.get('uuid');
            args.address.set('entityUuid', entityUuid);
            args.address.save().then(function () {
              _this2.transitionTo('capfin.debtors');
            });
          });
        }
        return args.address.save().then(function () {
          _this2.transitionTo('capfin.debtors');
        });
      },
      cancelDebtor: function cancelDebtor(args) {
        args.debtor.rollbackAttributes();
        args.address.rollbackAttributes();
        this.transitionTo('capfin.debtors');
      }
    }
  });
});
