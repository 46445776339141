import Ember from 'ember';
import TabbedControllerMixin from 'tpf-fund-manager/mixins/tabbed-controller-mixin';
import config from 'tpf-fund-manager/config/environment';

export default Ember.Controller.extend(TabbedControllerMixin, {
    appController: Ember.inject.controller('application'),

    capfinAppUrl: Ember.computed(function() {
        return config.APP.capfinAppUrl;
    })
});
