define("tpf-fund-manager/fixtures/formation-structures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // company formation structures for applying businesses
  var _default = _exports.default = [{
    value: 'CORPORATION',
    display: 'Corporation'
  }, {
    value: 'LLC',
    display: 'LLC'
  }, {
    value: 'PARTNERSHIP',
    display: 'Partnership'
  }, {
    value: 'JOINT_VENTURE',
    display: 'Joint Venture'
  },
  // typo on some backend forms.
  {
    value: 'JOIN_VENTURE',
    display: 'Joint Venture'
  }, {
    value: '501_C_3',
    display: '501(c)(3)'
  }, {
    value: 'OTHER',
    display: 'Other'
  }];
});
