define("tpf-fund-manager/components/reference-rate-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: 'md-padding',
    actions: {
      saveReferenceRate: function saveReferenceRate(referenceRate) {
        return this.get('saveReferenceRate')(referenceRate);
      },
      cancelReferenceRate: function cancelReferenceRate(referenceRate) {
        return this.get('cancelReferenceRate')(referenceRate);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.types = ['PRIME', 'LIBOR', 'SOFR'];
    }
  });
});
