define("tpf-fund-manager/routes/capfin/onboarding/rf/index/prequalification", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      // TODO: Create risk aggregate query/view that also hits the tpfRisk table
      return Ember.RSVP.hash(this.getPreviousFilters({
        onboardingStatuses: {
          _modelName: 'onboarding-status',
          isFactoringClient: true,
          isTermSheetAccepted: false,
          sort: {
            isPreOnboardingSignatureComplete: 'DESC',
            isAdditionalBusinessComplete: 'DESC',
            isOfficerInfoComplete: 'DESC',
            isFinancialFilesComplete: 'DESC',
            isPostOnboardingSignatureComplete: 'DESC',
            applicationSignDate: 'DESC',
            isApprovedAndConfigured: 'DESC'
          }
        }
      }));
    }
  });
});
