define("tpf-fund-manager/serializers/users-search", ["exports", "tpf-fund-manager/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var KEYS = ['userAuthServiceUuid', 'clientUuid'];
  var _default = _exports.default = _application.default.extend({
    extractId: function extractId(modelClass, resourceHash) {
      // https://discuss.emberjs.com/t/composite-primary-keys/11967/3
      return KEYS.map(function (key) {
        return resourceHash[key];
      }).join('|');
    }
  });
});
