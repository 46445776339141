define("tpf-fund-manager/helpers/rich-text", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "tpf-fund-manager/utils/escape-string"], function (_exports, _slicedToArray2, _escapeString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.richText = richText;
  function richText(_ref /*, hash*/) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      rawText = _ref2[0];
    var escaped = (0, _escapeString.default)(rawText);
    return Ember.String.htmlSafe(escaped.replace(/(\\n|\\r)/gi, "<br/>"));
  }
  var _default = _exports.default = Ember.Helper.helper(richText);
});
