define("tpf-fund-manager/components/term-code-grid", ["exports", "tpf-fund-manager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    documentUploadUrl: "".concat(_environment.default.APP.tpfApi, "/term-codes/csv-upload"),
    actions: {
      onUploadComplete: function onUploadComplete() {
        this.get('TermCodes').update();
      }
    }
  });
});
