import DS from 'ember-data';

export default DS.Model.extend({
    uuid: DS.attr(),
    description: DS.attr(),
    majorGroupId: DS.attr(),
    group: DS.attr(),
    groupDisplayName: DS.attr(),
    locale: DS.attr()
});
