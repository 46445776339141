define("tpf-fund-manager/routes/capfin/clients/show/files", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    connect: Ember.inject.service(),
    modelBulkUpdate: Ember.inject.service(),
    model: function model() {
      var client = this.modelFor('capfin.clients.show').client;
      var divisionUuid = client.get('divisionUuid');
      var uuid = client.get('uuid');
      return Ember.RSVP.hash({
        clientRequiredDocuments: this.get('store').findRecord('client-required-document', uuid),
        onboardingStatus: this.get('store').findRecord('onboarding-status', divisionUuid),
        client: this.get('store').query('client', {
          divisionUuid: divisionUuid
        }),
        financialFiles: this.get('store').query('financial-file', {
          divisionUuid: divisionUuid,
          deleted: false,
          isClientUploaded: false,
          pageSize: 25
        }),
        financialFilesPostOnboard: this.get('store').query('financial-file', {
          divisionUuid: divisionUuid,
          deleted: false,
          isClientUploaded: true,
          pageSize: 25
        })
        // codatUrl: this.get('connect').getCompanyCodatUrl(divisionUuid)
      }).then(function (model) {
        var clientRequiredDocuments = model.clientRequiredDocuments;
        var onboardingStatus = model.onboardingStatus;
        var client = model.client.get('firstObject');
        var codatUrl = null;
        var financialFiles = model.financialFiles;
        var financialFilesPostOnboard = model.financialFilesPostOnboard;
        return {
          clientRequiredDocuments: clientRequiredDocuments,
          onboardingStatus: onboardingStatus,
          client: client,
          codatUrl: codatUrl,
          financialFiles: financialFiles,
          financialFilesPostOnboard: financialFilesPostOnboard
        };
      });
    },
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      },
      bulkUpdateFinancialFiles: function bulkUpdateFinancialFiles(params, actionSlug) {
        var modelBulkUpdate = this.get('modelBulkUpdate');
        return modelBulkUpdate.updateWithQueryParams(params, 'financial-file', actionSlug);
      }
    }
  });
});
