import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    name: Model.attr('string'),
    url: Model.attr('string'),
    employeeCount: Model.attr('integer'),
    naics: Model.attr('string'),
    annualSpend: Model.attr('currency'),
    revenue: Model.attr('currency'),
    organizationId: Model.attr('id'),
    created: Model.attr('date'),
    updated: Model.attr('date'),
    userId: Model.attr('id'),
    country: Model.attr('string'),
    eslapId: Model.attr('id'),
    companyInfo: Model.attr(), //json
    address1: Model.attr('string'),
    address2: Model.attr('string'),
    city: Model.attr('string'),
    state: Model.attr('string'),
    postalCode: Model.attr('string'),
    eslap: Model.attr(), //json
    uuid: Model.attr(),
    taxId: Model.attr('string'),
    wfcId: Model.attr('integer'),

    tpfLoans: DS.belongsTo('tpf-loan')
});
