import Ember from 'ember';

export default Ember.Component.extend({
    websiteUrl: Ember.computed('onboardingForm.website', function() {
        const website = this.get('onboardingForm.website');
        if (website && !website.startsWith('http')) {
            return 'http://' + website;
        }
        return website;
    })
});
