import Ember from 'ember';

export default Ember.Component.extend({
    tagName: '',
    page: 1,
    pageSize: 10,

    records: Ember.computed('data.[]', 'page', 'pageSize', function() {
        let data = this.get('data') || [];
        let start = (this.get('page') - 1) * this.get('pageSize');
        let end = this.get('page') * this.get('pageSize');
        return data.slice(start, end);
    }),
    pagesCount: Ember.computed('data.[]', 'pageSize', function() {
        return Math.ceil(this.get('data.length') / this.get('pageSize'));
    })
});
