import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    divisionId: Model.attr('id'),
    score: Model.attr('number', 'WFC Risk Score'),
    v1: Model.attr('number'),
    v2: Model.attr('number'),
    v3: Model.attr('number'),
    v4: Model.attr('number'),
    v5: Model.attr('number')
});
