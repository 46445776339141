define("tpf-fund-manager/components/debtor-form", ["exports", "tpf-fund-manager/utils/state-codes", "tpf-fund-manager/utils/country-codes"], function (_exports, _stateCodes, _countryCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Ensure the market is always in the default state when modifying.
  var _default = _exports.default = Ember.Component.extend({
    selectedIndustryGroup: Ember.computed('industryGroups', 'debtor.industryGroupUuid', function () {
      var uuid = this.get('debtor.industryGroupUuid');
      return this.get('industryGroups').find(function (item) {
        return item.get('uuid') === uuid;
      });
    }),
    actions: {
      updateIndustryGroup: function updateIndustryGroup(industryGroup) {
        this.set('debtor.industryGroupUuid', industryGroup.get('uuid'));
      },
      saveDebtor: function saveDebtor(args) {
        var debtor = args.debtor;
        var saveDebtor = this.get('saveDebtor');
        var name = debtor.get('name');
        debtor.set('billToCustomerName', name);
        var phone = debtor.get('billToPhone');
        if (debtor.get('riskRating') === '') {
          debtor.set('riskRating', null);
        }
        if (phone !== undefined) {
          debtor.set('billToPhone', phone.replace(/\D/g, ''));
        }
        return saveDebtor(args);
      },
      cancelDebtor: function cancelDebtor(args) {
        var cancelDebtor = this.get('cancelDebtor');
        return cancelDebtor(args);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.riskRatings = ['', 1, 2, 3, 4, 5];
      this.countryCodes = _countryCodes.default;
      this.stateCodes = _stateCodes.default;
    }
  });
});
