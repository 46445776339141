define("tpf-fund-manager/storages/ui-state", ["exports", "ember-local-storage/local/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Storage = _object.default.extend();
  Storage.reopenClass({
    initialState: function initialState() {
      return {
        groupedTransactions: false,
        expandedTransactions: true,
        showInvoiceBlacklistFilters: false,
        riskDetailRiskScore: true,
        riskDetailStatus: true,
        riskDetailSupplierOnboarding: true,
        riskDetailFinancialInformation: true,
        riskDetailFinancialFiles: false,
        riskDetailLoans: false,
        riskDetailCustomerConcentration: false,
        showLoanDetailCalendar: true
      };
    }
  });
  var _default = _exports.default = Storage;
});
