import DS from 'ember-data';

export default DS.Model.extend({
    uuid: DS.attr(),
    questionText: DS.attr(),
    locale: DS.attr(),
    isCurrent: DS.attr(),
    section: DS.attr(), // currently OFFICER_INFO_QUESTIONS only
    sortOrder: DS.attr(),
    linkedAnswer: DS.attr(),
    onboardingAdditionalQuestionAnswer: DS.hasMany('onboarding-additional-question-answer')
});
