define("tpf-fund-manager/components/bread-crumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // tpf breadcrumb
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['breadcrumb-item'],
    wormholeName: 'breadcrumb-wormhole',
    title: '',
    route: ''
  });
});
