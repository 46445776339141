define("tpf-fund-manager/helpers/to-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toJson = toJson;
  function toJson(obj) {
    return JSON.stringify(obj, null, 2);
  }
  var _default = _exports.default = Ember.Helper.helper(toJson);
});
