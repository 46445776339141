define("tpf-fund-manager/components/buyer-toggle-onboarding-status-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isExpanded: true,
    buyerToggleFilters: Ember.computed({
      get: function get() {
        return [{
          fieldName: 'isUnapproved',
          choices: [{
            value: true,
            name: 'Yes'
          }, {
            value: false,
            name: 'No'
          }],
          heading: 'Underwriting Disapproved'
        }, {
          fieldName: 'isApproved',
          choices: [{
            value: true,
            name: 'Yes'
          }, {
            value: false,
            name: 'No'
          }],
          heading: 'Approved'
        }, {
          fieldName: 'isPostOnboardingSignatureComplete',
          choices: [{
            value: true,
            name: 'Yes'
          }, {
            value: false,
            name: 'No'
          }],
          heading: 'Application Signed'
        }];
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.searchFieldNames = this.getWithDefault('searchFieldNames', ['name']);
    }
  });
});
