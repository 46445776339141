define("tpf-fund-manager/models/onboarding-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    uuid: _emberData.default.attr(),
    formUuid: _emberData.default.attr(),
    divisionId: _emberData.default.attr(),
    clientUuid: _emberData.default.attr(),
    clientName: _emberData.default.attr(),
    isPreOnboardingSignatureComplete: _emberData.default.attr(),
    isAdditionalBusinessComplete: _emberData.default.attr(),
    isOfficerInfoComplete: _emberData.default.attr(),
    isFinancialFilesComplete: _emberData.default.attr(),
    isPostOnboardingSignatureComplete: _emberData.default.attr(),
    isApprovedAndConfigured: _emberData.default.attr(),
    isUnderwritingDisapproved: _emberData.default.attr(),
    isBankAccountRequired: _emberData.default.attr(),
    isReadyForUnderwriting: _emberData.default.attr(),
    created: _emberData.default.attr(),
    updated: _emberData.default.attr(),
    applicationSignDate: _emberData.default.attr(),
    isAccountSystemConnected: _emberData.default.attr(),
    isManuallyCreated: _emberData.default.attr(),
    isPreQualificationFormCompleted: _emberData.default.attr(),
    isPreQualificationFinancialFilesComplete: _emberData.default.attr(),
    isTermSheetAccepted: _emberData.default.attr(),
    preQualificationCompleteDate: _emberData.default.attr('date'),
    salesRepEmail: _emberData.default.attr(),
    /**
     * A user is only ready to sign the application
     * if they have completed the previous steps' required
     * fields.
     */
    isReadyForSignApplication: Ember.computed.and('isPreOnboardingSignatureComplete', 'isAdditionalBusinessComplete', 'isOfficerInfoComplete', 'isFinancialFilesComplete'),
    /**
     * A user has already completed all sections, including signature,
     * and should be prevented from further editing.
     */
    applicationComplete: Ember.computed.and('isPreOnboardingSignatureComplete', 'isAdditionalBusinessComplete', 'isOfficerInfoComplete', 'isFinancialFilesComplete', 'isPostOnboardingSignatureComplete'),
    /**
     * Return a list of incomplete steps of onboarding.
     * Used for messaging to the user if they try to sign
     * the application before it is complete.
     */
    incompleteSections: Ember.computed('isPreOnboardingSignatureComplete', 'isAdditionalBusinessComplete', 'isOfficerInfoComplete', 'isFinancialFilesComplete', 'isPostOnboardingSignatureComplete', function () {
      var _this = this;
      var incompleteSections = Ember.A(['isPreOnboardingSignatureComplete', 'isAdditionalBusinessComplete', 'isOfficerInfoComplete', 'isFinancialFilesComplete', 'isPostOnboardingSignatureComplete']);
      return incompleteSections.filter(function (sectionStatus) {
        return !_this.get(sectionStatus);
      });
    })
  });
});
