import DS from 'ember-data';

export default DS.Model.extend({
    uuid: DS.attr(),
    divisionUuid: DS.attr(),
    isApproved: DS.attr('boolean'),
    isUnapproved: DS.attr('boolean'),
    created: DS.attr('date'),
    updated: DS.attr('date')
});
