import DS from 'ember-data';

export default DS.Model.extend({
    uuid: DS.attr(),
    text: DS.attr(),
    type: DS.attr(), // PRE_ONBOARDING, POST_ONBOARDING
    locale: DS.attr(),
    isCurrent: DS.attr(),
    published: DS.attr() // might not need
});
