import DS from 'ember-data';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
    uuid: DS.attr(),
    onboardingFormUuid: DS.attr(),
    name: DS.attr(),
    next12MonthVolume: DS.attr(),
    invoiceTerms: DS.attr(),
    type: DS.attr() // SUPPLIER, CUSTOMER
});
