define("tpf-fund-manager/components/feathers-range-filter", ["exports", "tpf-fund-manager/templates/components/feathers-range-filter"], function (_exports, _feathersRangeFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _feathersRangeFilter.default,
    classNames: 'feathers-filter feathers-range-filter',
    filterService: Ember.inject.service('filter-state'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('uuid', Ember.guidFor(this));
      this.setup();
    },
    setup: function setup() {
      this.filterService = this.get('filterService');
      var data = this.get('data');
      var fieldName = this.get('field');
      var existingFilters = this.filterService.getParam(data.modelName, fieldName);
      // only update if we have a valid range filter.
      if (existingFilters && Ember.typeOf(existingFilters) === 'object') {
        data.query[fieldName] = existingFilters;
        Ember.run.debounce(data, data.update, 200);
      }
    },
    /**
     * The minimum value of the range filter. Updated anytime the data store changes.
     */
    minimumValue: Ember.computed('data.isUpdating', {
      get: function get() {
        var data = this.get('data');
        return this.getValue(data, 'gte');
      },
      /**
       * The component lifecycle calls the set before calling the action.
       * So if the set does not return the value, the searchValue will
       * get an undefined value.
       */
      set: function set(key, value) {
        return value;
      }
    }),
    /**
     * The maximum value of the range filter. Updated anytime the data store changes.
     */
    maximumValue: Ember.computed('data.isUpdating', {
      get: function get() {
        var data = this.get('data');
        return this.getValue(data, 'lte');
      },
      /**
       * The component lifecycle calls the set before calling the action.
       * So if the set does not return the value, the issueSearch will
       * get an undefined value.
       */
      set: function set(key, value) {
        return value;
      }
    }),
    /**
     * Gets the value off of the current data query on the datastore for the particular operator.
     *
     * @param data - data store
     * @param operator - the operator such as 'gte' or 'lte'
     * @returns {*}
     */
    getValue: function getValue(data, operator) {
      var fieldName = this.get('field');
      var fieldValue = data.query[fieldName] || {};
      if (!fieldValue[operator]) {
        return null;
      } else {
        return fieldValue[operator];
      }
    },
    /**
     * Adds the value to the data stores query.
     *
     * @param data - the data store
     * @param selected - the selected value.
     * @param operator - the operator.
     */
    searchValue: function searchValue(data, selected, operator) {
      var fieldName = this.get('field');
      var fieldValue = data.query[fieldName];
      if (fieldValue === undefined) {
        fieldValue = {};
      }
      var value = selected ? selected : null;

      //If the value is empty then delete it.
      if (!value) {
        delete fieldValue[operator];
        this.filterService.deleteParam(data.modelName, fieldName);
      } else {
        fieldValue[operator] = value;
      }

      // Don't put empty objects as filters
      if (Object.keys(fieldValue).length > 0) {
        this.filterService.setParam(data.modelName, fieldName, fieldValue);
        data.query[fieldName] = fieldValue;
      }
    },
    /**
     * Updates the query.
     *
     * @param data - data store.
     */
    rerenderView: function rerenderView(data) {
      var _this = this;
      data.update().then(function () {
        _this.rerender();
      });
    },
    actions: {
      onMinimumValueSelect: function onMinimumValueSelect(selected) {
        var data = this.get('data');
        this.searchValue(data, selected, 'gte');
        this.rerenderView(data);
      },
      onMaximumValueSelect: function onMaximumValueSelect(selected) {
        var data = this.get('data');
        this.searchValue(data, selected, 'lte');
        this.rerenderView(data);
      },
      onClear: function onClear(operator) {
        var data = this.get('data');
        this.searchValue(data, null, operator);
        this.rerenderView(data);
      }
    }
  });
});
