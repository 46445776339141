define("tpf-fund-manager/helpers/background-image-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.backgroundImageStyle = backgroundImageStyle;
  _exports.default = void 0;
  function backgroundImageStyle(params) {
    return Ember.String.htmlSafe("background-image: url('" + params[0] + "');");
  }
  var _default = _exports.default = Ember.Helper.helper(backgroundImageStyle);
});
