define("tpf-fund-manager/mixins/tabbed-controller-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Mixin to be used with an ember controller to provide state information on
   * situations where you need to child route state, e.g. if you have tabs or
   * other subnav showing subroutes within one page of your application.
   */
  var _default = _exports.default = Ember.Mixin.create({
    router: Ember.inject.service(),
    activeRouteName: Ember.computed('router.currentRouteName', {
      get: function get() {
        return this.get('router.currentRouteName');
      }
    }),
    refreshRoute: function refreshRoute() {
      return this.send('refreshRoute');
    }
  });
});
