import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    uuid: Model.attr(),
    verificationMethod: Model.attr(),
    note: Model.attr(),
    created: Model.attr('date'),
    updated: Model.attr('date'),
    approvedDate: Model.attr(),
    query: Model.attr(),
    invoices: DS.hasMany('Invoice')
});
