define("tpf-fund-manager/components/client-debtors", ["exports", "tpf-fund-manager/fixtures/client-debtor-invoice-verification-ratings"], function (_exports, _clientDebtorInvoiceVerificationRatings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    clientDebtorInvoiceVerificationRatings: _clientDebtorInvoiceVerificationRatings.default,
    store: Ember.inject.service(),
    debtorFormValid: Ember.computed.and('newDebtor'),
    isControlledRemittance: true,
    actions: {
      addDebtorDialog: function addDebtorDialog() {
        var currentClientDebtor = this.get('store').createRecord('client-debtor', {});
        this.set('currentClientDebtor', currentClientDebtor);
        this.set('showAddDebtorDialog', true);
      },
      addDebtor: function addDebtor() {
        var _this = this;
        var saveDebtor = this.get('addDebtor');
        if (this.get('invoiceVerificationRating') === '') {
          this.set('invoiceVerificationRating', null);
        }
        var options = {
          client: this.get('client'),
          debtor: this.get('newDebtor'),
          isEnabled: this.get('isEnabled'),
          isControlledRemittance: this.get('isControlledRemittance'),
          termCodeIds: this.get('currentClientDebtor.termCodes').map(function (termCode) {
            return termCode.get('uuid');
          }),
          invoiceVerificationRating: this.get('invoiceVerificationRating'),
          invoiceVerificationNotes: this.get('invoiceVerificationNotes'),
          bufferDays: this.get('bufferDays')
        };

        // strip null keys.
        Object.keys(options).forEach(function (k) {
          if (Ember.isNone(options[k])) {
            delete options[k];
          }
        });
        return saveDebtor(options).then(function () {
          _this.send('clearDebtorForm');
          _this.set('showAddDebtorDialog', false);
          return _this.get('refreshClient')();
        });
      },
      clearDebtorForm: function clearDebtorForm() {
        this.setProperties({
          newDebtor: null,
          fundingSource: null,
          isEnabled: false,
          isControlledRemittance: true,
          invoiceVerificationRating: null,
          invoiceVerificationNotes: ''
        });
      }
    }
  });
});
