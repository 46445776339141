define("tpf-fund-manager/routes/capfin/review-receipt/show/summary", ["exports", "tpf-fund-manager/mixins/c2fo-authenticated-route-mixin"], function (_exports, _c2foAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_c2foAuthenticatedRouteMixin.default, {
    model: function model() {
      var _this = this;
      var stagedReceiptUploadUuid = this.paramsFor('capfin.review-receipt.show').stagedReceiptUploadUuid;
      var needsReview = this.get('store').query('staged-debtor-remittance', {
        stagedReceiptUploadUuid: stagedReceiptUploadUuid,
        'riskSeverity[$in]': ['1', '2', '3', '4'],
        'status[$in]': ['IMPORTED', 'LINKED', 'INVALID', 'CHANGED']
      });
      var noRisk = this.get('store').query('staged-debtor-remittance', {
        stagedReceiptUploadUuid: stagedReceiptUploadUuid,
        riskSeverity: '0',
        'status[$in]': ['IMPORTED', 'LINKED', 'INVALID', 'CHANGED']
      });
      var reviewed = this.get('store').query('staged-debtor-remittance', {
        stagedReceiptUploadUuid: stagedReceiptUploadUuid,
        'status[$in]': ['COMMITTED', 'REJECTED']
      });
      var failed = this.get('store').query('staged-debtor-remittance', {
        stagedReceiptUploadUuid: stagedReceiptUploadUuid,
        'status[$in]': ['FAILED']
      });
      return Ember.RSVP.hash({
        needsReviewRemittance: needsReview,
        noRiskRemittance: noRisk,
        reviewedRemittance: reviewed,
        failedRemittance: failed,
        stagedReceiptUpload: this.get('store').findRecord('staged-receipt-upload', stagedReceiptUploadUuid),
        stagedReceiptUploadUuid: stagedReceiptUploadUuid
      }).then(function (model) {
        var stillProcessingReceipts = _this.hasStatusProcessingReceipts(model.noRiskRemittance);
        return Object.assign(model, {
          stillProcessingReceipts: stillProcessingReceipts
        });
      });
    },
    /**
     * How many of our receipts are still "linking" on the backend.
     * @param {*} remittanceList
     */
    hasStatusProcessingReceipts: function hasStatusProcessingReceipts(remittanceList) {
      // see https://github.com/C2FO/tpf-fund-manager/blob/ff34f21b66da09eda1bcc86f1fb5f62821de1b52/app/helpers/receipt-upload-summary-display-status.js#L7
      return remittanceList.filter(function (remittance) {
        return remittance.get('status') === 'IMPORTED';
      }).length;
    },
    actions: {
      toAllocations: function toAllocations(stagedDebtorRemittanceSummary) {
        var uuid = stagedDebtorRemittanceSummary.get('uuid');
        var stagedReceiptUploadUuid = stagedDebtorRemittanceSummary.get('stagedReceiptUploadUuid');
        this.transitionTo('capfin.review-receipt.show.debtor-remittance.show.allocations', {
          stagedReceiptUploadUuid: stagedReceiptUploadUuid,
          stagedDebtorRemittanceUuid: uuid
        });
      },
      submitRemaining: function submitRemaining(stagedReceiptUploadUuid) {
        this.transitionTo('capfin.review-receipt.show.success', {
          stagedReceiptUploadUuid: stagedReceiptUploadUuid
        });
      },
      returnToAllUploads: function returnToAllUploads() {
        this.transitionTo('capfin.all-receipts.uploads');
      },
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});
