define("tpf-fund-manager/components/buyer-toggle-client-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      updateBuyerToggleClientStatus: function updateBuyerToggleClientStatus(buyerToggleClient, refreshRouteFunc) {
        var _this = this;
        buyerToggleClient.save().then(function (buyerToggleClientResponse) {
          _this.set('buyerToggleClient', buyerToggleClientResponse);
          refreshRouteFunc();
        });
      }
    }
  });
});
