define("tpf-fund-manager/adapters/client-settlement-passthrough", ["exports", "tpf-fund-manager/adapters/application-v2-patch-api"], function (_exports, _applicationV2PatchApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _applicationV2PatchApi.default.extend({
    pathForType: function pathForType() {
      return 'client-settlements/passthroughs';
    }
  });
});
