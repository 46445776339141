define("tpf-fund-manager/utils/escape-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = escapeString;
  function escapeString(str) {
    return str.replace(/["'\\\n\r\u2028\u2029]/g, function (character) {
      // Escape all characters not included in SingleStringCharacters and
      // DoubleStringCharacters on
      // http://www.ecma-international.org/ecma-262/5.1/#sec-7.8.4
      switch (character) {
        case '"':
        case "'":
        case '\\':
          return '\\' + character;
        case '\n':
          return '\\n';
        case '\r':
          return '\\r';
        case "\u2028":
          return "\\u2028";
        case "\u2029":
          return "\\u2029";
      }
    });
  }
});
