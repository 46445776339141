import DS from 'ember-data';
import { memberAction } from 'ember-api-actions';

export default DS.Model.extend({
    uuid: DS.attr(),
    fileName: DS.attr(),
    s3Path: DS.attr(),
    downloadPath: DS.attr(),
    status: DS.attr(),
    lastUpdated: DS.attr(),
    totalAmount: DS.attr(),
    totalCount: DS.attr(),
    successfulCount: DS.attr(),
    pendingReviewCount: DS.attr(),
    parseErrors: DS.attr(),
    commit: memberAction({
        path: 'commit',
        type: 'post',
        urlType: 'findRecord'
    }),
    summary: memberAction({
        path: 'summary',
        type: 'get'
    })
});
