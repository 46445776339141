define("tpf-fund-manager/components/onboarding-diligence-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {},
    searchFields: Ember.computed({
      get: function get() {
        return ['name'];
      }
    }),
    onboardingFilters: Ember.computed({
      get: function get() {
        return [{
          fieldName: 'isUnderwritingDisapproved',
          choices: [{
            value: true,
            name: 'Yes'
          }, {
            value: false,
            name: 'No'
          }],
          heading: 'Underwriting Disapproved'
        }, {
          fieldName: 'isApprovedAndConfigured',
          choices: [{
            value: true,
            name: 'Yes'
          }, {
            value: false,
            name: 'No'
          }],
          heading: 'Approved'
        }, {
          fieldName: 'isPostOnboardingSignatureComplete',
          choices: [{
            value: true,
            name: 'Yes'
          }, {
            value: false,
            name: 'No'
          }],
          heading: 'Application Signed'
        }];
      }
    })
  });
});
