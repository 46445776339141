define("tpf-fund-manager/controllers/capfin/factoring/invoices/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ['factoringClientUuid'],
    factoringClientUuid: 'all-clients',
    router: Ember.inject.service(),
    activeRouteName: Ember.computed('router.currentRouteName', {
      get: function get() {
        return this.get('router.currentRouteName');
      }
    }),
    clientOptions: Ember.computed('model.allPendingInvoices.[]', function () {
      var allClients = [{
        clientUuid: 'all-clients',
        clientName: 'All Clients'
      }];
      var invoices = this.get('model.allPendingInvoices');
      if (!invoices) {
        return allClients;
      }
      return allClients.concat(invoices.uniqBy('clientUuid'));
    })
  });
});
