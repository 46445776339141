define("tpf-fund-manager/helpers/format-date", ["exports", "moment", "tpf-fund-manager/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;
  function formatDate(params, options) {
    var date = params[0];
    if (!date) {
      return _environment.default.formatHelpers['noValue'];
    }
    var format = 'YYYY-MM-DD';
    var newDate = _moment.default.utc(date);
    if (options && options.timestamp) {
      format = 'YYYY-MM-DD h:mm:ss a';
      newDate = (0, _moment.default)(date);
    }
    if (options && options.format) {
      format = options.format;
    }
    return newDate.format(format);
  }
  var _default = _exports.default = Ember.Helper.helper(formatDate);
});
