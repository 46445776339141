import DS from 'ember-data';

export default DS.Model.extend({
    uuid: DS.attr(),
    reportType: DS.attr(),
    created: DS.attr(),
    fromDate: DS.attr(),
    toDate: DS.attr(),
    reportUploadStatus: DS.attr()
});
