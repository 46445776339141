define("tpf-fund-manager/services/model-bulk-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    /**
     * Does a bulk update using the data and query to determine the columns and the
     * records to update.
     *
     * @param data - a key value pair with the the column and the value to update to.
     * @param query - the query to use when updating the tables records.
     * @param modelName - the Ember modelName the query should use when building a url.
     * @param adapterName - the Ember adapter name used to build the url.  If none is provided,
     *      It is assumed the adapterName is the same as the modelName.
     * @param actionSlug - if the model has a more specific path, that slug of the url
     * @returns {*}
     */
    update: function update(data, query, modelName, adapterName) {
      var actionSlug = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
      Ember.assert('You must provide the data to update', data);
      Ember.assert('You must provide the query for the update', query);
      Ember.assert('You must provide the modelName for the update', modelName);
      adapterName = adapterName || modelName;
      var queryCopy = Ember.copy(query);
      delete queryCopy.sort;
      delete queryCopy.pageSize;
      var requestMethod = 'PATCH';
      var adapter = this.get('store').adapterFor(adapterName);
      var url = adapter.buildURL(modelName, null, null, 'updateRecord');
      url = url + "".concat(actionSlug, "?") + Ember.$.param(queryCopy);
      return adapter.ajax(url, requestMethod, {
        data: data
      });
    },
    /**
     * Does a bulk update using the data and query parameters to determine the columns and the
     * records to update.  Also accepts an actionSlug which is assumed to be a path nested under
     * the ember model's base path.
     * @param {*} params - filter parameters for the model, to be passed as query params.
     * @param {*} modelName
     * @param {*} actionSlug - optional end-url slug, assumed as the last part of the path, after ember
     *  model base path.
     */
    updateWithQueryParams: function updateWithQueryParams(params, modelName) {
      var actionSlug = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      Ember.assert('You must provide the params for the update', params);
      Ember.assert('You must provide the modelName for the update', modelName);
      var requestMethod = 'PATCH';
      var adapter = this.get('store').adapterFor(modelName);
      var url = adapter.buildURL(modelName, null, null, 'updateRecord');
      url = url + "".concat(actionSlug, "?") + Ember.$.param(params);
      return adapter.ajax(url, requestMethod);
    }
  });
});
