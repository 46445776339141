define("tpf-fund-manager/components/feathers-value-filter", ["exports", "tpf-fund-manager/templates/components/feathers-value-filter"], function (_exports, _feathersValueFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FETCH_DELAY = 800;
  var _default = _exports.default = Ember.Component.extend({
    layout: _feathersValueFilter.default,
    filterService: Ember.inject.service('filter-state'),
    classNames: 'feathers-filter feathers-value-filter',
    optionLimit: 100,
    checkAllThreshold: 25,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('uuid', Ember.guidFor(this));
    },
    hiddenOptionCount: Ember.computed('data.isUpdating', 'choices', 'optionLimit', {
      get: function get() {
        return this.get('choices').length + this.get('optionLimit');
      }
    }),
    /**
     * The filters options. Recomputed any time the data store updates.
     */
    options: Ember.computed('data.isUpdating', 'choices', 'optionLimit', {
      get: function get() {
        var data = this.get('data');
        return data ? this.getOptions(data) : [];
      }
    }),
    hasChecked: Ember.computed('fieldValues', {
      get: function get() {
        var fieldValues = this.get('fieldValues');
        return !Ember.isEmpty(fieldValues);
      }
    }),
    shouldShowCheckAll: Ember.computed('options', {
      get: function get() {
        return this.get('options').length > this.get('checkAllThreshold');
      }
    }).readOnly(),
    hasCheckedAll: Ember.computed('fieldValues', 'options', {
      get: function get() {
        if (!this.get('shouldShowCheckAll')) {
          return false;
        }
        var options = this.get('options');
        return options.every(function (option) {
          return option.checked === 'checked' || option.disabled;
        });
      }
    }).readOnly(),
    /**
     * Gets all the options and whether they are checked or not.
     * Only returns ```this.optionLimit``` results to speed up renders.
     *
     * @param data - that data store used to determine whether a filter is checked.
     * @returns {*}
     */
    getOptions: function getOptions(data) {
      var fieldName = this.get('field');
      var fieldValues = this.getFieldValues(data, fieldName);
      var excludeValues = data.query["".concat(fieldName, "[$notIn]")] || [];
      var checkedValues = {};
      fieldValues.forEach(function (value) {
        return checkedValues[value] = 'checked';
      });

      // limit options to a reasonable amount, prevents long renders.
      // avoid map/filter as it causes extra iterations.
      var choices = this.getWithDefault('choices', []);
      var choicesLen = choices.length;
      var limit = this.get('optionLimit') || choicesLen;
      var options = [];
      for (var i = 0; i < limit && i < choicesLen; i++) {
        options[i] = Ember.Object.create({
          value: choices[i].value,
          name: choices[i].name,
          checked: this.isChecked(fieldValues, choices[i].value),
          disabled: excludeValues.indexOf(choices[i].value) !== -1,
          id: "".concat(choices[i].name, "-").concat(this.get('uuid'))
        });
      }
      return options;
    },
    /**
     * Checks if the value should be checked.  If it's an array it makes sure they are all
     * in the query to determine if it's checked.
     *
     * @param fieldValues - the values in the query.
     * @param value - the filter value to determined if it should be checked.
     * @returns a boolean whether the filter should be checked.
     */
    isChecked: function isChecked(fieldValues, value) {
      var checkedValues = {};
      fieldValues.forEach(function (value) {
        checkedValues[value] = 'checked';
      });

      //If it's an array it checks that they all exist in the query.
      if (Array.isArray(value)) {
        var checked = true;
        value.forEach(function (value) {
          checked = checked && checkedValues[value];
        });
        return checked || '';
      } else {
        return checkedValues[value] || '';
      }
    },
    /**
     * Adds or removes the selected filter value based on the current query of the data store.
     *
     * @param data - the data store.
     * @param selected - the selected filter.
     */
    applyCheck: function applyCheck(data, selected) {
      var _this = this;
      var fieldName = this.get('field');
      var fieldValues = this.getFieldValues(data, fieldName);
      var value = selected.get('value');
      if (Array.isArray(value)) {
        value.forEach(function (value) {
          _this.addOrRemoveValue(value, fieldValues);
        });
      } else {
        this.addOrRemoveValue(value, fieldValues);
      }
      this.setExistingFilters(data, fieldName, fieldValues);
      data.query["".concat(fieldName, "[$in]")] = fieldValues;

      // Remove the filter if it's empty
      if (fieldValues.length === 0) {
        delete data.query[fieldName];
        delete data.query["".concat(fieldName, "[$in]")];
      }
    },
    /**
     * Adds or removes the value from the filter.
     *
     * @param value - the value to add or remove.
     * @param fieldValues - the current values in the query.
     */
    addOrRemoveValue: function addOrRemoveValue(value, fieldValues) {
      // Set or remove the value based on if it's there
      var index = fieldValues.indexOf(value);
      if (index > -1) {
        fieldValues.splice(index, 1);
      } else {
        fieldValues.push(value);
      }
    },
    _clearFilters: function _clearFilters(data, fieldName) {
      delete data.query[fieldName];
      delete data.query["".concat(fieldName, "[$in]")];
    },
    getFieldValues: function getFieldValues(data, fieldName) {
      var fieldValues = [];
      if (data.query[fieldName] && data.query[fieldName].length > 0) {
        fieldValues = data.query[fieldName];
      } else if (data.query["".concat(fieldName, "[$in]")] && data.query["".concat(fieldName, "[$in]")].length > 0) {
        fieldValues = data.query["".concat(fieldName, "[$in]")];
      }

      // Make sure we have an array
      if (fieldValues === undefined || fieldValues.length < 1) {
        fieldValues = [];
      }
      this.set('fieldValues', fieldValues);
      return fieldValues;
    },
    setExistingFilters: function setExistingFilters(data, fieldName, fieldValues) {
      this.get('filterService').setParam(data.modelName, "".concat(fieldName, "[$in]"), fieldValues);
    },
    actions: {
      checkFilter: function checkFilter(selected) {
        var _this2 = this;
        if (selected.disabled) return;
        this.set('isLoading', true);
        Ember.set(selected, 'isLoading', true);
        var data = this.get('data');
        this.applyCheck(data, selected);

        // allow checkbox animation to complete before updating.
        Ember.run.later(function () {
          data.update().finally(function () {
            _this2.set('isLoading', false);
            Ember.set(selected, 'isLoading', false);
            _this2.rerender();
          });
        }, FETCH_DELAY);
      },
      clearFilters: function clearFilters() {
        var _this3 = this;
        this.set('isClearingAll', true);
        var data = this.get('data');
        var fieldName = this.get('field');
        this._clearFilters(data, fieldName);
        this.setExistingFilters(data, fieldName, []);
        Ember.run.later(function () {
          data.update().finally(function () {
            _this3.set('isClearingAll', false);
            _this3.rerender();
          });
        }, FETCH_DELAY);
      },
      checkAll: function checkAll() {
        var _this4 = this;
        if (this.get('hasCheckedAll')) {
          return this.send('clearFilters');
        }
        var options = this.get('options');
        var data = this.get('data');
        this.set('isCheckingAll', true);
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;
        try {
          for (var _iterator = options[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var option = _step.value;
            if (option.disabled || option.checked) {
              continue;
            }
            this.applyCheck(data, option);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
        return data.update().finally(function () {
          _this4.set('isCheckingAll', false);
          _this4.rerender();
        });
      },
      showMore: function showMore() {
        this.set('optionLimit', this.get('choices').length);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.fieldValues = [];
    }
  });
});
