define("tpf-fund-manager/components/month-end-factoring-reports", ["exports", "tpf-fund-manager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    baseUploadUrl: "".concat(_environment.default.APP.tpfSupplierApiV2, "/v2/tpf-api/admin/month-end-factoring-report-uploads"),
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.isGeneratingSummaryReport = false;
      this.isGeneratingInvoicesReport = false;
      this.isGeneratingFeesReport = false;
    },
    actions: {
      regenSummaryReport: function regenSummaryReport() {
        this.set('isGeneratingSummaryReport', true);
        fetch("".concat(this.get('baseUploadUrl'), "/summary"), {
          method: 'POST',
          headers: {
            Authorization: "Bearer ".concat(this.get('session.data.authenticated.token')),
            'Content-Type': 'application/json'
          }
        });
      },
      regenInvoicesReport: function regenInvoicesReport() {
        this.set('isGeneratingInvoicesReport', true);
        fetch("".concat(this.get('baseUploadUrl'), "/invoices"), {
          method: 'POST',
          headers: {
            Authorization: "Bearer ".concat(this.get('session.data.authenticated.token')),
            'Content-Type': 'application/json'
          }
        });
      },
      regenFeesReport: function regenFeesReport() {
        this.set('isGeneratingFeesReport', true);
        fetch("".concat(this.get('baseUploadUrl'), "/fees"), {
          method: 'POST',
          headers: {
            Authorization: "Bearer ".concat(this.get('session.data.authenticated.token')),
            'Content-Type': 'application/json'
          }
        });
      },
      regenStatement: function regenStatement() {
        this.set('isGeneratingStatement', true);
        fetch("".concat(this.get('baseUploadUrl'), "/statement"), {
          method: 'POST',
          headers: {
            Authorization: "Bearer ".concat(this.get('session.data.authenticated.token')),
            'Content-Type': 'application/json'
          }
        });
      }
    }
  });
});
