define("tpf-fund-manager/adapters/application-kt-api", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "tpf-fund-manager/config/environment", "ember-inflector", "tpf-fund-manager/mixins/ember-simple-auth-authorizer"], function (_exports, _emberData, _dataAdapterMixin, _environment, _emberInflector, _emberSimpleAuthAuthorizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // application level adapter for the tpf-supplier-api (java/kotlin api)
  // THE ORDER OF MIXINS IS IMPORTANT
  var _default = _exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, _emberSimpleAuthAuthorizer.default, {
    session: Ember.inject.service('session'),
    toast: Ember.inject.service(),
    host: _environment.default.APP.tpfSupplierApi,
    namespace: 'wfc-api/admin',
    pathForType: function pathForType(type) {
      var dasherized = Ember.String.dasherize(type);
      return (0, _emberInflector.pluralize)(dasherized);
    },
    handleResponse: function handleResponse(status, headers, payload) {
      var session = this.get('session');

      // Update the JWT on each response from the server.
      // This keeps the session alive over time as long
      // as the user is doing something with data inside
      // the application.
      if (payload && payload.jwt) {
        var authData = session.get('data');
        if (authData.authenticated) {
          // Use set so the token is actually updated.
          // Disable until supplier api auth token supports auth specific tokens
          // Refreshing admin tokens in supplier api is actually shortening the length
          // session.set('data.authenticated.token', payload.jwt);
        }
      }
      if (headers && headers['authorization']) {
        var _authData = session.get('data');
        if (_authData.authenticated) {
          // Use set so the token is actually updated.
          // Disable until supplier api auth token supports auth specific tokens
          // Refreshing admin tokens in supplier api is actually shortening the length
          // session.set('data.authenticated.token', headers['authorization']);
        }
      }
      switch (session && status) {
        case 401:
          return session.invalidate();
        default:
          if (session && status >= 400 && status !== 403) {
            var err = feathersErrorResponse.call(this, status, headers, payload, session);
            var errorMessages = err.errors.map(function (err) {
              return err.detail;
            }).join('<br/>');
            this.get('toast').error(errorMessages, err.name);
            return err;
          }
          return this._super.apply(this, arguments);
      }
    },
    // Sanitize/convert unsupported params to supported query actions.
    query: function query(store, type, _query) {
      var newQuery = Ember.copy(_query, true);

      // set the query.$limit
      if (!Ember.isEmpty(_query.pageSize)) {
        newQuery.size = _query.pageSize;
        delete newQuery.pageSize;
      }
      return this._super(store, type, newQuery);
    } // TODO: right now PATCH is throwing a NPE from the api (in onboarding).  PUT seems to work.
    // figure out where the nullpointer is happening.
    // updateRecord(store, type, snapshot) {
    //     let data = {};
    //     let serializer = store.serializerFor(type.modelName);
    //     serializer.serializeIntoHash(data, type, snapshot);
    //     let id = snapshot.id;
    //     let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
    //     // TODO: if we want to only send changed attributes, enable this and test it.
    //     // // Use data directly because serializeIntoHash gives the object directly differently for feathers.
    //     // const dataKeys = Object.keys(data);
    //     // // Ensure code only sends the data we are actually updating on a record.
    //     // dataKeys.forEach((key) => {
    //     //     if (key in snapshot.changedAttributes()) {
    //     //         submittedData[key] = data[key];
    //     //     }
    //     // });
    //     return this.ajax(url, "PATCH", { data: data });
    // }
  });
  function feathersErrorResponse(status, headers, payload) {
    var errors = [{
      detail: payload.message,
      source: {
        pointer: '/data'
      }
    }];
    if (payload.errors && payload.errors.length > 0) {
      errors = errors.concat(payload.errors);
    }
    errors.name = payload.name || null;

    //TODO: use DS.InvalidError or DS.ServerError?
    return new _emberData.default.InvalidError(errors);
  }
});
