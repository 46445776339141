import DS from 'ember-data';
import Model from '../utils/model-util';

export default DS.Model.extend({
    uuid: Model.attr(),
    clientUuid: Model.attr(),
    clientName: Model.attr(),
    postedDate: Model.attr(),
    amount: Model.attr(),
    type: Model.attr(),
    subtype: Model.attr(),
    subtypeName: Model.attr(),
    balanceType: Model.attr(),
    description: Model.attr(),
    isActive: Model.attr(),
    created: Model.attr(),
    updated: Model.attr()
});
