import DS from 'ember-data';

export default DS.Model.extend({
    uuid: DS.attr(),
    formUuid: DS.attr(),
    buyerToggleClientUuid: DS.attr(),
    name: DS.attr(),
    divisionId: DS.attr(),
    isPreOnboardingSignatureComplete: DS.attr('boolean'),
    isBasicInformationCompleted: DS.attr('boolean'),
    isPostOnboardingSignatureComplete: DS.attr('boolean'),
    isApproved: DS.attr('boolean'),
    isUnapproved: DS.attr('boolean'),
    bankAccountStatus: DS.attr(),
    isBankAccountComplete: DS.attr('boolean'),
    created: DS.attr('date'),
    updated: DS.attr('date'),
    applicationSignDate: DS.attr('date')
});
